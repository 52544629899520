<template>
  <menu-main></menu-main>
  <div id="clients">
    <header-main> </header-main>
    <div class="cuerpo_principal">
      
      <div class="mosaico_cam">
        <div class="main_content">
          <div class="grid_busc">
            <form class="buscador" @submit.prevent>
              <input
                id="search"
                type="search"
                v-model="search"
                :placeholder="$t('common.search')"
              />
              <span class="material-icons refresh-button" @click="refresh"
                >refresh</span
              >
            </form>
          </div>
        </div>
      </div>
      <div class="container">
        <div class="row">
          <div id="tgrab" class="col-12 offset-0 col-md-10 offset-md-1">
            <div class="table-responsive">
              <table class="table align-middle">
                <thead>
                  <tr>
                    <th scope="col"></th>
                    <th scope="col">{{ $t("users.first_name") }}</th>
                    <th scope="col">{{ $t("users.surname") }}</th>
                    <th scope="col">{{ $t("users.email") }}</th>
                  </tr>
                </thead>
                <tbody>
                  <client-item
                    v-for="(client, index) in filterClients"
                    :key="client.email"
                    :index="index"
                    :accessToken="client.accessToken"
                    :name="client.name"
                    :surname="client.surname"
                    :email="client.email"
                  >
                  </client-item>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import ClientItem from "./ClientItem.vue";
export default {
  name: "SignInClient",
  components: {
    ClientItem,
  },
  data() {
    return {
      search: "",
      //clients: [],
    };
  },
  computed: {
    filterClients() {
      const filter1 = this.filterUsersByName();
      const filter2 = this.filterUsersBySurname();
      const filter3 = this.filterUsersByEmail();
      if (filter1 || filter2 || filter3) {
        return [...new Set([...filter1, ...filter2, ...filter3])];
      }
      return this.$root.sessionManager.clients;
    },
  },
  mounted() {
    document.documentElement.style.scrollBehavior = "auto";
    //console.log("SigInClient");
    document.getElementById("search").focus();
    document.getElementById("search").blur();
    document.title = this.$t("login.access_client");
    if (!this.$root.sessionManager.isLoggedInAsOperator()) {
      console.log("router to signin");
      this.$router.push({ name: "SignIn" });
    }
    //this.clients = [...this.$root.sessionManager.clients];
    //console.log("SigInClient, clients:", this.$root.sessionManager.clients);
    //this.editUsersPriv = userPriv["editUsers"];
  },
  methods: {
    async refresh() {
      this.loaded = false;
      console.log("refresh");
      //this.clients = [...this.$root.sessionManager.clients];
      this.$root.sessionManager.loginex("","",this.$root.sessionManager.operatorToken);
    },
    filterUsersByName() {
      return this.search
        ? this.$root.sessionManager.clients.filter((client) =>
            client.name.toLowerCase().includes(this.search.toLowerCase())
          )
        : null;
    },
    filterUsersBySurname() {
      return this.search
        ? this.$root.sessionManager.clients.filter((client) =>
            client.surname.toLowerCase().includes(this.search.toLowerCase())
          )
        : null;
    },
    filterUsersByEmail() {
      return this.search
        ? this.$root.sessionManager.clients.filter((client) =>
            client.email.toLowerCase().includes(this.search.toLowerCase())
          )
        : null;
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
@import "../../assets/custom/css/login.css";
</style>