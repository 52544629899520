<template>
  <menu-main></menu-main>

  <div id="grabaciones">
    <header-main> </header-main>

    <div class="cuerpo_principal">
      <!--<div class="col-12">
        <div class="titulo_seccion">
          <h1>Grabaciones</h1>
        </div>
      </div>-->
      <div class="mosaico_cam">
        <div class="main_content">
          <div class="grid_busc">
            <form class="buscador" @submit.prevent>
              <input style="padding-right: 40px;"
                id="search"
                type="search"
                v-model="search"
                :placeholder="$t('common.search')"/>
              <!--<button><i class="fa fa-search" aria-hidden="true" style="margin-left: -100px"></i></button>-->
              
              <button data-bs-toggle="dropdown"
                ><i class="fa fa-filter" aria-hidden="true"></i></button>
              <ul class="dropdown-menu list-dropdown dropdown-menu-right">
                <li @click="addFilter(1)">
                  <a><i class="fa fa-calendar" aria-hidden="true"></i> {{ $t('common.date') }} </a>
                </li>
              </ul>
              <span class="material-icons refresh-button"
                @click="refresh">refresh</span>
            </form>
            <div class="g_filtro"> <!-- Filter list -->
             
              <div v-if="filters" :class="{'collapse show':filters,'collapse':!filters}" id="gFiltro">
                <div class="card card-body">
									{{ $t('common.filters') }}
                  <div v-if="filters & 1" class="btn-group">
                    <button
                      class="btn btn-sm dropdown-toggle filter-btn"
                      type="button"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                      id="dropdown-date"
                    >
                      <i class="fa fa-calendar" aria-hidden="true"></i>
                      {{searchDate}}
											
                    </button>

                    <ul class="dropdown-menu">
                      <li style="/*width: 220px*/">
                        
												<Datepicker id="datepicker"
                          v-model="recTime"
                          :selectText="selectText" 
													:format="'dd/MM/yyyy, HH:mm'"
													:previewFormat="'dd/MM/yyyy, HH:mm'"
                          :cancelText="cancelText"
                          :monthNameFormat="'long'"
													:locale="'es-ES'"							
													@update:modelValue="searchByDate"
													:inline="true"
													:dark="false"
                        />
                      </li>
                    </ul>
										<button class="btn delete-btn" @click="removeFilter(1)" ><i class="fa fa-close" aria-hidden="true"></i></button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="container">

            
        <p v-if="$root.dataManager.recsLoaded && !$root.dataManager.recordingsWithLabel.length" class="no_data_msg">
          {{ message }}
        </p>
        <div v-if="$root.dataManager.recordingsWithLabel.length" class="row">
          <div id="tgrab" class="col-12 offset-0 col-md-10 offset-md-1">
            <div class="table-responsive">
              <table class="table align-middle">
                <thead>
                  <tr>
                    <th scope="col"></th>
                    <th scope="col" style="max-width: 60px">{{ $t('recordings.type') }}</th>
                    <th scope="col">{{ $t('recordings.camera') }}</th>
                    <th scope="col">{{ $t('common.date') }}</th>
                    <th scope="col">{{ $t('common.time') }}</th>
                    <th scope="col"></th>
                  </tr>
                </thead>
                <tbody>
                  <!--<tr v-for="(recording,index) in filterRecordings" :key="recording.id">-->
                  <!--<template
                    v-for="(recording, index) in filterRecordings"
                    :key="recording.id"
                  >-->
                    <recording-item v-for="(recording, index) in filterRecordings"
                      :key="recording.id"
                      :index="index"
                      :id="recording.id"
                      :name="recording.camName"
                      :camId="recording.camId"
                      :date="getDate(recording.startTime)"
                      :start="getHour(recording.startTime)"
                      :end="getHour(recording.endTime)"
                      :type="recording.type"
                      :locked="recording.locked"
                      :rectime="recording.rectime"
                      @delete-event="showDeleteConfirmation"
                      @export-event="exportRecording"
                    >
                    </recording-item>
                    <!--</tr>-->
                  <!--</template>-->
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <menu-footer active-state="recordings"></menu-footer>
</template>

<script>
import $ from "jquery";
import Datepicker from "@vuepic/vue-datepicker";
import "@vuepic/vue-datepicker/dist/main.css";

import RecordingItem from "./RecordingItem.vue";
import DataManager from "../../assets/custom/js/DataManager.js";

//const axios = require("axios").default;
//axios.defaults.withCredentials = false;
const Swal = require("sweetalert2");

export default {
  name: "RecordingsMain",
  components: {
    Datepicker,
    RecordingItem,
  },
  data() {
    return {
      //recordings: [],
      search: "",
      selectText: this.$t('recordings.select'),
      cancelText: this.$t('common.cancel'),
      //dayNames:["Lu","Ma","Mi","Ju","Vi","Sa","Do"],
      recTime: null,
      error: false,
      //loaded: false,
      message: this.$t('recordings.no_recordings'),
			filters: 0,
			searchDate: this.$t('common.date'),
    };
  },
  computed: {
    filterRecordings() {
      const filter1 = this.filterRecsByName();
      const filter2 = this.filterRecsByDay();
      if (filter1 || filter2) {
        return [...new Set([...filter1, ...filter2])];
      }
      return this.$root.dataManager.recordingsWithLabel;
    },
  },

  methods: {
    async refresh() {
      console.log("refresh");
      this.$root.dataManager.loadRecordings(true);
      if (this.$root.dataManager.selectedLabelId!=-1)
        this.$root.dataManager.loadRecordingsWithLabel(true);
    },
    parseDate(date) {
			
      var year = date.getFullYear();
      var month =
        date.getMonth() + 1 > 9
          ? date.getMonth() + 1
          : "0" + (date.getMonth() + 1);
      var day = date.getDate() > 9 ? date.getDate() : "0" + date.getDate();
      var hour = date.getHours() > 9 ? date.getHours() : "0" + date.getHours();
      var minute =
        date.getMinutes() > 9 ? date.getMinutes() : "0" + date.getMinutes();
      console.log(year, month, day, hour, minute);
      return "" + year + "" + month + "" + day + "" + hour + "" + minute;
    },
		parseDateForDisplay(date) {		
      var year = date.getFullYear();
      var month = ("0" + (date.getMonth() + 1)).slice(-2);
      var day = ("0" + date.getDate()).slice(-2);
      var hour = ("0" + date.getHours()).slice(-2);
      var minute = ("0" + date.getMinutes()).slice(-2);
      console.log(year, month, day, hour, minute);
      return "" + day + "/" + month + "/" + year + ", " + hour + ":" + minute;
    },
    searchByDate() {
			if (this.recTime===null) return;  
			console.log("searchByDate:", this.recTime);
			this.searchDate=this.parseDateForDisplay(this.recTime);
      if (this.recTime != null) {
        //this.getRecordingsByDate(this.parseDate(this.recTime));
        this.getRecordings(this.parseDate(this.recTime));
        $("#dropdown-date").trigger("click");
      }
    },
    filterRecsByName() {
      return this.search
        ? this.$root.dataManager.recordingsWithLabel.filter((rec) =>
            rec.camName.toLowerCase().includes(this.search.toLowerCase())
          )
        : null;
    },
    filterRecsByDay() {
      return this.search
        ? this.$root.dataManager.recordingsWithLabel.filter((rec) =>
            this.getDate(rec.startTime).includes(this.search.toLowerCase())
          )
        : null;
    },
    showDeleteConfirmation(id, index) {
      console.log("showDeleteConfirmation:", id, index);
      Swal.fire({
        /*title: this.recordings[index].camName,*/
        text: this.$t('recordings.rec_del_confirmation',{camname: this.$root.dataManager.recordingsWithLabel[index].camName}),
        icon: "question",
        showCancelButton: true,
        confirmButtonText: this.$t('common.ok'),
        cancelButtonText: this.$t('common.cancel'),
         //confirmButtonColor: getComputedStyle(document.documentElement).getPropertyValue('--main-accent-color') /*"#61aaea"*/,
        //cancelButtonColor: getComputedStyle(document.documentElement).getPropertyValue('--main-inactive-color') /*"#c0c0c0"*/,
       position: "center",
      }).then((result) => {
				console.log(result);
        if (result.isConfirmed) {
          console.log("Delete:", id, index);
          this.deleteRecording(id/*, index*/);
        }
      });
    },

    exportRecording(id, index) {
      console.log("Export recording", id, index);
      this.$router.push({ name: "ExportAdd", params: { paramRecId: id } });
    },

    async deleteRecording(id/*, index*/) {
      try {
        await this.$root.dataManager.deleteRecording(id);
        //await this.$root.dataManager.loadRecordings(true);       
      } catch (error) {
        console.log("recording delete ERROR", error);
      }
    },

    async getRecordings(recTime) {
      console.log("Load recs " + recTime);
      try {
        await this.$root.dataManager.loadRecordings(recTime);
        if (recTime!=undefined) {
          for (let rec of this.$root.dataManager.recordingsWithLabel) {
            rec.rectime = recTime;
          }
          if (this.$root.dataManager.recordingsWithLabel.length == 0) {
            this.message = this.$t('recordings.no_recs_for_date');
          }
        }
      }
      catch (response) {
        console.log(response);
        if (response.result == DataManager.S_SESSIONERROR) {
          Swal.fire({
            text: this.$t('common.session_expired'),
            icon: "error",
            confirmButtonText: this.$t('common.ok'),
            position: "center",
          }).then((result) => {
            console.log(result);
            if (result.isConfirmed) {
              this.logOut();
            }
          });
        }  
      }
    },

    getDate(tm) {
      return (
        tm.substring(6, 8) + "/" + tm.substring(4, 6) + "/" + tm.substring(0, 4)
      );
    },
    getHour(tm) {
      if (tm) {
        return tm.substring(8, 10) + ":" + tm.substring(10, 12);
      } else {
        return "---";
      }
    },
		addFilter(filter) {
      this.filters|=filter;
    },
		removeFilter(filter) {
			if (filter==1) {
				this.searchDate=this.$t('common.date');
				this.recTime=null;
				this.message="";
			}
      this.filters&=(!filter);
			if (this.filters==0)
				this.getRecordings();
    },
  },
  mounted() {
    document.documentElement.style.scrollBehavior = 'auto';
		//console.log(document/*.querySelector("#datepicker")*/); //.setAttribute("dark","");
    console.log("RecordingsMain");
    document.getElementById("search").focus();
    document.getElementById("search").blur();
    document.title = this.$t('common.recordings');
    //const userInfo = JSON.parse(sessionStorage.getItem("userInfo"));
    if (!this.$root.sessionManager.isLoggedIn()) {
      console.log("router to signin");
      this.$router.push({ name: "SignIn" });
    }
    console.log("RecordingsMain, sessionid:", this.$root.sessionManager.sessionId);
    //this.getRecordings();
  },
  unmounted() {
    var oldMenu=document.querySelector('.dropdown-menu.show');
		if (oldMenu!=null) oldMenu.classList.remove("show");
  },
	
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
/*li:hover a {
  color: white;
}*/
</style>