<template>
<tr>
  <th scope="row">
    <!--<div class="tg_vista">-->
		<span class="material-icons" style="font-size: 32px;line-height:1;vertical-align:middle">person</span>
    <!--</div>-->
  </th>
  <td>{{ name }}</td>
  <td>{{ surname }}</td>
  <td>{{ email }}</td>
  <td style="text-align:right">
    <!-- Default dropstart button -->
    <div class="btn-group dropstart">
      <button
        type="button"
        class="btn"
        data-bs-toggle="dropdown"
        aria-expanded="false"
				ref="dropdownbtn"
      >
        <span
          ><svg
            id="Modo_de_aislamiento"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 1.78 10.75"
          >
            <circle
              class="cls-1"
              cx=".89"
              cy="9.86"
              r=".89"
              transform="translate(-2.23 .46) rotate(-13.2)"
            />
            <circle
              class="cls-1"
              cx=".89"
              cy="5.37"
              r=".89"
              transform="translate(-1.2 .35) rotate(-13.2)"
            />
            <circle
              class="cls-1"
              cx=".89"
              cy=".89"
              r=".89"
              transform="translate(-.18 .23) rotate(-13.2)"
            /></svg></span>
      </button>
      <ul class="dropdown-menu list-dropdown">
        <li @click="deleteUser(id,index)">
          <a>
						<i class="fa fa-trash" aria-hidden="true"></i> {{ $t('common.delete') }}</a>
        </li>
        <li @click="editUser(id,index)">
          <a >
						<i class="fa fa-pencil" aria-hidden="true"></i> {{ $t('common.edit') }}</a>
        </li>
      </ul>
    </div>
  </td>
</tr>
</template>

<script>
//const axios = require("axios").default;
//axios.defaults.withCredentials = false;

export default {
  name: "UserItem",
  emits: ["deleteEvent", "editEvent"],
  props: {
    index: Number,
    paramId: {
      type: String,
      default: "-1",
    },
    name: {
      type: String,
      required: true,
      default: "",
    },
    surname: {
      type: String,
      required: true,
      default: "",
    },
    email: {
      type: String,
      required: true,
      default: "",
    },
    privcod: {
      type: Number,
      default: 0,
    },
    enabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      loaded: false,
      error: false,
      id: -1,
    };
  },
  mounted() {
    this.id = this.paramId;
    console.log("UserItem mounted",this.id);
		/*this.$refs.dropdownbtn.addEventListener('show.bs.dropdown', function() {
			var oldMenu=document.querySelector('.dropdown-menu.show');
			if (oldMenu!=null) oldMenu.classList.remove("show");
		});*/
		this.$refs.dropdownbtn.addEventListener('show.bs.dropdown', function(e) {
			//console.log("Drop down");
			//console.log(e);
			var oldMenu=document.querySelector('.dropdown-menu.show');
			if (oldMenu!=null) oldMenu.classList.remove("show");
			var dropdownMenu = e.target.parentElement.querySelector(".dropdown-menu");
			if (dropdownMenu===null) return;
			var rect = dropdownMenu.getBoundingClientRect();
			dropdownMenu.style={
				position: 'absolute',
				left: rect.left,
				top: rect.top
			};
			e.target.myMenu=dropdownMenu;
			dropdownMenu.parentElement.removeChild(dropdownMenu);
			document.body.append(dropdownMenu);
		});
		this.$refs.dropdownbtn.addEventListener('hidden.bs.dropdown', function(e) {
			//console.log("Hide drop down");	
			//console.log(e);
			if (e.target.myMenu===undefined) return;
			var dropdownMenu = e.target.myMenu;
			dropdownMenu.style={
				position: false,
				left: false,
				top: false
			};
			document.body.removeChild(dropdownMenu);
			e.target.parentElement.append(dropdownMenu);
		});
  },
  methods: {
		editUser(id,index) {
			document.querySelector('.dropdown-menu.show').classList.remove("show");
			this.$emit('editEvent',id,index);
		},
		deleteUser(id,index) {
			document.querySelector('.dropdown-menu.show').classList.remove("show");
			this.$emit('deleteEvent',id,index);
		},
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>