<template>
  <menu-main></menu-main>

  <div id="recExports">
    <header-main> </header-main>

    <div class="cuerpo_principal">
      <div class="mosaico_cam">
        <div class="main_content">
          <div class="grid_busc">
            <form class="buscador" @submit.prevent>
              <input
                style="padding-right: 40px"
                id="search"
                type="search"
                v-model="search"
                :placeholder="$t('common.search')"
              />
              <span class="material-icons refresh-button"
                @click="refresh">refresh</span>
            </form>
          </div>
        </div>
      </div>

      <div class="container">
        <p
          v-if="exportRecordingsPriv && loaded && !recExports.length"
          class="no_data_msg"
        >
          {{ $t('exports.no_exports') }}
        </p>
        <p v-if="!exportRecordingsPriv" class="no_data_msg">
          {{ $t('common.no_privileges') }}
        </p>
        <div v-if="exportRecordingsPriv && recExports.length" class="row">
          <div id="tgrab" class="col-12 offset-0 col-md-10 offset-md-1">
            <div class="table-responsive">
              <table class="table align-middle">
                <thead>
                  <tr>
                    <th scope="col"></th>
                    <th scope="col">{{ $t('common.name') }}</th>
                    <th scope="col">{{ $t('common.date') }}</th>
                    <th scope="col">{{ $t('common.time') }}</th>
                    <th scope="col"></th>
                  </tr>
                </thead>
                <tbody>
                  <!--<template
                    v-for="(recExport, index) in filterRecExports"
                    :key="recExport.formkey"
                  >-->
                    <export-item v-for="(recExport, index) in filterRecExports"
                      :key="recExport.formkey"
                      :index="index"
                      :formKey="recExport.formkey"
                      :name="recExport.name"
                      :date="getDate(recExport.initdate)"
                      :start="getHour(recExport.initdate)"
                      :end="getHour(recExport.enddate)"
                      :initProgress="recExport.progress"
                      :status="recExport.status"
                      :label="
                        recExport.name +
                        '_' +
                        recExport.initdate +
                        '_' +
                        recExport.enddate +
                        '.avi'
                      "
                      @delete-event="showDeleteConfirmation"
                      @download-event="downloadRecExport"
                      @cancel-event="cancelEvent"
                    >
                    </export-item>
                  <!--</template>-->
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <menu-footer active-state=""></menu-footer>
</template>

<script>
import ExportItem from "./ExportItem.vue";

//const axios = require("axios").default;
//axios.defaults.withCredentials = false;
const Swal = require("sweetalert2");

export default {
  name: "ExportsMain",
  components: {
    ExportItem,
  },
  data() {
    return {
      recExports: [],
      search: "",
      //error: false,
      loaded: false,
      exportRecordingsPriv: false,
      timesRefreshed: 0,
    };
  },
  computed: {
    filterRecExports() {
      const filter1 = this.filterRecExportsByName();
      const filter2 = this.filterRecExportsByDay();
      if (filter1 || filter2) {
        return [...new Set([...filter1, ...filter2])];
      }
      return this.recExports;
    },
  },

  methods: {
    async refresh() {
      this.loaded=false;
      if (this.intervalId!=undefined)
        clearInterval(this.intervalId);
      this.intervalId=undefined;
      console.log("refresh");
      this.recExports=[];
      this.getRecExports();
    },
    filterRecExportsByName() {
      return this.search
        ? this.recExports.filter((exp) =>
            exp.name.toLowerCase().includes(this.search.toLowerCase())
          )
        : null;
    },
    filterRecExportsByDay() {
      return this.search
        ? this.recExports.filter((exp) =>
            this.getDate(exp.initdate).includes(this.search.toLowerCase())
          )
        : null;
    },

    async downloadRecExport(formkey, index) {
      console.log("Download recExport", formkey, index);
    },

    async cancelEvent(formkey, index) {
      try {
        await this.$root.dataManager.cancelExportation(formkey);
        console.log("cancel recExport OK");
        this.recExports.splice(index, 1);
      } catch (error) {
        console.log("Cancel recExportERROR", error);
      }
    },

    
    showDeleteConfirmation(id, index) {
      console.log("showDeleteConfirmation:", id, index);
      Swal.fire({
        /*title: this.recordings[index].camName,*/
        text: this.$t('exports.export_del_confirmation',{camname:this.recExports[index].name}),
        icon: "question",
        showCancelButton: true,
        confirmButtonText: this.$t('common.ok'),
        cancelButtonText: this.$t('common.cancel'),
        //confirmButtonColor: getComputedStyle(document.documentElement).getPropertyValue('--main-accent-color') /*"#61aaea"*/,
        //cancelButtonColor: getComputedStyle(document.documentElement).getPropertyValue('--main-inactive-color') /*"#c0c0c0"*/,
        position: "center",
      }).then((result) => {
        if (result.isConfirmed) {
          console.log("Delete:", id, index);
          this.deleteRecExport(id, index);
        }
      });
    },

    async deleteRecExport(formkey, index) {
      try {
        await this.$root.dataManager.deleteExportedRecording(formkey);
        this.recExports.splice(index, 1);
      } catch (error) {
        console.log("recExports delete ERROR", error);
      }
    },



    async getRecExports() {
      this.loaded=false;
      console.log("getRecExports");
      try {
        const response = await this.$root.dataManager.loadExportedRecordings();
        console.log("getrecExports OK");
        if (response.exports.length!=this.recExports.length)
          this.recExports = [...response.exports];
        this.loaded = true;
        console.log("recExports", this.recExports);
      } catch (error) {
        console.log("getRecExports error");
        console.error(error);
        //this.error = true;
        if (error.result == this.$root.dataManager.S_SESSIONERROR) {
            Swal.fire({
              text: this.$t('common.session_expired'),
              icon: "error",
              confirmButtonText: this.$t('common.ok'),
              position: "center",
            }).then((result) => {
              console.log(result);
              if (result.isConfirmed) {
                this.logOut();
              }
            });
          }
      }
    },

    getDate(tm) {
      return (
        tm.substring(6, 8) + "/" + tm.substring(4, 6) + "/" + tm.substring(0, 4)
      );
    },
    getHour(tm) {
      if (tm) {
        return tm.substring(8, 10) + ":" + tm.substring(10, 12);
      } else {
        return "---";
      }
    },
  },
  mounted() {
    document.documentElement.style.scrollBehavior = 'auto';
    console.log("ExportsMain");
    document.getElementById("search").focus();
    document.getElementById("search").blur();
    document.title = this.$t('common.exports');
    //const userInfo = JSON.parse(sessionStorage.getItem("userInfo"));
    if (!this.$root.sessionManager.isLoggedIn()) {
      console.log("router to signin");
      this.$router.push({ name: "SignIn" });
    }
    console.log("ExportsMain, sessionid:", this.$root.sessionManager.sessionId);
    const userPriv = this.$root.sessionManager.privileges;
    console.log("UsersMain, userPriv:", userPriv);
    this.exportRecordingsPriv = userPriv["exportRecordings"];
    if (this.exportRecordingsPriv) {
      this.timesRefreshed=0;
      var self=this;
      self.getRecExports();
      this.intervalId=setInterval(()=>{
        self.timesRefreshed++;
        if (self.timesRefreshed==10)
        {
          clearInterval(self.intervalId);
          self.intervalId=undefined;
        }
        self.getRecExports();
      },2000);
      
    }
  },
  unmounted() {
    var oldMenu=document.querySelector('.dropdown-menu.show');
		if (oldMenu!=null) oldMenu.classList.remove("show");
    if (this.intervalId!=undefined)
      clearInterval(this.intervalId);
    this.intervalId=undefined;
   
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>