<template>
  <div id="login">
    <div class="contenedor_login">
      <!--<img src="../../../public/img/logo.png" alt="" />-->
      <div id="logo" />
      <form>
        <div>
          <!--<i class="fa fa-user" aria-hidden="true"></i>-->
          <i class="material-icons" aria-hidden="true">person</i>
          <input
            type="email"
            id="email"
            disabled
            v-model="email"
            :placeholder="$t('login.email')"
            v-on:keyup.enter="login"
          />
        </div>
        <p class="login_msg" v-if="message != ''">{{ message }}</p>
        <button class="login_btn" type="button" @click.prevent="save">{{ $t('common.activate') }}</button>
      </form>
      <div class="copy_login">
        <p>{{ $t('common.copyright', {year: new Date().getFullYear()}) }} | {{ $t('common.all_rights_reserved') }}</p>
      </div>
    </div>
  </div>
</template>


<script>
const axios = require("axios").default;
axios.defaults.withCredentials = false;
const Swal = require("sweetalert2");

export default {
  name: "AuthenticationActivate",

  data() {
    return {
      message: "",
      token: "",
      email: "",
      tokenEmail: "",
    };
  },
  methods: {
    validateData() {
      if (this.email != this.tokenEmail) {
        this.message = this.$t('login.invalid_email');
        return false;
      }
      return true;
    },
    save() {
      console.log("save");
      this.message = "";
      if (this.validateData()) {
        console.log("save activate2FA");
        this.activate2FA();
      }
    },
    async getEmail($token) {
      console.log("validate2FAToken:", $token);
      const url = this.APIUrl + "/validate2fa/" + $token;
      console.log(url);
      try {
        const response = await axios.get(url);
        console.log(response.status);
        if (response.status == 200 && response.data.result == 0) {
          this.email = response.data.email;
          this.tokenEmail = response.data.email;
          console.log("validate2FAToken OK:", this.email);
        } else {
          console.log("validate2FAToken error");
        }
      } catch (error) {
        console.log("validate2FAToken error");
        console.error(error);
      }
    },
    async activate2FA() {
      this.message = "";
      console.log("activate2FA");
      const data = {
        email: this.email,
      };
      console.log("data", data);
      console.log("activate2FA:", this.token);
      const url = this.APIUrl + "/authentication/" + this.token;
      try {
        const response = await axios.put(url, data);
        console.log(response.status);
        console.log(response.data);
        if (response.status == 200 && response.data.result == 0) {
          this.message = "";
          Swal.fire({
            text: this.$t('login.two_factor_activated'),
            icon: "success",
            confirmButtonText: this.$t('common.ok'),
            position: "center",
          }).then((result) => {
            console.log(result);
            if (result.isConfirmed) {
              this.$router.push({ name: "SignIn" });
            }
          });
        } else {
          console.log("activate2FA error");
          this.message = "";
          Swal.fire({
            text: this.$t('login.error_activating_2fa'),
            icon: "error",
            confirmButtonText: this.$t('common.ok'),
            position: "center",
          }).then((result) => {
            console.log(result);
            if (result.isConfirmed) {
              console.error("Error al activar2fa");
            }
          });
        }
      } catch (error) {
        this.message = "";
        Swal.fire({
          text: this.$t('login.error_activating_2fa'),
          icon: "error",
          confirmButtonText: this.$t('common.ok'),
          position: "center",
        }).then((result) => {
          console.log(result);
          if (result.isConfirmed) {
            console.error("Error al activar2fa");
          }
        });
      }
    },
  },
  mounted() {
    console.log("AuthenticationActivate");
    this.token = this.$route.query.q;
    console.log("token:", this.token);
    this.getEmail(this.token);
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
@import "../../assets/custom/css/login.css";
</style>