<template>
  <li 
    :class="['nav-item', this.$root.dataManager.selectedLabelId == this.id ? 'active' : '']"
    @click.stop="this.$emit('selectedEvent',id);">
    <div class="tag_content">
    <a class="nav-link" aria-current="page" href="#" data-bs-dismiss="offcanvas">
      <div 
        :class="['text-truncate', id!=-1 ? 'with-switch' : '']"
      >
      <i class="material-icons" aria-hidden="true">{{icon}}</i>
      
        <slot></slot><!--{{name}}-->
      </div>
    </a>
    <div @click.stop="" v-if="id!=-1" class="sw_cam">
          <input
            type="checkbox"
            name="swLabelMenu"
            :id="swLabelMenu"
            class="switch_input"
            v-model="swLabelMenuChecked"
            readonly
          />
          <label :for="swLabelMenu" class="switch_label"></label>
        </div>
      </div>
  </li>
</template>

<script>
//const axios = require("axios").default;
//axios.defaults.withCredentials = false;

export default {
  name: "LabelMenuItem",
  emits:["selectedEvent"],
  props: {
    /*index: Number,*/
    id: Number,
    /*name: {
      type: String,
      required: true,
      default: "",
    },*/
    alarmState: {
      type: Number,
      default: -1
    },
  },
  data() {
    return {
      /*previewURL: "/img/default_preview.jpg",
      loaded: false,
      error: false,
      protected: false,
      showRecordingsPriv:false,
      exportRecordingsPriv:false,
      deleteRecordingsPriv:false,*/
      lalarmState: -1,
      swLabelMenuChecked: false,
      icon: "sell",
    };
  },
  created() {
    //console.log("RecordingItem created");
    //this.getPreviewURL(this.camId);
    this.swLabelMenuChecked = this.alarmState==1;

  },
  watch: {
    alarmState () {
      //console.log("alarmState changed");
      this.swLabelMenuChecked = this.alarmState==1;
    },
    swLabelMenuChecked() {
      console.log("New value " + this.swLabelMenuChecked);
      
      if (this.swLabelMenuChecked!=(this.alarmState==1))
      {
        console.log("Modify installation");
        this.$root.dataManager.modifyInstallation(this.id,null,this.swLabelMenuChecked)
          .then(() => {
            //this.$root.dataManager.loadLabels(true);
          })
          .catch(error => {
            console.log(error);
          });
      }
    }
  },
  computed:{
    swLabelMenu() {
      return "swLabelMenu-" + this.id;
    },
  },
  mounted() {
    this.lalarmState = this.alarmState;
    if (this.id==-1) this.icon="visibility";
  },
  methods: {
		async updateAlarmState(id,state) {
      console.log (id + " " + state);
    },
	},
};

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

.text-truncate {
  max-width:100%;
}
.text-truncate.with-switch {
  max-width:calc(100% - 60px);
}
.tag_content {
  position:relative;
  width:100%;
}
.sw_cam {
  position: absolute;
  /*padding-right: 8px;*/
  right: 8px;
  top: 50%;
  transform: translate(0,-50%);

}
li i {
    padding-right: 18px;
    color: var(--main-menu-text-color);
    font-size: 22px;
    vertical-align: middle;
}


/*.switch_label {
    box-shadow: 0 0 5px 1px var(--separator-color);
}*/
.ml_items .nav-item:hover  .switch_input:checked+.switch_label
/*.ml_items .nav-item:hover  .switch_input+.switch_label*/ {
    /*background-color: var(--main-accent-color);*/
    box-shadow: 0 0 0px 1px var(--main-menu-switch-border-color);
}

.switch_input:checked+.switch_label {
  background-color: var(--main-menu-switch-on-background-color);
}

.switch_label {
  background-color: var(--main-menu-switch-off-background-color);
}

.switch_label::before {
  background-color: var(--main-menu-switch-thumb-color);
  box-shadow: 0 0 0 1px var(--main-menu-switch-thumb-border-color);
}

.navbar-nav .nav-link {
    color: var(--main-menu-text-color);
    font-weight: bold;
    width: 100%;
    font-size: 16px;
}
.ml_items .nav-item:hover i,
.ml_items .nav-item:hover a,
.ml_items .nav-item:hover a::after,
.ml_items .nav-item:focus a {
    color: var(--main-menu-hover-color) !important;
    transition: none;
}
.ml_items .nav-item:hover ul li i,
.ml_items .nav-item:hover ul li a{
    color: var(--main-menu-text-color) !important;
    transition: none;
}
.ml_items .nav-item.active {
  background-color: var(--main-menu-active-background-color);
  
}

.ml_items .nav-item.active i,
.ml_items .nav-item.active a {
  color: var(--main-menu-active-color);
}
@media (max-width: 600px) {
  li i {
    padding-right: 14px;
    font-size: 18px;
  }
  .navbar-nav .nav-link {
    font-size: 14px;
  }
}
</style>