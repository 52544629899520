<template>
<div>

</div>
</template>

<script>
export default {
  name: 'HomeMain',
  mounted(){
        console.log("HomeMain");
				/*document.head.innerHTML += '<meta name="theme-color" content="' + getComputedStyle(document.documentElement).getPropertyValue('--topbar-background-color') + '">';
				document.head.innerHTML += '<meta name="apple-mobile-web-app-status-bar-style" content="' + getComputedStyle(document.documentElement).getPropertyValue('--topbar-background-color') + '">';*/
        // const userInfo = JSON.parse(sessionStorage.getItem('userInfo'));
        // if (!userInfo){
        //   console.log("router to signin");
        //   this.$router.push({name:"SignIn"});
        // }else{
        //   console.log("HomeMain, sessionid:",userInfo.sessionId);
        //   console.log("router to CamerasMain");
        //   this.$router.push({name:"CamerasMain"});
        // }
        console.log(this.$root.sessionManager.sessionId);
        if (!this.$root.sessionManager.isLoggedIn()){
          console.log("router to signin");
          this.$router.push({name:"SignIn"});
        }else{
          console.log("HomeMain, sessionid:",this.$root.sessionManager.sessionId);
          console.log("router to CamerasMain");
          this.$router.push({name:"CamerasMain"});
        }

  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
