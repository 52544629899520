<template>
  <div id="login">
    <div class="contenedor_login">
      <!--<img src="../../../public/img/logo.png" alt="" />-->
      <div id="logo" />
      <form>
        <div>
          <!--<i class="fa fa-user" aria-hidden="true"></i>-->
          <i class="material-icons" aria-hidden="true">person</i>
          <input
            type="email"
            id="email"
            disabled
            v-model="user"
            :placeholder="$t('login.email')"
            v-on:keyup.enter="login"
          />
        </div>
        <div>
          <i class="material-icons" aria-hidden="true">pin</i>
          <input
            v-model="code2fa"
            :placeholder="$t('login.code_2fa')"
            v-on:keyup.enter="login"
          />
        </div>

        <input
          type="checkbox"
          id="swRememberMe"
          v-model="remember"
          class="switch_input"
          readonly
        /> {{ $t("login.remember_me") }}
        <label for="swRememberMe" class="switch_label"></label>

        <p class="login_msg" v-if="error">
          {{ $t("login.invalid_code2fa") }}
        </p>
        <button class="login_btn" type="button" @click.prevent="login">
          {{ $t("login.enter") }}
        </button>
      </form>
      <div class="copy_login">
        <p>
          {{ $t("common.copyright", { year: new Date().getFullYear() }) }} |
          {{ $t("common.all_rights_reserved") }}
        </p>
      </div>
    </div>
  </div>
</template>

<script>
const axios = require("axios").default;
axios.defaults.withCredentials = false;

export default {
  name: "SignIn2FA",

  data() {
    return {
      token2fa: "",
      user: "",
      accessToken: "",
      code2fa: "",
      password: "",
      remember: 0,
      error: false,
    };
  },
  methods: {
    async login() {
      this.error = false;
      try {
        const response = await this.$root.sessionManager.loginex2fa(
          this.user,
          this.password,
          this.accessToken,
          this.token2fa,
          this.code2fa,
          this.remember
        );

        if (response.result == this.S_2FA_CODE_REQUIRED) {
          console.log("Required 2FA");
          this.error = true;
        } else if (response.result == this.S_2FA_CODE_ERROR) {
          console.log("Error S_2FA_CODE_ERROR");
          this.error = true;
        } else {
          if (response.userInfo !== undefined) {
            this.$root.dataManager.APIServer =
              "https://" + this.$root.sessionManager.recServer;
            this.$root.dataManager.sessionId =
              this.$root.sessionManager.sessionId;
            this.$root.dataManager.userName =
              this.$root.sessionManager.userName;
            await this.$root.sessionManager.getConfig();
            this.$router.push({ name: "CamerasMain" });
          }
          if (response.clients !== undefined) {
            this.$router.push({ name: "SignInClient" });
          }
        }
      } catch (error) {
        console.log("login error login2FA");
        console.error(error);
        this.error = true;
      }
    },
    setLocale(locale) {
      if (locale !== undefined) {
        if (this.$i18n.locale != locale) {
          console.log("Setting locale " + locale);
          this.$i18n.locale = locale;
        }
        return;
      }
      let i, j;
      let found = false;
      for (i = 0; i < navigator.languages.length; i++) {
        let lang = navigator.languages[i].split("-")[0];
        for (j = 0; j < this.$root.$i18n.availableLocales.length; j++) {
          if (lang == this.$root.$i18n.availableLocales[j]) {
            found = true;
            if (this.$root.$i18n.locale != lang) {
              console.log("Setting locale " + lang);
              this.$root.$i18n.locale = lang;
            }
            break;
          }
        }
        if (found == true) break;
      }
    },
  },
  mounted() {
    console.log("SignIn2FA");
    this.token2fa = "";
    this.token = "";
    console.log("token:", this.token2fa);
    console.log("token2fa:", this.token2fa);
    this.user = this.$root.sessionManager.userName;
    this.password = this.$root.sessionManager.password;
    console.log("user:", this.user);
    console.log("password:", this.password);

    //this.getEmail(this.token);
  },
  created() {
    this.setLocale();
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
@import "../../assets/custom/css/login.css";
</style>
