<template>
  <div id="login">
    <div v-show="accessToken === ''" class="contenedor_login">
      <!--<img src="../../../public/img/logo.png" alt="" />-->
      <div id="logo" />
      <form>
        <div>
          <!--<i class="fa fa-user" aria-hidden="true"></i>-->
          <i class="material-icons" aria-hidden="true">person</i>
          <input
            type="text"
            id="user"
            v-model="user"
            :placeholder="$t('login.email')"
            v-on:keyup.enter="login"
          />
        </div>
        <div>
          <!--<i class="fa fa-lock" aria-hidden="true"></i>-->
          <i class="material-icons" aria-hidden="true">key</i>
          <input
            :type="passwordFieldType"
            v-model="password"
            :placeholder="$t('login.password')"
            v-on:keyup.enter="login"
          />
          <!--:class="{
              'fa fa-eye': !this.passwordVisibility,
              'fa fa-eye-slash': this.passwordVisibility,
            }"-->

          <i
            class="material-icons"
            style="position: absolute; top: 8px; right: 12px; cursor: pointer"
            @click="switchPasswordVisibility()"
            >{{ this.passwordVisibility ? "visibility" : "visibility_off" }}</i
          >
        </div>
        <p class="login_msg" v-if="error">
          {{ $t("login.invalid_credentials") }}
        </p>

        <a href="/rememberme">{{ $t("login.forgot_password") }}</a>

        <button class="login_btn" type="button" @click="login">
          {{ $t("login.enter") }}
        </button>
      </form>
      <p v-if="this.ServiceConditions" class="login_condiciones">
        {{ $t("login.entering_our_web") }}
        <a href="/main/view" target="_blank">{{
          $t("login.terms_of_service")
        }}</a>
      </p>
      <div class="copy_login">
        <p>
          {{ $t("common.version") }}: {{ Version }} |
          {{ $t("common.copyright", { year: new Date().getFullYear() }) }} |
          {{ $t("common.all_rights_reserved") }}
        </p>
      </div>
    </div>
  </div>
</template>

<script>
const axios = require("axios").default;
axios.defaults.withCredentials = false;
export default {
  name: "SignIn",
  props: {
    token: {
      type: String,
      required: false,
      default: "",
    },
    email: {
      type: String,
      required: false,
      default: "",
    },
  },
  data() {
    return {
      user: "",
      password: "",
      accessToken: "",
      token2fa: "",
      code2fa: "",
      remember: 0,
      passwordVisibility: false,
      error: false,
    };
  },
  computed: {
    passwordFieldType() {
      return this.passwordVisibility ? "text" : "password";
    },
  },
  methods: {
    switchPasswordVisibility() {
      console.log("switchPasswordVisibility");
      this.passwordVisibility = !this.passwordVisibility;
    },
    async login() {
      this.error = false;
      console.log("SignIn login function");
      console.log("email", this.user);
      console.log("password", this.password);
      console.log("token", this.accessToken);
      console.log("code2fa", this.code2fa);
      console.log("remember", this.remember);
      this.token2fa = "";
      let local_token2fa = localStorage.getItem("token2fa:" + this.user);
      if (local_token2fa != null){
        console.log("exists in localstorage token2fa for:", this.user);
        this.token2fa = local_token2fa;
      }
      console.log("token2fa", this.token2fa );
      try {
        const response = await this.$root.sessionManager.loginex2fa(
          this.user,
          this.password,
          this.accessToken,
          this.token2fa,
          this.code2fa,
          this.remember,
        );

        if (response.result == this.S_2FA_CODE_REQUIRED) {
          console.log("Required 2FA");
          this.$router.push({ name: "SignIn2FA" });
        } else {
          if (response.userInfo !== undefined) {
            this.$root.dataManager.APIServer =
              "https://" + this.$root.sessionManager.recServer;
            this.$root.dataManager.sessionId =
              this.$root.sessionManager.sessionId;
            this.$root.dataManager.userName =
              this.$root.sessionManager.userName;
            await this.$root.sessionManager.getConfig();
            this.$router.push({ name: "CamerasMain" });
          }
          if (response.clients !== undefined) {
            this.$router.push({ name: "SignInClient" });
          }
        }
      } catch (error) {
        console.log("login error in login function");
        console.error(error);
        this.error = true;
      }
    },
    setLocale(locale) {
      if (locale !== undefined) {
        if (this.$i18n.locale != locale) {
          console.log("Setting locale " + locale);
          this.$i18n.locale = locale;
        }
        return;
      }
      let i, j;
      let found = false;
      for (i = 0; i < navigator.languages.length; i++) {
        let lang = navigator.languages[i].split("-")[0];
        for (j = 0; j < this.$root.$i18n.availableLocales.length; j++) {
          if (lang == this.$root.$i18n.availableLocales[j]) {
            found = true;
            if (this.$root.$i18n.locale != lang) {
              console.log("Setting locale " + lang);
              this.$root.$i18n.locale = lang;
            }
            break;
          }
        }
        if (found == true) break;
      }
    },
  },
  mounted() {
    console.log("SignIn", this.APIUrl);
    document.getElementById("user").focus();
    document.title = this.$t("login.welcome");
    this.accessToken = this.token;
    console.log("token:", this.accessToken);
    
    if (this.accessToken !== "") {
      this.user = this.email;
      this.login();
    }

    //const userInfo = JSON.parse(sessionStorage.getItem("userInfo"));
    if (this.$root.sessionManager.isLoggedIn()) {
      console.log("sessionID", this.$root.sessionManager.sessionId);
      console.log("router to CamerasMain");
      this.$router.push({ name: "CamerasMain" });
      return;
    }
  },
  created() {
    this.setLocale();
  },
};
</script>

<style scoped>
@import "../../assets/custom/css/login.css";
</style>
