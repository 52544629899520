<template>
  <menu-main></menu-main>
  <div id="twoFactor">
    <header-main> </header-main>
    <div class="cuerpo_principal">
      <div id="confUser" class="container">
        <div class="row">
          <div
            class="col-12 offset-0 col-sm-10 offset-sm-1 col-md-8 offset-md-2 col-md-8 offset-md-2 col-lg-6 offset-lg-3 visor_box"
          >
            <ul class="nav nav-tabs nav-fill" role="tablist"></ul>
            <button
              @click="closeWindow"
              type="button"
              class="close_props material-icons"
              style="float: right"
              aria-label="Close"
            >
              close
            </button>
            <div class="tab-content" id="npContenido" style="border: 0px">
              <div
                class="tab-pane fade show active"
                id="informacion"
                role="tabpanel"
                aria-labelledby="informacion-tab"
              >
                <div class="sw_prog">
                  <div class="sw_contenedor">
                    <p>{{ $t("settings.two_factor_email") }}</p>
                    <div class="sw_cam">
                      <input
                        type="checkbox"
                        id="swTwoFactor"
                        v-model="enabled2fa"
                        class="switch_input"
                      />
                      <label for="swTwoFactor" class="switch_label"></label>
                    </div>
                  </div>
                </div>
                <div class="msg">
                  <p>{{ errorMessage }}</p>
                </div>
              </div>
            </div>

            <div class="btn_guardar">
              <button @click.prevent="save">
                <a href="#">{{ $t("common.save") }}</a>
                <div
                  v-if="saving"
                  class="spinner-border spinner-border-sm"
                  role="status"
                >
                  <span class="sr-only">Saving...</span>
                </div>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <menu-footer active-state=""></menu-footer>
</template>

<script>
//const axios = require("axios").default;
//axios.defaults.withCredentials = false;

export default {
  name: "TwoFactor",
  data() {
    return {
      error: false,
      saving: false,
      errorMessage: "",
      email: "",
      enabled2fa: false,
    };
  },
  methods: {
    validateEmail(email) {
      var re = /\S+@\S+\.\S+/;
      return re.test(email);
    },
    closeWindow() {
      console.log("closeWindow");
      //this.$router.push({ name: "CamerasMain" });
      console.log(this.$router);
      this.$router.go(-1);
    },
    save() {
      this.error = false;
      this.errorMessage = "";
      console.log("save", this.enabled2fa);
      if (!this.validateEmail(this.email)){
        this.error = true;
        this.errorMessage = this.$t("users.error_user_email");
        this.enabled2fa = false;
        return;
      }
      this.$root.sessionManager.enabled2fa = this.enabled2fa ? 1 : 0;
      if (this.enabled2fa) {
        this.error = true;
        this.errorMessage = this.$t("users.email_sent");
      }
      this.update2FA();
    },
    async update2FA() {
      this.saving = true;
      console.log("update2FA");
      if (!this.enabled2fa){
        localStorage.removeItem("token2fa:" + this.email);
      }
      try {
        await this.$root.dataManager.modifyUser2FA(this.enabled2fa);
        this.saving = false;
      } catch (error) {
        console.log("update2FA error");
        console.error(error);
        this.error = true;
        this.errorMessage = this.$t("users.error_updating_2fa");
      }
    },
  },
  mounted() {
    console.log("TwoFactor");
    this.enabled2fa = Boolean(this.$root.sessionManager.enabled2fa);
    this.email = this.$root.sessionManager.userName;
    console.log("enabled2fa:",this.enabled2fa);
    console.log("email:",this.email);
    //const userInfo = JSON.parse(sessionStorage.getItem("userInfo"));
    if (!this.$root.sessionManager.isLoggedIn()) {
      console.log("router to signin");
      this.$router.push({ name: "SignIn" });
    }
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.container .nav-tabs {
  border-bottom: none !important;
}
#npContenido {
  padding-top: 65px;
}

.nom_input > div {
  position: relative;
}
.nom_input > div i {
  padding-left: 8px;
  font-size: 20px;
  line-height: 1;
  vertical-align: top;
  padding-top: 2px;
}
.sw_prog .sw_contenedor p {
  width: 320px;
  text-align: left;
}
</style>