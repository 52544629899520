<template>
  <menu-main></menu-main>

  <div id="users">
    <header-main> </header-main>

    <div class="cuerpo_principal">
      <div v-show="editUsersPriv" class="mosaico_cam">
        <div class="main_content">
          <div class="grid_busc">
            <form class="buscador" @submit.prevent>
              <input style="padding-right: 40px;"
                id="search"
                type="search"
                v-model="search"
                :placeholder="$t('common.search')"
              />
              <span class="material-icons refresh-button"
                @click="refresh">refresh</span>
            </form>
            
          </div>
        </div>
      </div>


      <div class="container">
        <p v-if="editUsersPriv && loaded && !users.length" class="no_data_msg">
          {{ $t('users.no_users') }}
        </p>
        <p v-if="!editUsersPriv" class="no_data_msg">
          {{ $t('common.no_privileges') }}
        </p>
        <div v-if="editUsersPriv && users.length" class="row">
          <div id="tgrab" class="col-12 offset-0 col-md-10 offset-md-1">
            <div class="table-responsive">
              <table class="table align-middle">
                <thead>
                  <tr>
                    <th scope="col"></th>
                    <th scope="col">{{ $t('users.first_name') }}</th>
                    <th scope="col">{{ $t('users.surname') }}</th>
                    <th scope="col">{{ $t('users.email') }}</th>
                    <th scope="col"></th>
                  </tr>
                </thead>
                <tbody>
                  <!--<template v-for="(user, index) in filterUsers" :key="user.id">-->
                    <user-item v-for="(user, index) in filterUsers" :key="user.id"
                      :index="index"
                      :paramId="user.id"
                      :name="user.name"
                      :surname="user.surname"
                      :email="user.email"
                      :privcod="user.privcod"
                      :enabled="Boolean(user.enabled)"
                      @delete-event="showDeleteConfirmation"
                      @edit-event="editUser"
                    >
                    </user-item>
                  <!--</template>-->
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div v-if="editUsersPriv" id="btnAgregar">
<button
            v-show="editUsersPriv"
            @click="addUser"
            class="agregar_opcion"
          >
          <span class="material-icons">add</span>

            <!--<strong>Añadir<br />Cámara</strong>-->
						<!--<i class="fa fa-plus"/>-->

          </button>
 
        </div>
      </div>
    </div>
  </div>

  <menu-footer active-state="users"></menu-footer>
</template>

<script>
import UserItem from "./UserItem.vue";
//const axios = require("axios").default;
//axios.defaults.withCredentials = false;
const Swal = require("sweetalert2");

export default {
  name: "UsersMain",
  components: {
    UserItem,
  },
  data() {
    return {
      users: [],
      search: "",
      error: false,
      loaded: false,
      editUsersPriv: false,
    };
  },
  computed: {
    filterUsers() {
      const filter1 = this.filterUsersByName();
      const filter2 = this.filterUsersBySurname();
      const filter3 = this.filterUsersByEmail();
      if (filter1 || filter2 || filter3) {
        return [...new Set([...filter1, ...filter2, ...filter3])];
      }
      return this.users;
    },
  },

  methods: {
    async refresh() {
      this.loaded=false;
      console.log("refresh");
      this.users=[];
      this.getUsers();
    },
    filterUsersByName() {
      return this.search
        ? this.users.filter((user) =>
            user.name.toLowerCase().includes(this.search.toLowerCase())
          )
        : null;
    },
    filterUsersBySurname() {
      return this.search
        ? this.users.filter((user) =>
            user.surname.toLowerCase().includes(this.search.toLowerCase())
          )
        : null;
    },
    filterUsersByEmail() {
      return this.search
        ? this.users.filter((user) =>
            user.email.toLowerCase().includes(this.search.toLowerCase())
          )
        : null;
    },

    showDeleteConfirmation(id, index) {
      console.log("showDeleteConfirmation:", id, index);
      Swal.fire({
        /*title: this.recordings[index].camName,*/
        text: this.$t('users.user_del_confirmation',{useremail: this.users[index].email}),
        icon: "question",
        showCancelButton: true,
        confirmButtonText: this.$t('common.ok'),
        cancelButtonText: this.$t('common.cancel'),
        //confirmButtonColor: getComputedStyle(document.documentElement).getPropertyValue('--main-accent-color') /*"#61aaea"*/,
        //cancelButtonColor: getComputedStyle(document.documentElement).getPropertyValue('--main-inactive-color') /*"#c0c0c0"*/,
        position: "center",
      }).then((result) => {
        if (result.isConfirmed) {
          console.log("Delete:", id, index);
          this.deleteUser(id, index);
        }
      });
    },

    async deleteUser(id, index) {
      try {
        await this.$root.dataManager.deleteUser(id);
        this.users.splice(index, 1);
      } catch (error) {
        console.log("user delete ERROR", error);
      }
    },
    editUser(id, index) {
      console.log("Edit user", id, index);
      this.$router.push({ name: "UserEdit", params: { editId: id } });
    },

    addUser() {
      console.log("add user");
      this.$router.push({ name: "UserAdd" });
    },
    async getUsers() {
      this.loaded=false;
      try {
        const response = await this.$root.dataManager.loadUsers();
        console.log("getUsers OK");
        this.users = [...response.users];
        this.loaded = true;
        console.log("users", this.users);
      } catch (error) {
        console.log("getUsers error");
        
        Swal.fire({
          text: this.$t('common.session_expired'),
          icon: "error",
          confirmButtonText: this.$t('common.ok'),
          position: "center",
        }).then((result) => {
          console.log(result);
          if (result.isConfirmed) {
            this.logOut();
          }
        });        
      }
    },

  },
  mounted() {
    document.documentElement.style.scrollBehavior = 'auto';
    console.log("UsersMain");
    document.getElementById("search").focus();
    document.getElementById("search").blur();
    document.title = this.$t('common.users');
    //const userInfo = JSON.parse(sessionStorage.getItem("userInfo"));
    if (!this.$root.sessionManager.isLoggedIn()) {
      console.log("router to signin");
      this.$router.push({ name: "SignIn" });
    }
    const userPriv = this.$root.sessionManager.privileges;
    console.log("UsersMain, userPriv:", userPriv);
    this.editUsersPriv = userPriv["editUsers"];

    console.log("UsersMain, sessionid:", this.$root.sessionManager.sessionId);
    this.getUsers();
  },
  unmounted() {
    var oldMenu=document.querySelector('.dropdown-menu.show');
		if (oldMenu!=null) oldMenu.classList.remove("show");
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>