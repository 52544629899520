<template>
  <div id="login">
    <div class="contenedor_login">
      <!--<img src="../../../public/img/logo.png" alt="" />-->
			<div id="logo"/>
      <form>
        <div>
          <!--<i class="fa fa-user" aria-hidden="true"></i>-->
          <i class="material-icons" aria-hidden="true">person</i>
          <input
            type="email"
            id="email"
            v-model="email"
            :placeholder="$t('login.email')"
            v-on:keyup.enter="login"
          />
        </div>
        <p class="login_msg" v-if="message != ''">{{ message }}</p>
        <button class="login_btn" type="button" @click="send">
          {{ $t('login.send') }}
        </button>
      </form>
      <div class="copy_login">
        <p>{{ $t('common.copyright', {year: new Date().getFullYear()}) }} | {{ $t('common.all_rights_reserved') }}</p>
      </div>
    </div>
  </div>
</template>
<script>
const axios = require("axios").default;
axios.defaults.withCredentials = false;
export default {
  name: "RememberMe",
  data() {
    return {
      email: "",
      message: "",
    };
  },
  mounted() {
    console.log("RememberMe");
  },
  methods: {
    validateEmail(email) {
      var re = /\S+@\S+\.\S+/;
      return re.test(email);
    },
    send() {
      this.message = "";
      console.log("send.");
      if (this.validateEmail(this.email)){
        console.log("rememberMe");
        this.rememberMe()

      }else{
        this.message = this.$t('login.invalid_email');
      }
    },
    async rememberMe() {
  
      this.error = false;
      const url_link = window.location.protocol + "//" + window.location.host + "/updatepassword";
      const url = this.APIUrl + "/remember";
      const data = { email: this.email, url: url_link};
      console.log("data:",data);
      try {
        const response = await axios.post(url, data);
        console.log(response.status);
        console.log(response.data);
        if (response.status == 200 && response.data.result == 0) {
          console.log("rememberMe OK");
          this.message = this.$t('login.review_inbox');
        } else {
          console.log("rememberMe error");
          this.message = this.$t('login.error_sending_email');
        }
      } catch (error) {
        console.log("rememberMe error");
        console.error(error);
        this.message = this.$t('login.error_sending_email');
      }
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
@import "../../assets/custom/css/login.css";
</style>