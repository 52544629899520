<template>
  <menu-main></menu-main>
  <div id="labelAdd">
    <header-main> </header-main>
    <div class="cuerpo_principal">
      <div id="confProg" class="container">
        <div class="row">
          <div class="col-12 offset-0 col-sm-10 offset-sm-1 col-md-8 offset-md-2 col-md-8 offset-md-2 col-lg-6 offset-lg-3 visor_box">
            <div class="alert alert-custom" id="msg-alert">
              <p>{{ errorMessage }}</p>
            </div>
            <ul class="nav nav-tabs nav-fill" role="tablist">
              <li class="nav-item" role="presentation">
                <button
                  class="nav-link active"
                  id="informacion-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#informacion"
                  type="button"
                  role="tab"
                  aria-controls="informacion"
                  aria-selected="true"
                >
                {{ $t('common.information') }}
                </button>
              </li>
              <li class="nav-item" role="presentation">
                <button
                  class="nav-link"
                  id="cameras-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#cameras"
                  type="button"
                  role="tab"
                  aria-controls="cameras"
                  aria-selected="false"
                >
                {{ $t('common.cameras') }}
                </button>
              </li>
              <li class="nav-item" role="presentation">
                <button
                  class="nav-link"
                  id="groups-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#groups"
                  type="button"
                  role="tab"
                  aria-controls="groups"
                  aria-selected="false"
                >
                {{ $t('common.groups') }}
                </button>
              </li>
              
            </ul>
						<button
              @click="closeWindow"
              type="button"
              class="close_props material-icons"
              style="float: right"
              aria-label="Close"
            >close</button>
            <div class="tab-content" id="npContenido">
              <div
                class="tab-pane fade show active"
                id="informacion"
                role="tabpanel"
                aria-labelledby="informacion-tab"
              >
                <div class="conf_box">
                  <div class="nom_input">
                    <p>Nombre</p>
                    <input class="text_input"
                      v-model="name"
                      id="label_name"
                      type="text"
                      :placeholder="$t('labels.label_name')"
                    />
                  </div>
                  
                </div>
              </div>

              

              <div
                class="tab-pane fade"
                id="cameras"
                role="tabpanel"
                aria-labelledby="cameras-tab"
              >
                <div class="conf_cameras">
                  <div class="cam_layout">
                    <div v-for="(camera, index) in cameras" :key="camera.id">
                      <camera-switch
                        :index="index"
                        :id="camera.id"
                        :name="camera.name"
                        :model="camera.model"
                        :initialSelected="isCameraInInstallation(camera.id)"
                      ></camera-switch>
                    </div>
                  </div>
                </div>
              </div>

              <div
                class="tab-pane fade"
                id="groups"
                role="tabpanel"
                aria-labelledby="groups-tab"
              >
                <div class="conf_cameras">
                  <div class="cam_layout">
                    <div v-for="(group, index) in groups" :key="group.id">
                      <group-switch
                        :index="index"
                        :id="group.id"
                        :name="group.name"
                        :geometry="group.geometry"
                        :initialSelected="isGroupInInstallation(group.id)"
                      ></group-switch>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div :class="!loaded ? 'disabled_btn_guardar' : 'btn_guardar'">
              <button :disabled="!loaded" @click.prevent="save">
                <a href="#">{{ $t('common.save') }}</a>
                <div
                  v-if="saving"
                  class="spinner-border spinner-border-sm"
                  role="status"
                >
                  <span class="sr-only">Saving...</span>
                </div>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <menu-footer active-state="users"></menu-footer>
</template>

<script>
import CameraSwitch from "../Cameras/CameraSwitch.vue";
import GroupSwitch from "../Groups/GroupSwitch.vue";
import $ from "jquery";

//const axios = require("axios").default;
//axios.defaults.withCredentials = false;

export default {
  name: "UserEdit",
  components: {
    CameraSwitch,
    GroupSwitch,
  },
  props: {
    editId: String,
  },
  data() {
    return {
      error: false,
      saving: false,
      errorMessage: "",
      //cameras: [],
      //groups: [],
      selectedCameras: [],
      selectedGroups: [],
      loaded: false,
      savedInstallationCameras: [],
      savedInstallationGroups: [],
      id: -1,
      name: "",
      //loading: true,
    };
  },
  mounted() {
    this.id = parseInt(this.editId);
    console.log("edit user with id:", this.id);
    this.getInstallation(this.id);
    $("#msg-alert").hide();
    $("#cameras-tab").removeClass("active");
    $("#groups-tab").removeClass("active");
    $("#informacion-tab").addClass("active");
  },
  methods: {
    showMsgAlert() {
      $("#msg-alert")
        .fadeTo(200, .80, function() {
					setTimeout(function() {
						$("#msg-alert").fadeTo(200,0);
						},2000)
				});
    },
    closeWindow() {
      console.log("closeWindow");
      //this.$router.push({ name: "LabelsMain" });
      this.$router.go(-1);
    },
    hasPrivilegeCamera(camId) {
      return this.userCamerasIds.includes(camId) ? true : false;
    },
    hasPrivilegeGroup(groupId) {
      return this.userGroupsIds.includes(groupId) ? true : false;
    },
    verifyData() {
      if (this.name === "") {
        this.errorMessage = this.$t('labels.name_required');
        //this.error = true;
        return false;
      }
      return true;
    },
    save() {
      console.log("save label");
      this.errorMessage = "";
      //this.error = false;
      this.selectedCameras = $("input:checkbox[name=swCamSwitch]:checked")
        .map(function () {
          console.log("Id: " + $(this).attr("id") + " Value: " + $(this).val());
          return $(this).attr("id").split("-")[1];
        })
        .get();
      this.selectedGroups = $("input:checkbox[name=swGroupSwitch]:checked")
        .map(function () {
          console.log("Id: " + $(this).attr("id") + " Value: " + $(this).val());
          return $(this).attr("id").split("-")[1];
        })
        .get();
      if (this.verifyData()) {
        console.log("verify data OK");
        this.updateLabel(this.id);
      } else {
        this.showMsgAlert();
      }
    },

    async updateLabel(instId) {
      this.saving = true;
      console.log("updating installation");
      try {
        await this.$root.dataManager.modifyInstallation(instId,this.name);
        console.log("update installation OK", instId);
        //const data = { this.selectedCameras.toString();
        await this.$root.dataManager.deleteInstallationElements(instId);  
        await this.$root.dataManager.addInstallationElements(instId,this.selectedCameras.toString(),this.selectedGroups.toString());
        this.saving = false;
        this.$router.push({ name: "LabelsMain" });
      }
      catch (error) {
        console.log("updateLabel error");
        console.error(error);
        //this.error = true;
        this.saving = false;
        this.errorMessage = this.$t('labels.error_updating_label');
        this.showMsgAlert();
      }
    },

    async getInstallation(id) {
      this.loaded=false;
      //this.loading=true;
      console.log("getLabel:", id);
      try {
        var response=await this.$root.dataManager.loadInstallation(id);
        console.log(response);
        this.name = response.installation.name;
        response=await this.$root.dataManager.loadInstallationElements(id);
        this.savedInstallationCameras = [...response.cameras];
        this.savedInstallationGroups = [...response.groups];
        this.loaded=true;
        //this.loading=false;
      }
      catch (error) {
        console.log("getGroup error");
        console.error(error);
        //this.error = true;
      }
    },
    isCameraInInstallation(cameraID) {
      //console.log("isCameraInInstallation", cameraID);
      //console.log(this.savedInstallationCameras);
      for (let camera of this.savedInstallationCameras) {
        //console.log("camera", camera);
        if (camera == cameraID) {
          //console.log("exists in savedInstallationCameras");
          return true;
        }
      }
      return false;
    },
    isGroupInInstallation(groupID) {
      //console.log("isCameraInGroup", cameraID);
      //console.log(this.savedGroupCameras);
      for (let group of this.savedInstallationGroups) {
        //console.log("camera", camera);
        if (group == groupID) {
          //console.log("exists in savedGroupCameras");
          return true;
        }
      }
      return false;
    },

   
  },
  computed: {
    cameras () {
      if (this.loaded) return this.$root.dataManager.cameras;
      return [];
    },
    groups () {
      if (this.loaded) return this.$root.dataManager.groups;
      return [];
    }

  }
};
</script>

<style scoped>
.sw_prog .sw_contenedor p {
  width: 320px;
  text-align: left;
}
</style>
