<template>
  <div id="myHeader" class="cabecera_fixed container-fluid">
    
    <div class="row justify-content-between">
      <div class="col-3" style="padding: 0px">
        <nav id="menuLateral" class="navbar navbar-light">
          <button
            class="navbar-toggler"
            type="button"
            data-bs-toggle="offcanvas"
            data-bs-target="#offcanvasNavbar"
            aria-controls="offcanvasNavbar"
          >
            <!--<span class="navbar-toggler-icon"></span>-->
						<span class="material-icons">menu</span>
          </button>
        </nav>
      </div>
      <div class="col-6">
        <div class="logo_header">
          <!--<h1>{{ title }}</h1>-->
					<!--<img id="image-header" src="../../public/img/logo.png" />-->
					<div id="logo"></div>
        </div>
      </div>
      <div class="col-3" style="padding: 0px">
        <div v-if="this.$root.sessionManager.isLoggedIn()" class="progreso_menu">
          <h4>{{ userName }}</h4>
          <div class="progress">
            <div
              class="progress-bar"
              :style="capacity_style"
              role="progressbar"
              aria-valuenow="64"
              aria-valuemin="0"
              aria-valuemax="100"
            >
              <span>{{ capacity }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
//const axios = require("axios").default;
//axios.defaults.withCredentials = false;
const Swal = require("sweetalert2");
import DataManager from "../assets/custom/js/DataManager.js";
import $ from "jquery";
export default {
  name: "HeaderMain",
  data() {
    return {
      userName: String,
      capacity: String,
    };
  },
  props: {
    // title: {
    //   type: String,
    //   required: true,
    //   default: "",
    // },
    /*capacity: {
      type: String,
      required: true,
      default: "12%",
    },*/
  },
  computed: {
    capacity_style() {
      return "width: " + this.capacity;
    },
  },
  mounted() {
    console.log("mounted HeaderMain");
    
//    console.log("Window width", $(window).width());

    if ($(window).width() > 1) {
      $(window).scroll(function () {
        if ($(this).scrollTop() > 1) {
          $("#myHeader").addClass("menu_scroll_");
        } else {
          $("#myHeader").removeClass("menu_scroll_");
        }
      });
    }
    //console.log("Clients",this.$root.sessionManager.clients);
    if (this.$root.sessionManager.isLoggedIn()) {
      console.log("userLogin OK", this.$root.sessionManager.userName);
      //const userInfo = JSON.parse(sessionStorage.getItem("userInfo"));

      //this.capacity = sessionStorage.getItem("capacity:" + userInfo.sessionId);
      this.userName = this.$root.sessionManager.userName;
      this.$root.dataManager.sessionId=this.$root.sessionManager.sessionId;
      this.$root.dataManager.APIServer="https://" + this.$root.sessionManager.recServer;
      this.$root.dataManager.userName=this.$root.sessionManager.userName;
      let topic="";
      if (this.$root.sessionManager.userType == 0) {
        topic = "users/" + this.$root.sessionManager.userId;
      }
      if (this.$root.sessionManager.userType == 1) {
        topic = "depusers/" + this.$root.sessionManager.userId;
      }
      
      this.$root.dataManager.startMQTTClient(
        this.$root.sessionManager.recServer,
        topic,
        this.MQTTUser,
        this.MQTTPassword
      );
      this.$root.dataManager.initializeFirestore(
        this.$root.sessionManager.platformId,
        this.$root.sessionManager.userType,
        this.$root.sessionManager.userId,
        this.$root.sessionManager.token
      );
      this.getLanguage();
      this.getStorage();
      this.getInitialData();
    }
    else if (this.$root.sessionManager.isLoggedInAsOperator()) {
      console.log("As operator");
    }

    else {
      console.log("router to signin");
      this.$router.push({ name: "SignIn" });
    }
  },
  unmounted() {
    console.log("unmounted HeaderMain");
  },
  methods: {

    async getStorage() {
      try {
        const response = await this.$root.dataManager.getStorage();
        console.log("getStorage OK");
        this.capacity  = response.storage.toFixed(2) + "%";
        // const userInfo = JSON.parse(sessionStorage.getItem("userInfo"));
        // sessionStorage.setItem(
        //   "capacity:" + userInfo.sessionId,
        //   this.capacity
        // );
      } catch (error) {
        console.log("getStorage error");
        console.error(error);
        this.capacity = "0%";
      }
    },
    async getLanguage() {
      try {
        const response = await this.$root.dataManager.getLanguage();
        console.log("getLanguage OK");
        //this.capacity  = response.storage.toFixed(2) + "%";
        console.log(response);
        this.setLocale(response.language);
      } catch (error) {
        console.log("getLanguage error");
        this.setLocale();
        console.error(error);
        
      }
    },
    
    setLocale(locale) {
      if (locale!==undefined)
      {
        if (this.$i18n.locale!=locale) {
          console.log("Setting locale " + locale);
          this.$root.$i18n.locale=locale;
        }
        return;
      }
      let i,j;
      let found=false;
      for (i=0;i<navigator.languages.length;i++) {
        let lang=navigator.languages[i].split("-")[0];
        for (j=0;j<this.$root.$i18n.availableLocales.length;j++) {
          if (lang==this.$root.$i18n.availableLocales[j]) {
            found=true;
            if (this.$root.$i18n.locale!=lang) {
              console.log("Setting locale " + lang);
              this.$root.$i18n.locale=lang;
            }
            break;
          }
        }
        if (found==true) break;
      }
    },
    async getInitialData() {
      try {

        await this.$root.dataManager.loadCameras();
        await this.$root.dataManager.loadGroups();
        await this.$root.dataManager.loadRecordings();
        await this.$root.dataManager.loadTasks();
      }
      catch (response) {
        if (response.result == DataManager.S_SESSIONERROR) {
          Swal.fire({
            text: "Sesión finalizada",
            icon: "error",
            confirmButtonText: "Aceptar",
            position: "center",
          }).then((result) => {
            console.log(result);
            if (result.isConfirmed) {
              this.logOut();
            }
          });
        }
      }
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>



</style>