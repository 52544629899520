<template>
  <menu-main></menu-main>
  <div id="labelAdd">
    <header-main> </header-main>
    <div class="cuerpo_principal">
      <div id="confProg" class="container">
        <div class="row">
          <div class="col-12 offset-0 col-sm-10 offset-sm-1 col-md-8 offset-md-2 col-md-8 offset-md-2 col-lg-6 offset-lg-3 visor_box">
            <div class="alert alert-custom" id="msg-alert">
              <p>{{ errorMessage }}</p>
            </div>
            <ul class="nav nav-tabs nav-fill" role="tablist">
              <li class="nav-item" role="presentation">
                <button
                  class="nav-link active"
                  id="informacion-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#informacion"
                  type="button"
                  role="tab"
                  aria-controls="informacion"
                  aria-selected="true"
                >
                  {{ $t('common.information') }}
                </button>
              </li>
              <li class="nav-item" role="presentation">
                <button
                  class="nav-link"
                  id="cameras-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#cameras"
                  type="button"
                  role="tab"
                  aria-controls="cameras"
                  aria-selected="false"
                >
                {{ $t('common.cameras') }}
                </button>
              </li>
              <li class="nav-item" role="presentation">
                <button
                  class="nav-link"
                  id="groups-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#groups"
                  type="button"
                  role="tab"
                  aria-controls="groups"
                  aria-selected="false"
                >
                {{ $t('common.groups') }}
                </button>
              </li>
              
            </ul>
						<button
              @click="closeWindow"
              type="button"
              class="close_props material-icons"
              style="float: right"
              aria-label="Close"
            >close</button>
            <div class="tab-content" id="npContenido">
              <div
                class="tab-pane fade show active"
                id="informacion"
                role="tabpanel"
                aria-labelledby="informacion-tab"
              >
                <div class="conf_box">
                  <div class="nom_input">
                    <p>{{ $t('common.name') }}</p>
                    <input class="text_input"
                      v-model="name"
                      id="label_name"
                      type="text"
                      :placeholder="$t('labels.label_name')"
                    />
                  </div>
                </div>
              </div>

              
              <div
                class="tab-pane fade"
                id="cameras"
                role="tabpanel"
                aria-labelledby="cameras-tab"
              >
                <div class="conf_cameras">
                  <div class="cam_layout">
                    <div v-for="(camera, index) in this.$root.dataManager.cameras" :key="camera.id">
                      <camera-switch
                        :index="index"
                        :id="camera.id"
                        :name="camera.name"
                        :model="camera.model"
                      ></camera-switch>
                    </div>
                  </div>
                </div>
              </div>

              <div
                class="tab-pane fade"
                id="groups"
                role="tabpanel"
                aria-labelledby="groups-tab"
              >
                <div class="conf_cameras">
                  <div class="cam_layout">
                    <div v-for="(group, index) in this.$root.dataManager.groups" :key="group.id">
                      <group-switch
                        :index="index"
                        :id="group.id"
                        :name="group.name"
                        :geometry="group.geometry"
                      ></group-switch>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="btn_guardar">
              <button @click.prevent="save">
                <a href="#">{{ $t('common.save') }}</a>
                <div
                  v-if="saving"
                  class="spinner-border spinner-border-sm"
                  role="status"
                >
                  <span class="sr-only">Saving...</span>
                </div>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <menu-footer active-state="labels"></menu-footer>
</template>

<script>
import CameraSwitch from "../Cameras/CameraSwitch.vue";
import GroupSwitch from "../Groups/GroupSwitch.vue";
import $ from "jquery";

//const axios = require("axios").default;
//axios.defaults.withCredentials = false;

export default {
  name: "LabelAdd",
  components: {
    CameraSwitch,
    GroupSwitch,
  },
  data() {
    return {
      //error: false,
      saving: false,
      errorMessage: "",
      //cameras: [],
      //groups: [],
      selectedCameras: [],
      selectedGroups: [],
      name: "",
    };
  },
  mounted() {
    $("#msg-alert").hide();
    //this.getCameras();
    //this.getGroups();
  },
  computed: {
    
  },
  methods: {
    /*selectLabel() {
      this.getCameras();
      this.getGroups();
    },*/
    showMsgAlert() {
      $("#msg-alert")
        .fadeTo(200, .80, function() {
					setTimeout(function() {
						$("#msg-alert").fadeTo(200,0);
						},2000)
				});
    },
    closeWindow() {
      console.log("closeWindow");
      //this.$router.push({ name: "LabelsMain" });
      this.$router.go(-1);
    },
    
    verifyData() {
      if (this.name === "") {
        this.errorMessage = this.$t('labels.name_required');
        this.error = true;
        return false;
      }
      return true;
    },
    save() {
      console.log("save label");
      this.errorMessage = "";
      this.error = false;
      this.selectedCameras = $("input:checkbox[name=swCamSwitch]:checked")
        .map(function () {
          console.log("Id: " + $(this).attr("id") + " Value: " + $(this).val());
          return $(this).attr("id").split("-")[1];
        })
        .get();
      this.selectedGroups = $("input:checkbox[name=swGroupSwitch]:checked")
        .map(function () {
          console.log("Id: " + $(this).attr("id") + " Value: " + $(this).val());
          return $(this).attr("id").split("-")[1];
        })
        .get();
      

      if (this.verifyData()) {
        console.log("verify data OK");
        this.saveLabel();
      } else {
        this.showMsgAlert();
      }
    },

    async saveLabel() {

      this.saving = true;
      console.log("selectedCameras " + this.selectedCameras.toString());
      console.log("selectedGroups " + this.selectedGroups.toString());
      console.log("saving installation");
      try {
        var response = await this.$root.dataManager.addInstallation(this.name);
        const instId = response.instId;
        console.log("save installation OK", instId);
        //const data = { this.selectedCameras.toString();
        await this.$root.dataManager.addInstallationElements(instId,this.selectedCameras.toString(),this.selectedGroups.toString());
        this.saving = false;
        this.$router.push({ name: "LabelsMain" });
      }
      catch (error) {
        console.log("saveInstallation error");
        console.error(error);
        this.error = true;
        this.saving = false;
        this.errorMessage = this.$t('labels.error_saving_label');
        this.showMsgAlert();
      }
    },

  },
};
</script>

<style scoped>
.sw_prog .sw_contenedor p {
  width: 320px;
  text-align: left;
}
</style>
