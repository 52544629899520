<template>
  <menu-main></menu-main>
  <div id="passwordChange">
    <header-main></header-main>
    <div class="cuerpo_principal">
      <div id="confUser" class="container">
        <div class="row">
          <div class="col-12 offset-0 col-sm-10 offset-sm-1 col-md-8 offset-md-2 col-md-8 offset-md-2 col-lg-6 offset-lg-3 visor_box">
            <div class="alert alert-custom" id="msg-alert">
              <p>{{ messageAlert }}</p>
            </div>
            <ul class="nav nav-tabs nav-fill" role="tablist">
              
            </ul>
            <button
              @click="closeWindow"
              type="button"
              class="close_props material-icons"
              style="float: right"
              aria-label="Close"
            >close</button>
            <div class="tab-content" id="npContenido" style="border: 0px">
              <div
                class="tab-pane fade show active"
                id="informacion"
                role="tabpanel"
                aria-labelledby="informacion-tab">
                <div class="tab-content" id="hTabla">
                  <!--<div id="tgrab" class="col-12 offset-0 col-md-10 offset-md-1">-->
                    <div v-if="loading || saving" id="searching_msg">
                      <div
                        style="text-align: center"
                        class="spinner-border spinner-border-lx"
                        role="status"
                      ></div>
                      <p v-html="wifiMessage"></p>
                    </div>

                    <div v-if="wifiNetworks.length && !saving" class="table-responsive">
                      <table class="table align-middle" id="twifi">
                        <thead>
                          <tr>
                            <th scope="col"></th>
                            <th scope="col" style="max-width: 60px">SSID</th>
                            <th scope="col">{{ $t('cameras.security') }}</th>
                            <th scope="col"></th>
                          </tr>
                        </thead>
                        <tbody>
                          <!--<template v-for="(wifi,index) in wifiNetworks" :key="wifi.key">-->
                            <wifi-item v-for="(wifi,index) in wifiNetworks" :key="wifi.key"
                              :index="index"
                              :id="parseInt(wifi.id)"
                              :ssid="wifi.ssid"
                              :security="wifi.security"
                              :signal="parseInt(wifi.signal)"
                              :connected="wifi.connected"
                              @config-event="setWifiConfig"
                            >
                            </wifi-item>
                          <!--</template>-->
                        </tbody>
                      </table>
                    </div>
                  <!--</div>-->
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <menu-footer active-state="cameras"></menu-footer>
</template>

<script>
import WifiItem from "./WifiItem.vue";
import $ from "jquery";
//const axios = require("axios").default;
//axios.defaults.withCredentials = false;

const sleep = (ms) => new Promise((r) => setTimeout(r, ms));

export default {
  name: "CameraWifi",
  components: {
    WifiItem,
  },
  props: {
    editId: String,
  },
  data() {
    return {
      camId: -1,
      ssid_connected: "",
      sessionId: "",
      wifiNetworks: [],
      wifiMessage: this.$t('cameras.searching_wifi'),
      messageAlert: "",
      settingWifi: false,
      loading: true,
      saving: false,
    };
  },

  methods: {
    showMsgAlert(delay) {
      $("#msg-alert").fadeTo(200, 0.8, function () {
        setTimeout(function () {
          $("#msg-alert").fadeTo(200, 0);
        }, delay);
      });
    },
    closeWindow() {
      console.log("closeWindow");
      if (!this.settingWifi){
        //this.$router.push({ name: "CamerasMain" });
        this.$router.go(-1);
      }else{
         this.messageAlert = this.$t('cameras.configuring_the_camera_wait');
         this.showMsgAlert(4000);
      }

    },
    compare(a, b) {
      if (a.ssid < b.ssid) {
        return -1;
      }
      if (a.ssid > b.ssid) {
        return 1;
      }
      return 0;
    },

    async getWiFiConfig(id) {
      console.log("getwifiinfo");
      this.wifiMessage=this.$t('cameras.searching_wifi');
      this.loading=true;
      try {
        this.messageAlert = this.$t('cameras.error_getting_wifi');
        let response=await this.$root.dataManager.getWiFiConfig(id);
        this.ssid_connected = response.wifi.ssid;
        this.messageAlert = this.$t('cameras.error_getting_network_list');
        response=await this.$root.dataManager.getWiFiNetworks(id);
        this.wifiNetworks = [...response.wifi];
        for (var wifi of response.wifi) {
          if (wifi.ssid == this.ssid_connected) {
            wifi.connected = true;
            wifi.key = "1" + "-" + wifi.id  + "-" + wifi.signal ;
          } else {
            wifi.connected = false;
            wifi.key = "0" + "-" + wifi.id  + "-" + wifi.signal ;
          }
        }
        this.wifiNetworks.sort((a, b) => a.ssid.localeCompare(b.ssid));
        console.log("wifiNetworks:", this.wifiNetworks);
        this.loading=false;
      }
      catch (error) {
        this.loading=false;
        console.error("getWifiNetworks error");
        this.showMsgAlert(2000); 
      }
    },
    async setWifiConfig(ssid, security, password) {
      this.settingWifi = true;
      this.saving=true;
      this.wifiMessage = this.$t('cameras.new_password_sent') + "<br>" + this.$t('cameras.please_wait');
      let success=false;
      //this.showMsgAlert(5000);
      try {
        await this.$root.dataManager.setWiFiConfig(this.camId,ssid,password,security);
        this.wifiMessage = this.$t('cameras.configuring_wifi') + "<br>" + this.$t('cameras.please_wait');
        //this.showMsgAlert(4000);
        await sleep(10000);
        for (var i = 0; i < 20; i++) {
          try {
            const response = await this.$root.dataManager.getWiFiConfig(this.camId);
            var ssid_connected = response.wifi.ssid;
            if (ssid_connected == ssid) {
              this.ssid_connected = ssid;
              this.messageAlert = this.$t('cameras.camera_configured_successfully');
              this.settingWifi = false;
              this.saving=false;
              this.showMsgAlert(2000);
              success=true;
              break;
            }
            this.wifiMessage += ".";
            //this.showMsgAlert(2000);
            await sleep(5000);
          }
          catch (error) {
            console.log("error in for" ,error);
          }
        }
        if (!success)
          throw ("error");
        //this.isWifiConfigOK(this.camId, 10000, ssid);
        
      } catch (error) {
        this.settingWifi = false;
        this.saving=false;
        console.error("setWifiConf error");
        this.messageAlert = this.$t('cameras.error_configuring_wifi');
        this.showMsgAlert(2000);
      }
    },

  },
  mounted() {
    console.log("CameraWifi");
    $("#msg-alert").hide();
    this.camId = parseInt(this.editId);
    document.title = this.$t('cameras.wifi_config');
    //const userInfo = JSON.parse(sessionStorage.getItem("userInfo"));
    if (!this.$root.sessionManager.isLoggedIn()) {
      console.log("router to signin");
      this.$router.push({ name: "SignIn" });
    }
    console.log("CameraWifi, sessionid:", this.$root.sessionManager.sessionId);
    this.sessionId = this.$root.sessionManager.sessionId;
    const userPriv = this.$root.sessionManager.privileges;
    console.log("UsersMain, userPriv:", userPriv);
    this.addCamerasPriv = userPriv["addCameras"];
    //this.getWifiConf(this.camId);
    this.getWiFiConfig(this.camId);
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

.tab-pane {
	height: calc(100vh - 301px);
  max-height: 549px;
  min-height: 349px;
}
.container .nav-tabs {
    border-bottom: none!important;
}
#npContenido {
    padding-top: 30px;
}

#searching_msg {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
  width:100%;
}
</style>