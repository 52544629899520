<template>
  <tr @click="changeWifiPassword(id)">
    <th scope="row">
      <span
        class="material-icons"
        style="line-height: 1; vertical-align: middle"
        >{{ wifi_signal }}</span
      >
    </th>
    <td>{{ ssid }}</td>
    <td>{{ security }}</td>
    <td>
      <span v-show="connected">
        <i class="fa fa-check" aria-hidden="true"></i
      ></span>
    </td>
  </tr>
</template>
<script>
const Swal = require("sweetalert2");
export default {
  name: "WifiItem",
  emits: ["configEvent"],
  computed: {
    wifi_signal() {
      //return "network_wifi";
      if (this.wifi_level < 20) {
        return "network_wifi_1_bar";
      } else if (this.wifi_level > 20 && this.wifi_level < 40) {
        return "network_wifi_2_bar";
      } else if (this.wifi_level > 40 && this.wifi_level < 80) {
        return "network_wifi_3_bar";
      } else {
        return "network_wifi";
      }
    },
  },
  props: {
    index:{
      type: Number,
    },
    id: {
      type: Number,
      default: -1,
      required: true,
    },
    ssid: {
      type: String,
      default: "",
      required: true,
    },
    security: {
      type: String,
      default: "",
      required: false,
    },
    signal: {
      type: Number,
      default: 0,
      required: true,
    },
    connected: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      wifi_level: 0,
      wifi_id: "",
      wifi_ssid: "",
      wifi_security: "",
    };
  },
  methods: {
    changeWifiPassword() {
      console.log("changeWifiPassword");
      Swal.fire({
        title: this.$t('cameras.wifi_password'),
        position: "center",
        showCancelButton: true,
        html: `<input type="password" id="password1" class="text_input" placeholder="` + this.$t('cameras.password') + `">
        <input type="password" id="password2" class="text_input" placeholder="` + this.$t('cameras.repeat_password') + `"/>`,
        confirmButtonText: this.$t('common.save'),
        cancelButtonText: this.$t('common.cancel'),
        focusConfirm: true,
        preConfirm: () => {
          const password1 = Swal.getPopup().querySelector("#password1").value;
          const password2 = Swal.getPopup().querySelector("#password2").value;
          if (!password1) {
            Swal.showValidationMessage(this.$t('cameras.enter_password'));
          }
          if (password1 != password2) {
            Swal.showValidationMessage(this.$t('cameras.passwords_dont_match'));
          }
          return { password: password1 };
        },
      }).then((result) => {
        if (result.isConfirmed) {
          console.log("nueva contraseña:", result.value.password);
          if (result.value.password) {
            this.$emit(
              "configEvent",
              this.wifi_ssid,
              this.wifi_security,
              result.value.password
            );
          }
        }
      });
    },
  },
  mounted() {
    this.wifi_level = parseInt(this.signal);
    this.wifi_id = parseInt(this.id);
    this.wifi_ssid = this.ssid;
    this.wifi_security = this.security;
  },
};
</script>
<style scoped>

</style>