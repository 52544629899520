<template>
  <menu-main></menu-main>
  <div id="passwordChange">
    <header-main> </header-main>
    <div class="cuerpo_principal">
      <div id="confUser" class="container">
        <div class="row">
          <div class="col-12 offset-0 col-sm-10 offset-sm-1 col-md-8 offset-md-2 col-md-8 offset-md-2 col-lg-6 offset-lg-3 visor_box">
            <ul class="nav nav-tabs nav-fill" role="tablist">
              
            </ul>
            <button
              @click="closeWindow"
              type="button"
              class="close_props material-icons"
              style="float: right"
              aria-label="Close"
            >close</button>
            <div class="tab-content" id="npContenido" style="border: 0px;">
              <div
                class="tab-pane fade show active"
                id="informacion"
                role="tabpanel"
                aria-labelledby="informacion-tab"
              >
                <div class="conf_box">
                  <div class="nom_input">
                    <p>{{ $t('users.current_password') }}</p>
                    <div>
                      <input class="text_input"
                        :type="currentPasswordFieldType"
                        v-model="currentPassword"
                        :placeholder="$t('users.current_password')"
                      />
                    
                      <i class="material-icons"
                        style="position: absolute; top: 10px; right: 12px; cursor: pointer"
                        @click="switchCurrentPasswordVisibility()"
                      >{{ this.currentPasswordVisibility?"visibility":"visibility_off" }}</i>
                    </div>
                  </div>
                  <div class="nom_input">
                    <p>{{ $t('users.new_password') }}</p>
                    <div>
                      <input class="text_input"
                        :type="newPasswordFieldType"
                        v-model="newPassword"
                        :placeholder="$t('users.new_password')"
                      />
                      <i class="material-icons"
                        style="position: absolute; top: 10px; right: 12px; cursor: pointer"
                        @click="switchNewPasswordVisibility()"
                      >{{ this.newPasswordVisibility?"visibility":"visibility_off" }}</i>
                      
                    </div>
                  </div>
                </div>
								<div class="msg">
									<p>{{ errorMessage }}</p>
								</div>
              </div>
						
            </div>
            
            <div class="btn_guardar">
              <button @click.prevent="save">
                <a href="#">{{ $t('common.save') }}</a>
                <div
                  v-if="saving"
                  class="spinner-border spinner-border-sm"
                  role="status"
                >
                  <span class="sr-only">Saving...</span>
                </div>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <menu-footer active-state=""></menu-footer>
</template>

<script>
//const axios = require("axios").default;
//axios.defaults.withCredentials = false;

export default {
  name: "PasswordChange",
  computed: {
    currentPasswordFieldType() {
      return this.currentPasswordVisibility ? "text" : "password";
    },
    newPasswordFieldType() {
      return this.newPasswordVisibility ? "text" : "password";
    },
  },
  data() {
    return {
      error: false,
      saving: false,
      errorMessage: "",
      currentPassword: "",
      currentPasswordVisibility: false,
      newPassword: "",
      newPasswordVisibility: false,
    };
  },
  methods: {
    closeWindow() {
      console.log("closeWindow");
      //this.$router.push({ name: "CamerasMain" });
      console.log(this.$router);
      this.$router.go(-1);
    },
    switchCurrentPasswordVisibility() {
      console.log("switchCurrentPasswordVisibility");
      this.currentPasswordVisibility = !this.currentPasswordVisibility;
    },
    switchNewPasswordVisibility() {
      console.log("switchNewPasswordVisibility");
      this.newPasswordVisibility = !this.newPasswordVisibility;
    },
    verifyData() {
      const userPassword = this.$root.sessionManager.password;
      console.log("userpassword", userPassword);
      if (this.currentPassword !== userPassword) {
        this.errorMessage = this.$t('users.password_incorrect');
        this.error = true;
        return false;
      }
      const regex = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])[0-9a-zA-Z]{8,}$/;
      if (!regex.test(this.newPassword)) {
        this.errorMessage = this.$t('users.password_no_valid');
        this.error = true;
        return false;
      }
      return true;
    },
    save() {
      console.log("save", this.currentPassword, this.newPassword);
      if (this.verifyData()) {
        console.log("verify ok");
        this.updatePassword();
      }
    },
    logOut() {
      this.$root.sessionManager.logout();
      this.$router.push({ name: "SignIn" });
    },
    async updatePassword() {
      this.saving = true;
      console.log("updatePassword");
      //const userEmail = JSON.parse(sessionStorage.getItem("userLogin")).email;
      const userEmail = this.$root.sessionManager.userName;
      try {
        await this.$root.dataManager.modifyUserPassword(userEmail,this.currentPassword,this.newPassword);
        //this.logOut();
        this.saving = false;
        this.$router.go(-1);
        this.$root.sessionManager.password=this.newPassword;

      } catch (error) {
        console.log("updatePassword error");
        console.error(error);
        this.error = true;
        this.errorMessage = this.$t('users.error_updating_password');
      }
    },

  },
  mounted() {
    console.log("PasswordChange");
    //const userInfo = JSON.parse(sessionStorage.getItem("userInfo"));
    if (!this.$root.sessionManager.isLoggedIn()) {
      console.log("router to signin");
      this.$router.push({ name: "SignIn" });
    }
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.container .nav-tabs {
    border-bottom: none!important;
}
#npContenido {
	padding-top:65px;
}

.nom_input>div
{
  position:relative;  
}
.nom_input>div i {
    padding-left: 8px;
    font-size: 20px;
    line-height: 1;
    vertical-align: top;
    padding-top: 2px;
}

</style>