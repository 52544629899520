<template>
  <div class="p_box">
    <div class="p_tabla">
      <div class="sw_contenedor">
        <p>{{ name }}</p>
        <div v-if= "editTasksPriv" class="sw_cam">
          <input
            type="checkbox"
            name="switch_cam"
            :id="switchName"
            class="switch_input"
            v-model="swChecked"
            readonly
            
            
          />
          <!--@change="stateTaskChanged"
          :checked="!disabled"-->
          <label :for="switchName" class="switch_label"></label>
        </div>
        <div v-if= "!editTasksPriv" class="sw_cam">
          <input
            type="checkbox"
            name="switch_cam"
            :id="switchName"
            class="switch_input"
            v-model="swChecked"
            disabled
          />
          <label :for="switchName" class="switch_label"></label>
        </div>
      </div>
      <div class="task_item_info">
        <table class="table">
          <tbody>
            <tr v-for="timetable in timetables" :key="timetable.wday">
              <td>{{ dayOfWeek(timetable.wday) }}</td>
              <td class="text-end">
                {{ timetable.start }} - <span> {{ timetable.end }}</span>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    <div class="task_menu">
      <div class="txt_task">
        
      </div>
      <div class="m_dot">
        <!-- Default dropstart button -->
        <div class="btn-group dropstart">
          <button
            type="button"
            class="btn btn-no-border"
            data-bs-toggle="dropdown"
            aria-expanded="false"
						ref="dropdownbtn"
            v-show="editTasksPriv"
          >
            <span
              ><svg
                id="Modo_de_aislamiento"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 1.78 10.75"
              >
                <circle
                  class="cls-1"
                  cx=".89"
                  cy="9.86"
                  r=".89"
                  transform="translate(-2.23 .46) rotate(-13.2)"
                />
                <circle
                  class="cls-1"
                  cx=".89"
                  cy="5.37"
                  r=".89"
                  transform="translate(-1.2 .35) rotate(-13.2)"
                />
                <circle
                  class="cls-1"
                  cx=".89"
                  cy=".89"
                  r=".89"
                  transform="translate(-.18 .23) rotate(-13.2)"
                /></svg></span>
          </button>
          <ul  class="dropdown-menu list-dropdown">
            <li>
              <a @click="$emit('deleteEvent', id, index)"
                ><i class="fa fa-trash" aria-hidden="true"></i> {{ $t('common.delete') }}</a
              >
            </li>
            <li>
              <a @click="$emit('editEvent', id, index)"
                ><i class="fa fa-pencil" aria-hidden="true"></i> {{ $t('common.edit') }}</a
              >
            </li>
          </ul>
        </div>
      </div>
    </div>

    </div>
  </div>
</template>
<script>
//const axios = require("axios").default;
//axios.defaults.withCredentials = false;

export default {
  name: "TaskItem",
  props: {
    index: Number,
    id: Number,
    type: Number,
    name: {
      type: String,
      default: "",
    },
    initialDisabled: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["deleteEvent", "editEvent"],
  computed: {
    switchName() {
      return "swP-" + this.id;
    },
    timetables() {
      console.log("computed timetables");
      if (this.$root.dataManager.tasks[this.index].timetables!=undefined)
      {
        console.log("timetable available");
        return this.$root.dataManager.tasks[this.index].timetables;
      }
      console.log("timetable not available");
      return [];
    }
  },
 
  data() {
    return {
      //timetables: [],
      error: false,
      loaded: false,
      //disabled: false,
      editTasksPriv: false,
      swChecked: false,
    };
  },
  created() {
    console.log("TaskItem created");
    this.getTimetable(this.id);
    //this.disabled = this.initialDisabled;
    this.swChecked=!this.initialDisabled;
  },
  watch: {
    swChecked() {
      //this.disabled=!this.swChecked;
      console.log("New value " + this.swChecked);
      this.$root.dataManager.modifyTask(this.id,this.name,this.type,!this.swChecked)
        .then(() => {
          //this.$root.dataManager.loadLabels(true);
        })
        .catch(error => {
          console.log(error);
        });
    },
    timetables() {
      console.log("changed timetables");
      console.log(this.timetables);
      if (this.timetables.length==0) {
        this.getTimetable(this.id);
      }
      
    },
  },
  mounted() {
    console.log("mounted,state", this.swChecked);
    const userPriv = this.$root.sessionManager.privileges;
    console.log("UsersMain, userPriv:", userPriv);
    this.editTasksPriv = userPriv["editTasks"];
		this.$refs.dropdownbtn.addEventListener('show.bs.dropdown', function() {
			var oldMenu=document.querySelector('.dropdown-menu.show');
			if (oldMenu!=null) oldMenu.classList.remove("show");
		});

  },
  methods: {
    // stateTaskChanged() {
    //   if (this.editTasksPriv) {
    //     this.disabled = !this.disabled;
    //     console.log("stateTaskChanged, disabled:", this.disabled);
    //     this.updateTask();
    //   }
    // },
    dayOfWeek(wday) {
      var dict = {};
      dict[1] = this.$t('weekdays.monday');
      dict[2] = this.$t('weekdays.tuesday');
      dict[3] = this.$t('weekdays.wednesday');
      dict[4] = this.$t('weekdays.thursday');
      dict[5] = this.$t('weekdays.friday');
      dict[6] = this.$t('weekdays.saturday');
      dict[7] = this.$t('weekdays.sunday');
      return dict[wday];
    },
    async getTimetable(id) {
      console.log("getTimetable");
      if (this.$root.dataManager.tasks[this.index].timetables!=undefined)
        return;
      try {
        const response=await this.$root.dataManager.loadTaskTimetable(id);
        this.$root.dataManager.tasks[this.index].timetables=[...response.timetables];
      }
      catch (error) {
        console.log("getTimetable error");
        console.error(error); 
      }
    },

  },
};
</script>


<style scoped>
.dropstart .dropdown-menu.show {
  
  top: -90px !important;
  
}
.task_item_info {
  height:269px;
  overflow-y:hidden
}
</style>
