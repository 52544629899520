<template>
  <menu-main></menu-main>
  <div id="cameraEdit">
    <header-main> </header-main>
    <div class="cuerpo_principal">
      <div v-show="editCamerasPriv" id="confProg" class="container">
        <div class="row">
          <div
            class="
              col-12
              offset-0
              col-sm-10
              offset-sm-1
              col-md-8
              offset-md-2
              col-md-8
              offset-md-2
              col-lg-6
              offset-lg-3
              visor_box
            "
          >
            <div class="alert alert-custom" id="msg-alert">
              <p>{{ errorMessage }}</p>
            </div>
            <ul class="nav nav-tabs nav-fill" role="tablist">
              <li class="nav-item" role="presentation">
                <button
                  class="nav-link active"
                  id="informacion-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#informacion"
                  type="button"
                  role="tab"
                  aria-controls="informacion"
                  aria-selected="true"
                >
                {{ $t('cameras.information') }}
                </button>
              </li>
              <li class="nav-item" role="presentation">
                <button
                  class="nav-link"
                  id="motion_configuration-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#motion_configuration"
                  type="button"
                  role="tab"
                  aria-controls="motion_configuration"
                  aria-selected="false"
                >
                {{ $t('cameras.motion') }}
                </button>
              </li>
              <li v-if="channels > 1 && 0" class="nav-item" role="presentation">
                <button
                  class="nav-link"
                  id="video_configuration-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#video_configuration"
                  type="button"
                  role="tab"
                  aria-controls="video_configuration"
                  aria-selected="false"
                >
                {{ $t('cameras.video') }}
                </button>
              </li>
              <li v-if="audioSupported" class="nav-item" role="presentation">
                <button
                  class="nav-link"
                  id="audio_configuration-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#audio_configuration"
                  type="button"
                  role="tab"
                  aria-controls="audio_configuration"
                  aria-selected="false"
                >
                {{ $t('cameras.audio') }}
                </button>
              </li>
            </ul>
            <button
              @click="closeWindow"
              type="button"
              class="close_props material-icons"
              style="float: right"
              aria-label="Close"
            >close</button>
            <div class="tab-content" id="npContenido" style="border: 0px">
              <!--TAB1-->
              <div
                class="tab-pane fade show active"
                id="informacion"
                role="tabpanel"
                aria-labelledby="informacion-tab"
              >
                <div class="conf_box">
                  <div class="nom_input">
                    <p>{{ $t('common.name') }}</p>
                    <input class="text_input"
                      v-model="name"
                      id="camera_name"
                      type="text"
                      :placeholder="$t('cameras.camera_name')"
                    />
                  </div>
									<div v-if="channels > 1" class="p_tipo">
                    <p>{{ $t('cameras.channel') }}</p>
                    <div class="conf_tipo">
                      <select id="channelSelect" v-model="channelSelected" class="dropdown_select">
                        <option
                          v-for="chanel in channelsAvailable"
                          :key="chanel"
                          :value="chanel"
                        >
                          {{ chanel }}
                        </option>
                      </select>
                    </div>
                  </div>
                  <div class="nom_input">
                    <p>{{ $t('cameras.brand') }}</p>
                    <input class="text_input"
                      disabled
                      v-model="brand"
                      id="camera_brand"
                      type="text"
                      :placeholder="$t('cameras.camera_brand')"
                    />
                  </div>
                  <div class="nom_input">
                    <p>{{ $t('cameras.model') }}</p>
                    <input class="text_input"
                      disabled
                      v-model="model"
                      id="camera_model"
                      type="text"
                      :placeholder="$t('cameras.camera_model')"
                    />
                  </div>
                  <div class="nom_input">
                    <p>{{ $t('cameras.ip_dn') }}</p>
                    <input class="text_input"
                      disabled
                      v-model="ip"
                      id="camera_ip"
                      type="text"
                      :placeholder="$t('cameras.camera_ip_dn')"
                    />
                  </div>
                  <div class="nom_input">
                    <p>{{ $t('cameras.profile') }}</p>
                    <input class="text_input"
                      disabled
                      v-model="profile"
                      id="camera_profile"
                      type="text"
                      :placeholder="$t('cameras.camera_profile')"
                    />
                  </div>
                </div>
                <!--end conf_box-->
              </div>
              <!--end tab-->

              <!--TAB2-->
              <div
                class="tab-pane fade"
                id="motion_configuration"
                role="tabpanel"
                aria-labelledby="motion_configuration-tab"
              >
								<div>
                <div class="conf_box">
                  <div class="sw_prog">
                    <div class="sw_contenedor">
                      <p>{{ $t('cameras.detection') }}</p>
                      <div class="sw_cam">
                        <input
                          type="checkbox"
                          id="swMotionDetection"
                          v-model="motionDetection"
                          class="switch_input"
                        />
                        <label
                          for="swMotionDetection"
                          class="switch_label"
                        ></label>
                      </div>
                    </div>
                  </div>
                  <div v-if="motionDetection" class="p_tipo">
                    <p>{{ $t('cameras.sensitivity') }}</p>
                    <div class="conf_tipo">
                      <select class="dropdown_select"
                        id="motionSensitivity"
                        v-model="motionSensitivity"
                      >
                        <option value="0">{{ $t('cameras.low') }}</option>
                        <option value="1">{{ $t('cameras.medium') }}</option>
                        <option value="2">{{ $t('cameras.high') }}</option>
                      </select>
                    </div>
                  </div>
                  <div v-if="motionDetection" class="p_tipo">
                    <p>{{ $t('cameras.region') }}</p>
                    <div class="conf_tipo">
                      <select class="dropdown_select"
                        @change="onChangeRegion($event)"
                        id="motionRegion"
                        v-model="motionRegion"
                      >
                        <option value="full">{{ $t('cameras.full') }}</option>
                        <option value="custom">{{ $t('cameras.custom') }}</option>
                      </select>
											<button class="btn btn-no-border" style="position: absolute; padding: 0; right: 20px; top: 50%; height: 24px;transform: translate(0,-50%);" v-show="motionRegion == 'custom'"
                        @click="editRegion">
												<i  class="material-icons" style="color: var(--main-text-color);">edit</i>
											</button>
                      
                    </div>
                  </div>
                  
                </div><!--end conf_box-->
								<div
                    
                    style="background-color: transparent; padding:10px;"
                    id="singleplayer"
                  ></div>
								</div>
                
              </div>
              <!--TAB3-->
              <div
                v-if="channels > 1 && 0"
                class="tab-pane fade"
                id="video_configuration"
                role="tabpanel"
                aria-labelledby="video_configuration-tab"
              >
                <div class="conf_box">
                  <div v-if="channels > 1" class="p_tipo">
                    <p>{{ $t('cameras.channel') }}</p>
                    <div class="conf_tipo">
                      <select id="channelSelect" v-model="channelSelected" class="dropdown_select">
                        <option
                          v-for="chanel in channelsAvailable"
                          :key="chanel"
                          :value="chanel"
                        >
                          {{ chanel }}
                        </option>
                      </select>
                    </div>
                  </div>
                </div>
                <!--end conf_box-->
              </div>

              <!--TAB4-->
              <div
                v-if="audioSupported"
                class="tab-pane fade"
                id="audio_configuration"
                role="tabpanel"
                aria-labelledby="audio_configuration-tab"
              >
                <div class="conf_box">
                  <div class="sw_prog">
                    <div v-if="audioSupported" class="sw_contenedor">
                      <p>{{ $t('cameras.enable') }}</p>
                      <div class="sw_cam">
                        <input
                          type="checkbox"
                          id="swAudio"
                          v-model="audioEnabled"
                          class="switch_input"
                        />
                        <label for="swAudio" class="switch_label"></label>
                      </div>
                    </div>
                  </div>
                  <div class="sw_prog">
                    <div v-if="audioEnabled" class="sw_contenedor">
                      <p>{{ $t('cameras.detection') }}</p>
                      <div class="sw_cam">
                        <input
                          type="checkbox"
                          id="swAudioDetection"
                          v-model="audioDetection"
                          class="switch_input"
                        />
                        <label
                          for="swAudioDetection"
                          class="switch_label"
                        ></label>
                      </div>
                    </div>
                  </div>
                  <div v-if="audioDetection && audioEnabled" class="p_tipo">
                    <p>{{ $t('cameras.sensitivity') }}</p>
                    <div class="conf_tipo">
                      <select id="audioSensitivity" v-model="audioSensitivity" class="dropdown_select">
                        <option value="0">{{ $t('cameras.low') }}</option>
                        <option value="1">{{ $t('cameras.medium') }}</option>
                        <option value="2">{{ $t('cameras.high') }}</option>
                      </select>
                    </div>
                  </div>
                </div>
                <!--end conf_box-->
              </div>
            </div>
            <!--end npContenido-->

            <div 
              :class="!loaded ? 'disabled_btn_guardar' : 'btn_guardar'">
              <button :disabled="!loaded" @click.prevent="save">
                <a href="#">{{ $t('common.save') }}</a>
                <div
                  v-if="saving"
                  class="spinner-border spinner-border-sm"
                  role="status"
                >
                  <span class="sr-only">Saving...</span>
                </div>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <menu-footer active-state="cameras"></menu-footer>
</template>

<script>
import $ from "jquery";

//const axios = require("axios").default;
//axios.defaults.withCredentials = false;

var Player = null;
var playerTop = 0;
var playerLeft = 0;
var playerWidth = 1000;
var playerHeight = 1000;

export default {
  name: "CameraEdit",
  props: {
    editId: String,
  },
  data() {
    return {
      sessionId: "",
      id: -1,
      name: "",
      ip: "",
      brand: "",
      model: "",
      profile: "",
      channels: 1,
      channelSelected: 1,
      channelsAvailable: [],
      audioSupported: false,
      audioEnabled: false,
      audioDetection: false,
      audioSensitivity: 1,
      motionDetection: false,
      motionSensitivity: 0,
      motionRegion: "full",
      region_top: 0,
      region_left: 0,
      region_width: 0,
      region_height: 0,
      errorMessage: "",
      saving: false,
      loaded: false,
      //error: false,
      editCamerasPriv: false,
      showedCamera: false,
    };
  },
  methods: {
    editRegion() {
      $("#singleplayer").show();
      if (!this.showedCamera) {
        Player.showCamera(this.id);
        this.showedCamera = true;
      }
    },
    onChangeRegion(event) {
      console.log("onChangeRegion", event.target.value);
      if (event.target.value == "full") {
        $("#singleplayer").hide();
      }
    },
    showMsgAlert() {
      $("#msg-alert")
        .fadeTo(200, .80, function() {
					setTimeout(function() {
						$("#msg-alert").fadeTo(200,0);
						},2000)
				});
    },
    closeWindow() {
      console.log("closeWindow");
      //this.$router.push({ name: "CamerasMain" });
      this.$router.go(-1);
    },
    verifyData() {
      console.log("verify data");
      if (this.name == "") {
        this.errorMessage = this.$t('common.name_required');
        //this.error = true;
        document.getElementById("camera_name").focus();
        return false;
      }
      return true;
    },
    save() {
      console.log("save data");
      if (this.verifyData()) {
        this.updateCamera(this.id);
      } else {
        this.showMsgAlert();
      }
    },

    async updateCamera(camId) {
      this.saving = true;
      const audio = {
        enabled: this.audioEnabled,
        detection: this.audioDetection && this.audioEnabled,
        sensitivity: this.audioSensitivity,
      };
      const motion = {
        detection: this.motionDetection,
        sensitivity: this.motionSensitivity,
        region: this.motionRegion,
      };
      var camNum=-1;
      
      if (this.motionRegion == "custom") {
        var regionPlayer = Player.getRegion();
        motion["left"] = regionPlayer.left;
        motion["top"] = regionPlayer.top;
        motion["width"] = regionPlayer.width;
        motion["height"] = regionPlayer.height;
      } else {
        motion["left"] = 0;
        motion["top"] = 0;
        motion["width"] = 1000;
        motion["height"] = 1000;
      }
      if (this.channels > 1) {
        camNum = this.channelSelected;
      }

      
      console.log("updating camera");
      try {
        await this.$root.dataManager.modifyCamera(camId,this.name,camNum,audio,motion);
        console.log("update camera OK", camId);
        //const data = { this.selectedCameras.toString();
        this.saving = false;
        this.$router.push({ name: "CamerasMain" });
      }
      catch (error) {
        console.log("updateCamera error");
        console.error(error);
        //this.error = true;
        this.saving = false;
        this.errorMessage = this.$t('cameras.error_updating_camera');
        this.showMsgAlert();
      }

    },
    async getCamera(camId) {
      this.loaded=false;
      console.log("getCamera:", camId);
      //const userInfo = JSON.parse(sessionStorage.getItem("userInfo"));
      //console.log("getCamera:", userInfo.sessionId);
      //const url = userInfo.APIUrl + "/cameras/" + id + "/?q=" + userInfo.sessionId;
      try {
        //const response = await axios.get(url);
        const response=await this.$root.dataManager.loadCamera(camId);
        //console.log(response.status);
        //console.log(response.data);
        console.log("getCamera OK", response.camera);
        this.name = response.camera.name;
        this.ip = response.camera.ip;
        this.brand = response.camera.brand;
        this.model = response.camera.model;
        this.profile = response.camera.profile;
        this.channels = response.camera.channels;
        for (var i = 1; i <= this.channels; i++) {
          this.channelsAvailable.push(i);
        }
        this.channelSelected = response.camera.number;
        this.audioSupported = response.camera.audio.supported;
        this.audioEnabled = response.camera.audio.enabled;
        this.audioDetection = response.camera.audio.detection;
        this.audioSensitivity = response.camera.audio.sensitivity;
        this.motionDetection = response.camera.motion.detection;
        this.motionSensitivity = response.camera.motion.sensitivity;
        this.motionRegion = response.camera.motion.region;
        playerTop = response.camera.motion.top;
        playerLeft = response.camera.motion.left;
        playerWidth = response.camera.motion.width;
        playerHeight = response.camera.motion.height;
        this.loaded=true;

      } catch (error) {
        console.log("getCamera error");
        console.error(error);
        //this.error = true;
      }
    },
  },
  created() {
    console.log("CameraEdit with id:", this.editId);
    this.id = parseInt(this.editId);
    //const userInfo = JSON.parse(sessionStorage.getItem("userInfo"));
    if (!this.$root.sessionManager.isLoggedIn()) {
      console.log("router to signin");
      this.$router.push({ name: "SignIn" });
    }
    console.log("CameraEdit sessionid:", this.$root.sessionManager.sessionId);
    this.sessionId = this.$root.sessionManager.sessionId;
    const ipnplayerScript = document.createElement("script");
    ipnplayerScript.setAttribute(
      "src",
      "//" + window.location.host + "/js/ipnplayer/ipnplayer.js"
    );
    var self=this;
    ipnplayerScript.onload = function () {
      console.log("Script loaded and ready");
      if (window.IPNPlayer) {
        console.log("IPNPlayer:", window.IPNPlayer);
        Player = new window.IPNPlayer(
          document.getElementById("singleplayer"),
          0
        );
        console.log("Player:", Player);
        Player.dispatcher = self.$root.sessionManager.dispatcher;
        Player.sessionID = self.$root.sessionManager.sessionId;
        Player.mdConfigMode = true;
        Player.enablePTZ = false;
        Player.mdConfigColor = getComputedStyle(document.documentElement).getPropertyValue('--main-accent-color');
        console.log("Player dispatcher:", Player.dispatcher);
        console.log("Player sessionID:", Player.sessionID);
        Player.onInfo = function (infotype, infostr) {
          console.log(infotype);
          console.log(infostr);
          const dimens = infostr.split("x");
          const ratio = dimens[0] / dimens[1];
          console.log("ratio", ratio);
					document.getElementById("singleplayer").style.aspectRatio=dimens[0] + " / " + dimens[1];
          document.getElementById("singleplayer").style.width = "100%"; //"320px";
					console.log(document.getElementById("singleplayer").style);
          //document.getElementById("singleplayer").style.height = "240px";

          /*if (ratio > 1.4) {
            const new_width = ratio * 240.0;
            document.getElementById("singleplayer").style.height = "240px";
            document.getElementById("singleplayer").style.width =
              new_width + "px";
          }*/
          console.log(
            "setRegion:",
            playerLeft,
            playerTop,
            playerWidth,
            playerHeight
          );
          Player.setRegion(playerLeft, playerTop, playerWidth, playerHeight);
        };
      }
    };
    document.head.appendChild(ipnplayerScript);
  },

  mounted() {
    document.getElementById("camera_name").focus();
    document.getElementById("camera_name").blur();
    $("#singleplayer").hide();
    document.title = this.$t('cameras.edit_camera');
    $("#msg-alert").hide();
    const userPriv = this.$root.sessionManager.privileges;
    console.log("UsersMain, userPriv:", userPriv);
    this.editCamerasPriv = userPriv["editCameras"];
    this.getCamera(this.id);
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>