<template>
  <div id="release_content" style="margin: 10px">
    <h1>Notas de la versión</h1>
    <br />
     <h2>Versión 1.0.2 - Fecha 16 Octubre 2023</h2>
    <ul>
      <li>Nueva característica: Autenticación de doble factor por email para clientes y usuarios dependientes</li>
    </ul>
    <h2>Versión 1.0.1 - Fecha 14 Septiembre 2023</h2>
    <ul>
      <li>Nueva característica: Visualizar la versión de código en el menú lateral</li>
    </ul>
    <h2>Versión 1.0.0 - Fecha: 4 Julio 2023</h2>
    <ul>
      <li>Nueva característica: Nueva opción en el menú de configuración de ordenar la lista de cámaras por email o por ID</li>
      <li>
        Mejora: Lista de clientes para operadores multisite.Cuando un operador
        multisite se conecta a la plataforma ve ordenada la lista de sus
        clientes alfabéticamente de menor a mayor por su email
      </li>
      <li>
        Mejora: Las contraseñas deben de tener al menos 8 caracteres
        alfanuméricos con al menos un número, una letra mayúscula y una letra
        minúscula.
      </li>
      <li>
        Corrección de errores: Corregido error en exportaciones de grabaciones
        en curso debido a la fecha fin de las grabaciones.
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: "ReleaseView",
  mounted() {
    console.log("ReleaseView");
    document.title = "Notas de la versión";
    console.log("sessionId:" + this.$root.sessionManager.sessionId);
    if (!this.$root.sessionManager.isLoggedIn()) {
      console.log("router to signin");
      this.$router.push({ name: "SignIn" });
    }
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
