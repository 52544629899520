const axios = require("axios").default;
axios.defaults.withCredentials = false;
const Buffer = require('buffer/').Buffer;
import mqtt from "mqtt/dist/mqtt";
import * as firebase from "./Firebase.js"
import { notify } from "@kyvg/vue3-notification";
import i18n from '../../../locales/i18n-config'
import { loadConfig } from '../../../configLoader.js';

export default class DataManager {
  constructor() {
    this._sessionId = "" + sessionStorage.getItem("dataManager.sessionId");
    this._APIUrl = "" + sessionStorage.getItem("dataManager.APIUrl");
    this._APIServer = "" + sessionStorage.getItem("dataManager.APIServer");
    this._userName = "" + sessionStorage.getItem("dataManager.userName");

    /*this._selectedLabelId=-1;
    
    this._labelItemList=null;
    this._camItemList=null;
    this._labelCamItemList=null;
    this._groupItemList=null;
    this._labelGroupItemList=null;
    this._recItemList=null;
    this._labelRecItemList=null;
    this.recsFiltered=false;*/
    this._language = null;
    this._notifications = null;
    //console.log(firebase);
    firebase.initialize();
    this._firestore = null;
    this._userDocumentRef = null;
    this._notificationsCollectionRef = null;
    this.clear();
    this._notificationsSettings = {
      motionDetection: true,
      audioDetection: true,
      connectivityEvents: true,
      labelConfiguration: true,
      externalEvents: true,
    }
    this._cameraListOrderBySettings = "name";
    let cameraListOrderBySettings = localStorage.getItem("cameraListOrderBySettings");
    if (cameraListOrderBySettings != null) {
      this._cameraListOrderBySettings = cameraListOrderBySettings;
    }
    let notifs = localStorage.getItem("Notifications_" + this._APIServer + "_" + this._userName);
    if (notifs != null)
      this._notificationsSettings = JSON.parse(notifs);

   
    console.log("DataManager created " + this._sessionId);

  }


  static S_SESSIONERROR = -2;

  static apiVersion = "restapi2";

  get cameras() {
    if (this._camItemList == null)
      return [];
    return this._camItemList;
  }

  get camerasWithLabel() {
    if (this._selectedLabelId == -1)
      return this.cameras;
    if (this._labelCamItemList == null)
      return [];
    return this._labelCamItemList;
  }

  get camerasSummary() {
    if (this._camsSummary == null)
      return [];
    return this._camsSummary;
  }

  get recordings() {
    if (this._recItemList == null)
      return [];
    return this._recItemList;
  }

  get recordingsWithLabel() {
    if (this._selectedLabelId == -1)
      return this.recordings;
    if (this._labelRecItemList == null)
      return [];
    return this._labelRecItemList;
  }

  get groups() {
    if (this._groupItemList == null)
      return [];
    return this._groupItemList;
  }

  get groupsWithLabel() {
    if (this._selectedLabelId == -1)
      return this.groups;
    if (this._labelGroupItemList == null)
      return [];
    return this._labelGroupItemList;
  }

  get labels() {
    if (this._labelItemList == null)
      return [];
    return this._labelItemList;
  }

  get tasks() {
    if (this._taskItemList == null)
      return [];
    return this._taskItemList;
  }

  get camerasLoaded() {
    return (this._camItemList != null)
  }

  get camerasWithLabelLoaded() {
    return (this._labelCamItemList != null)
  }

  get recsLoaded() {
    return (this._recItemList != null)
  }

  get recsWithLabelLoaded() {
    return (this._labelRecItemList != null)
  }

  get groupsLoaded() {
    return (this._groupItemList != null)
  }

  get groupsWithLabelLoaded() {
    return (this._labelGroupItemList != null)
  }


  get tasksLoaded() {
    return (this._taskItemList != null)
  }

  get labelsLoaded() {
    return (this._labelItemList != null)
  }

  get notificationsCollectionRef() {
    if (this._notificationsCollectionRef == null)
      return null;
    return this._notificationsCollectionRef;
  }

  get userDocumentRef() {
    if (this._userDocumentRef == null)
      return null;
    return this._userDocumentRef;
  }

  get firestore() {
    if (this._firestore == null)
      return null;
    return this._firestore;
  }

  get language() {
    if (this._language == null)
      return "en";
    return this._language;
  }

  get notificationSettings() {
    return this._notificationsSettings;
  }
  get cameraListOrderBySettings() {
  
    return this._cameraListOrderBySettings;
  }


  /**
    * @param {Number} labelId
    */
  set selectedLabelId(labelId) {
    //console.log("selected label");
    if (this._selectedLabelId == labelId) return;
    this._selectedLabelId = labelId;
    if (labelId == -1) return;
    this._labelCamItemList = null;
    this._labelGroupItemList = null;
    this._labelRecItemList = null;
    this.loadCamerasWithLabel(true);
    this.loadGroupsWithLabel(true);
    this.loadRecordingsWithLabel(true);
  }

  get selectedLabelId() {
    return this._selectedLabelId;
  }

  /**
   * @param {String} sessionId
   */
  set sessionId(sessionId) {
    this._sessionId = sessionId;
    sessionStorage.setItem("dataManager.sessionId", this._sessionId);
  }

  /**
   * @param {String} APIServer
   */
  set APIServer(APIServer) {
    this._APIUrl = APIServer + "/" + DataManager.apiVersion;
    this._APIServer = APIServer;
    sessionStorage.setItem("dataManager.APIUrl", this._APIUrl);
    sessionStorage.setItem("dataManager.APIServer", this._APIServer);
  }

  /**
    * @param {String} userName
    */
  set userName(userName) {
    this._userName = userName;
    sessionStorage.setItem("dataManager.userName", this._userName);
  }


  clear() {
    this._selectedLabelId = -1;

    this._labelItemList = null;
    this._camItemList = null;
    this._camsSummary = null;
    this.camPreviews = [];
    this.groupPreviews = [];
    this._labelCamItemList = null;
    this._groupItemList = null;
    this._labelGroupItemList = null;
    this._recItemList = null;
    this._labelRecItemList = null;
    this._taskItemList = null;
    this.recsFiltered = false;
    this.licenses = [];
    this.totalLicensesLeft = 0;
    this._storage = null;
    if (this._firestore != null) {
      console.log("Close firestore?");
    }
    this._firestore = null;
    if (this.clientMQTT != undefined && this.clientMQTT.connected) {
      console.log("Close clientMQTT");
      this.clientMQTT.end();

    }
  }




  //#region ----------------------- CAMERAS -------------------------

  async loadCameras(forced = false) {
    return this._loadCameras(-1, forced);
  }

  async loadCamerasWithLabel(forced = false) {
    return this._loadCameras(this._selectedLabelId, forced);
  }

  async _loadCameras(labelId, forced = false) {

    //console.log ("DataManager loadCameras");
    if (!forced) {
      if (labelId == -1 && this._camItemList != null) {
        //console.log ("DataManager loadCameras cached");
        if (this._cameraListOrderBySettings == "name"){
          this._camItemList.sort((a, b) => a.name.localeCompare(b.name));
        }
        if (this._cameraListOrderBySettings == "id"){
          this._camItemList.sort((a, b) => a.id - b.id);
        }
        return ({
          result: 0,
          cameras: this._camItemList,
          summary: this._camsSummary
        });
      }
      if (labelId != -1 && this._labelCamItemList != null) {
        if (this._cameraListOrderBySettings == "name"){
          this._labelCamItemList.sort((a, b) => a.name.localeCompare(b.name));
        }
        if (this._cameraListOrderBySettings == "id"){
          this._labelCamItemList.sort((a, b) => a.id - b.id);
        }
        return ({
          result: 0,
          cameras: this._labelCamItemList,
          summary: this._camsSummary
        });
      }
    }

    var url = this._APIUrl + "/cameras/?q=" + this._sessionId;
    if (labelId != -1) url += "&insid=" + labelId;
    try {
      var response = await this.sendAPIRequest(url);
      //console.log(response);
      if (labelId != -1) {
        this._labelCamItemList = [...response.cameras];
        if (this._cameraListOrderBySettings == "name"){
          this._labelCamItemList.sort((a, b) => a.name.localeCompare(b.name));
        }
        if (this._cameraListOrderBySettings == "id"){
          this._labelCamItemList.sort((a, b) => a.id - b.id);
        }

      } else {
        this._camItemList = [...response.cameras];
        if (this._cameraListOrderBySettings == "name"){
          this._camItemList.sort((a, b) => a.name.localeCompare(b.name));
        }
        if (this._cameraListOrderBySettings == "id"){
          this._camItemList.sort((a, b) => a.id - b.id);
        }
      }
      //this._camsSummary = [...response.summary];
      var config = await loadConfig(); 
      this.setLicenseProfiles(response.summary,config);
      return (response);
    }
    catch (result) {
      console.log(result);
      throw (result);
    }
  }

  async loadUnassignedCameras(taskId = -1) {
    var url = this._APIUrl + "/cameras/?q=" + this._sessionId + "&unassigned=" + taskId;
    try {
      var response = await this.sendAPIRequest(url);
      return (response);
    }
    catch (result) {
      console.log(result);
      throw (result);
    }
  }



  async loadCamera(camId) {
    //console.log("load camera");
    const url = this._APIUrl + "/cameras/" + camId + "/?q=" + this._sessionId;
    try {
      var response = await this.sendAPIRequest(url, "GET");
      return (response);
    }
    catch (error) {
      console.log(error);
      throw (error);
    }
  }

  async deleteCamera(camId) {
    //console.log("delete camera");
    const url = this._APIUrl + "/cameras/" + camId + "/?q=" + this._sessionId;
    try {
      var response = await this.sendAPIRequest(url, "DELETE");
      for (const [i, cam] of this.cameras.entries()) {
        if (cam.id == camId) {
          this.cameras.splice(i, 1);
          break;
        }
      }
      if (this._selectedLabelId != -1) {
        for (const [i, cam] of this.camerasWithLabel.entries()) {
          if (cam.id == camId) {
            this.camerasWithLabel.splice(i, 1);
            break;
          }
        }
      }

      // if (this._selectedLabelId!=-1)
      //   await this.loadCamerasWithLabel(true);
      // await this.loadCameras(true);
      return (response);
    }
    catch (error) {
      console.log(error);
      throw (error);
    }
  }

  async addCamera(name, mac, vcode, username, password, profile) {
    const data = {
      camname: name,
      mac: mac,
      vcode: vcode,
      username: username,
      password: password,
      profile: profile,
    }

    const url = this._APIUrl + "/cameras/?q=" + this._sessionId;
    try {
      var response = await this.sendAPIRequest(url, "POST", data);
      await this.loadCameras(true);
      return (response);
    }
    catch (error) {
      console.log(error);
      throw (error);
    }
  }

  async modifyCamera(camId, name, camNum = -1, audio = undefined, motion = undefined) {
    const data = {
      camname: name,
    };
    if (camNum != -1) {
      data.camnum = camNum;
    }
    //console.log(audio,motion);
    if (audio != undefined)
      data.audio = audio;
    if (motion != undefined)
      data.motion = motion;
    const url = this._APIUrl + "/cameras/" + camId + "/?q=" + this._sessionId;
    try {
      var response = await this.sendAPIRequest(url, "PUT", data);
      for (let cam of this.cameras) {
        if (cam.id == camId) {
          cam.name = name;
          if (camNum != -1)
            cam.number = camNum;
          break;
        }
      }
      if (this._selectedLabelId != -1) {
        for (let cam of this.camerasWithLabel) {
          if (cam.id == camId) {
            cam.name = name;
            if (camNum != -1)
              cam.number = camNum;
            break;
          }
        }
      }
      // if (this._selectedLabelId!=-1)
      //   await this.loadCamerasWithLabel(true);
      // await this.loadCameras(true);
      return (response);
    }
    catch (error) {
      console.log(error);
      throw (error);
    }
  }

  async updateCameraPreview(camId) {
    const url = this._APIUrl + "/cameras/" + camId + "/preview/?q=" + this._sessionId;
    try {
      var response = await this.sendAPIRequest(url, "PUT");
      return (response);
    }
    catch (error) {
      console.log(error);
      throw (error);
    }
  }

  async getCameraPreviewBase64(camId, forced = false) {
    //console.log(this.camPreviews["cam" + camId]);
    //console.log("Getting preview " + camId);
    if (!forced) {
      if (this.camPreviews["cam" + camId] != undefined) {
        //console.log("return cached " + this.camPreviews["cam" + camId]);
        return this.camPreviews["cam" + camId];
      }
    }
    if (this.camPreviews["cam" + camId] == "downloading") {
      //console.log("Already downloading");
      return this.camPreviews["cam" + camId];
    }
    this.camPreviews["cam" + camId] = "downloading";
    const url = this._APIUrl + "/cameras/" + camId + "/preview/?q=" + this._sessionId;
    try {
      var response = await axios.get(url, {
        responseType: 'arraybuffer'
      });

      if (response.status == 200) {
        var image = Buffer.from(response.data, 'binary').toString('base64');
        //console.log(image);
        this.camPreviews["cam" + camId] = "data:image/jpeg;base64," + image;
        //console.log("Got preview " + camId);
        return (image);
      } else {
        throw (response.data);
      }

    }
    catch (error) {
      this.camPreviews["cam" + camId] = "unavailable";
      throw ({
        result: -1,
        error: error
      });
    }
  }

  deleteCameraPreview(camId) {
    this.camPreviews["cam" + camId] = undefined;
  }

  deleteAllCameraPreviews() {
    this.camPreviews = [];
  }

  async getWiFiNetworks(camId) {
    const url = this._APIUrl + "/cameras/" + camId + "/wifilist/?q=" + this._sessionId;
    try {
      var response = await this.sendAPIRequest(url, "GET");
      return (response);
    }
    catch (error) {
      console.log(error);
      throw (error);
    }
  }

  async getWiFiConfig(camId) {
    const url = this._APIUrl + "/cameras/" + camId + "/wificonf/?q=" + this._sessionId;
    try {
      var response = await this.sendAPIRequest(url, "GET");
      return (response);
    }
    catch (error) {
      console.log(error);
      throw (error);
    }
  }

  async setWiFiConfig(camId, ssid, password, security) {
    const data = {
      key: password,
      ssid: ssid,
      security: security,
    };
    const url = this._APIUrl + "/cameras/" + camId + "/wificonf/?q=" + this._sessionId;
    try {
      var response = await this.sendAPIRequest(url, "PUT", data);
      return (response);
    }
    catch (error) {
      console.log(error);
      throw (error);
    }
  }

  //#endregion

  //#region ----------------------- GROUPS -------------------------

  async loadGroups(forced = false) {
    return this._loadGroups(-1, forced);
  }

  async loadGroupsWithLabel(forced = false) {
    return this._loadGroups(this._selectedLabelId, forced);
  }

  async _loadGroups(labelId, forced = false) {
    //console.log ("DataManager loadGroups");
    if (!forced) {
      if (labelId == -1 && this._groupItemList != null) {
        //console.log ("DataManager loadGroups cached");
        return ({
          result: 0,
          cameras: this._groupItemList
        });
      }
      if (labelId != -1 && this._labelGroupItemList != null) {
        //console.log ("DataManager loadGroups cached");
        return ({
          result: 0,
          cameras: this._labeGroupItemList
        });
      }
    }

    var url = this._APIUrl + "/groups/?q=" + this._sessionId;
    if (labelId != -1) url += "&insid=" + labelId;
    try {
      var response = await this.sendAPIRequest(url)
      //console.log(response);
      if (labelId != -1)
        this._labelGroupItemList = [...response.groups];
      else
        this._groupItemList = [...response.groups];
      return (response);
    }
    catch (error) {
      console.log(error);
      throw (error);
    }
  }


  async loadGroup(groupId) {
    //console.log("load group");
    const url = this._APIUrl + "/groups/" + groupId + "/?q=" + this._sessionId;
    try {
      var response = await this.sendAPIRequest(url, "GET");
      return (response);
    }
    catch (error) {
      console.log(error);
      throw (error);
    }
  }

  async deleteGroup(groupId) {
    //console.log("delete group");
    const url = this._APIUrl + "/groups/" + groupId + "/?q=" + this._sessionId;
    try {
      var response = await this.sendAPIRequest(url, "DELETE");
      for (const [i, group] of this.groups.entries()) {
        if (group.id == groupId) {
          this.groups.splice(i, 1);
          break;
        }
      }
      if (this._selectedLabelId != -1) {
        for (const [i, group] of this.groupsWithLabel.entries()) {
          if (group.id == groupId) {
            this.groupsWithLabel.splice(i, 1);
            break;
          }
        }
      }

      // if (this._selectedLabelId!=-1)
      //   await this.loadGroupsWithLabel(true);
      // await this.loadGroups(true);
      return (response);
    }
    catch (error) {
      console.log(error);
      throw (error);
    }
  }


  async addGroup(name, geometry, interval) {
    const data = {
      name: name,
      geometry: geometry,
      interval: interval,
    };
    const url = this._APIUrl + "/groups/?q=" + this._sessionId;
    try {
      var response = await this.sendAPIRequest(url, "POST", data);
      await this.loadGroups(true);
      return (response);
    }
    catch (error) {
      console.log(error);
      throw (error);
    }
  }

  async modifyGroup(groupId, name, geometry, interval) {
    const data = {
      name: name,
      geometry: geometry,
      interval: interval,
    };
    const url = this._APIUrl + "/groups/" + groupId + "/?q=" + this._sessionId;
    try {
      var response = await this.sendAPIRequest(url, "PUT", data);
      for (let group of this.groups) {
        if (group.id == groupId) {
          group.name = name;
          group.geometry = geometry;
          group.interval = interval;

          break;
        }
      }
      if (this._selectedLabelId != -1) {
        for (let group of this.groupsWithLabel) {
          if (group.id == groupId) {
            group.name = name;
            group.geometry = geometry;
            group.interval = interval;
            break;
          }
        }
      }
      // if (this._selectedLabelId!=-1)
      //   await this.loadGroupsWithLabel(true);
      // await this.loadGroups(true);
      return (response);
    }
    catch (error) {
      console.log(error);
      throw (error);
    }
  }


  async loadGroupCameras(groupId) {
    const url = this._APIUrl + "/groups/" + groupId + "/cameras/?q=" + this._sessionId;
    //console.log(url);
    try {
      var response = await this.sendAPIRequest(url, "GET");
      console.log(response);
      return (response);
    }
    catch (error) {
      console.log(error);
      throw (error);
    }
  }

  async addGroupCameras(groupId, camId) {
    const data = { camid: camId };
    const url = this._APIUrl + "/groups/" + groupId + "/cameras/?q=" + this._sessionId;
    try {
      var response = await this.sendAPIRequest(url, "POST", data);
      //await this.loadGroups(true);
      return (response);
    }
    catch (error) {
      console.log(error);
      throw (error);
    }
  }

  async deleteGroupCameras(groupId) {
    const url = this._APIUrl + "/groups/" + groupId + "/cameras/?q=" + this._sessionId;
    try {
      var response = await this.sendAPIRequest(url, "DELETE");
      return (response);
    }
    catch (error) {
      console.log(error);
      throw (error);
    }
  }

  async updateGroupPreview(groupId) {
    const url = this._APIUrl + "/groups/" + groupId + "/preview/?q=" + this._sessionId;
    try {
      var response = await this.sendAPIRequest(url, "PUT");
      return (response);
    }
    catch (error) {
      console.log(error);
      throw (error);
    }
  }

  async getGroupPreviewBase64(groupId, forced = false) {
    if (!forced)
      if (this.groupPreviews["group" + groupId] != undefined) return this.groupPreviews["group" + groupId];
    if (this.groupPreviews["group" + groupId] == "downloading") {
      //console.log("Already downloading");
      return this.groupPreviews["group" + groupId];
    }
    this.groupPreviews["group" + groupId] = "downloading";
    const url = this._APIUrl + "/groups/" + groupId + "/preview/?q=" + this._sessionId;
    try {
      var response = await axios.get(url, {
        responseType: 'arraybuffer'
      });

      if (response.status == 200) {
        var image = Buffer.from(response.data, 'binary').toString('base64');
        //console.log(image);
        this.groupPreviews["group" + groupId] = "data:image/jpeg;base64," + image;
        return (image);
      } else {
        throw (response.data);
      }

    }
    catch (error) {
      this.groupPreviews["group" + groupId] = "unavailable";
      throw ({
        result: -1,
        error: error
      });
    }
  }

  deleteGroupPreview(groupId) {
    this.groupPreviews["group" + groupId] = undefined;
  }

  deleteAllGroupPreviews() {
    this.groupPreviews = [];
  }


  //#endregion

  //#region ----------------------- RECORDINGS -------------------------


  async loadRecordings(forced = false) {
    return this._loadRecordings(-1, forced);
  }

  async loadRecordingsWithLabel(forced = false) {
    return this._loadRecordings(this._selectedLabelId, forced);
  }

  async _loadRecordings(labelId, param) {
    var forced = false;
    var recTime = "";
    if (typeof (param) === "string") {
      recTime = param;
      forced = true;
      this.recsFiltered = true;
    }
    else {
      if (this.recsFiltered)
        forced = true;
      else if (typeof (param) === "boolean") {
        forced = param;
      }
      this.recsFiltered = false;
    }

    //console.log ("DataManager loadRecordings");
    if (!forced) {
      if (labelId == -1 && this._recItemList != null) {
        //console.log ("DataManager loadRecordings cached");
        return ({
          result: 0,
          recordings: this._recItemList
        });
      }
      if (labelId != -1 && this._recCamItemList != null) {
        return ({
          result: 0,
          recordings: this._labelRecItemList
        });
      }
    }

    var url = this._APIUrl + "/recordings/?q=" + this._sessionId;
    if (labelId != -1) url += "&insid=" + labelId;
    if (recTime.length == 12) url += "&rectime=" + recTime;
    try {
      var response = await this.sendAPIRequest(url);
      //console.log(response);
      if (labelId != -1)
        this._labelRecItemList = [...response.recs];
      else
        this._recItemList = [...response.recs];
      return (response);
    }
    catch (error) {
      console.log(error);
      throw (error);
    }
  }

  async loadRecording(recId) {
    //console.log("load camera");
    const url = this._APIUrl + "/recordings/" + recId + "/?q=" + this._sessionId;
    try {
      var response = await this.sendAPIRequest(url, "GET");
      return (response);
    }
    catch (error) {
      console.log(error);
      throw (error);
    }
  }

  async deleteRecording(recId) {
    //console.log("delete recording");
    const url = this._APIUrl + "/recordings/" + recId + "/?q=" + this._sessionId;
    try {
      var response = await this.sendAPIRequest(url, "DELETE");
      for (const [i, rec] of this.recordings.entries()) {
        if (rec.id == recId) {
          this.recordings.splice(i, 1);
          break;
        }
      }
      if (this._selectedLabelId != -1) {
        for (const [i, rec] of this.recordingsWithLabel.entries()) {
          if (rec.id == recId) {
            this.recordingsWithLabel.splice(i, 1);
            break;
          }
        }
      }
      // if (this._selectedLabelId!=-1)
      //   await this.loadRecordingsWithLabel(true);
      // await this.loadRecordings(true);
      return (response);
    }
    catch (error) {
      console.log(error);
      throw (error);
    }
  }

  async modifyRecording(recId, locked) {
    const data = {
      locked: locked,
    };
    const url = this._APIUrl + "/recordings/" + recId + "/?q=" + this._sessionId;
    try {
      var response = await this.sendAPIRequest(url, "PUT", data);
      for (let rec of this.recordings) {
        if (rec.id == recId) {
          rec.locked = locked;
          break;
        }
      }
      if (this._selectedLabelId != -1) {
        for (let rec of this.recordingsWithLabel) {
          if (rec.id == recId) {
            rec.locked = locked;
            break;
          }
        }
      }
      // if (this._selectedLabelId!=-1)
      //   await this.loadRecordingsWithLabel(true);
      // await this.loadRecordings(true);
      return (response);
    }
    catch (error) {
      console.log(error);
      throw (error);
    }
  }
  //#endregion

  //#region ----------------------- EXPORTS -------------------------

  async exportRecording(recId, name, initDate, endDate) {
    const data = {
      recid: recId,
      name: name,
      dateini: initDate,
      dateend: endDate,
    };
    const url = this._APIUrl + "/exports/?q=" + this._sessionId;
    try {
      var response = await this.sendAPIRequest(url, "POST", data);
      return (response);
    }
    catch (error) {
      console.log(error);
      throw (error);
    }
  }

  async loadExportedRecordings() {
    const url = this._APIUrl + "/exports/?q=" + this._sessionId;
    try {
      var response = await this.sendAPIRequest(url, "GET");
      return (response);
    }
    catch (error) {
      console.log(error);
      throw (error);
    }
  }

  async loadExportedRecording(exportId) {
    const url = this._APIUrl + "/exports/" + exportId + "/?q=" + this._sessionId;
    try {
      var response = await this.sendAPIRequest(url, "GET");
      return (response);
    }
    catch (error) {
      console.log(error);
      throw (error);
    }
  }

  async deleteExportedRecording(exportId) {
    const url = this._APIUrl + "/exports/" + exportId + "/?q=" + this._sessionId;
    try {
      var response = await this.sendAPIRequest(url, "PUT");
      return (response);
    }
    catch (error) {
      console.log(error);
      throw (error);
    }
  }

  async cancelExportation(exportId) {
    const url = this._APIUrl + "/exports/" + exportId + "/?q=" + this._sessionId;
    try {
      var response = await this.sendAPIRequest(url, "DELETE");
      return (response);
    }
    catch (error) {
      console.log(error);
      throw (error);
    }
  }

  getExportedRecordingURL(exportId) {
    return this._APIUrl + "/exports/" + exportId + "/video/?q=" + this._sessionId;
  }


  //#endregion

  //#region ----------------------- INSTALLATIONS -------------------------

  async loadLabels(forced = false) {
    //console.log("loadInstallations");
    return this.loadInstallations(forced);
  }

  async loadInstallations(forced = false) {
    if (!forced) {
      if (this._labelItemList != null) {
        return ({
          result: 0,
          installations: this._labelItemList
        });
      }
    }
    const url = this._APIUrl + "/installations/?q=" + this._sessionId;
    try {
      var response = await this.sendAPIRequest(url);
      console.log(response);
      this._labelItemList = [...response.installations];
      return (response);
    }
    catch (error) {
      console.log(error);
      throw (error);
    }
  }

  async loadInstallation(instId) {
    //console.log("load installation");
    const url = this._APIUrl + "/installations/" + instId + "/?q=" + this._sessionId;
    try {
      var response = await this.sendAPIRequest(url, "GET");
      return (response);
    }
    catch (error) {
      console.log(error);
      throw (error);
    }
  }

  async deleteInstallation(instId) {
    //console.log("delete installation");
    const url = this._APIUrl + "/installations/" + instId + "/?q=" + this._sessionId;
    try {
      var response = await this.sendAPIRequest(url, "DELETE");
      for (const [i, inst] of this.labels.entries()) {
        if (inst.id == instId) {
          this.labels.splice(i, 1);
          break;
        }
      }
      //await this.loadInstallations(true);
      return (response);
    }
    catch (error) {
      console.log(error);
      throw (error);
    }
  }

  async addInstallation(name) {
    const data = {
      name: name,
    };
    const url = this._APIUrl + "/installations/?q=" + this._sessionId;
    try {
      var response = await this.sendAPIRequest(url, "POST", data);
      await this.loadInstallations(true);
      return (response);
    }
    catch (error) {
      console.log(error);
      throw (error);
    }
  }


  async modifyInstallation(instId, name = null, alarmState = null) {
    var data = {};
    if (name != null)
      data.name = name;
    if (alarmState != null)
      data.alarmState = alarmState;
    //console.log("modify installation");
    const url = this._APIUrl + "/installations/" + instId + "/?q=" + this._sessionId;
    try {
      var response = await this.sendAPIRequest(url, "PUT", data);
      for (let inst of this.labels) {
        if (inst.id == instId) {
          if (data.name != undefined)
            inst.name = data.name;
          if (data.alarmState != undefined)
            inst.alarmState = data.alarmState ? 1 : 0;
          break;
        }
      }
      //await this.loadInstallations(true);
      return (response);
    }
    catch (error) {
      console.log(error);
      throw (error);
    }
  }

  async loadInstallationElements(instId) {
    const url = this._APIUrl + "/installations/" + instId + "/elements/?q=" + this._sessionId;
    //console.log(url);
    try {
      var response = await this.sendAPIRequest(url, "GET");
      return (response);
    }
    catch (error) {
      console.log(error);
      throw (error);
    }
  }

  async addInstallationElements(instId, cameras, groups) {
    const data = {
      camid: cameras,
      groupid: groups,
    };
    const url = this._APIUrl + "/installations/" + instId + "/elements/?q=" + this._sessionId;
    try {
      var response = await this.sendAPIRequest(url, "POST", data);
      //await this.loadGroups(true);
      return (response);
    }
    catch (error) {
      console.log(error);
      throw (error);
    }
  }

  async deleteInstallationElements(instId) {
    const url = this._APIUrl + "/installations/" + instId + "/elements/?q=" + this._sessionId;
    try {
      var response = await this.sendAPIRequest(url, "DELETE");
      return (response);
    }
    catch (error) {
      console.log(error);
      throw (error);
    }
  }

  //#endregion

  //#region ----------------------- TASKS ------------------------

  async loadTasks(forced = false) {
    if (!forced) {
      if (this._taskItemList != null) {
        return ({
          result: 0,
          progs: this._taskItemList
        });
      }
    }
    const url = this._APIUrl + "/tasks/?q=" + this._sessionId;
    try {
      var response = await this.sendAPIRequest(url);
      //console.log(response);
      this._taskItemList = [...response.progs];

      return (response);
    }
    catch (error) {
      console.log(error);
      throw (error);
    }
  }

  async loadTask(taskId) {
    //console.log("load installation");
    const url = this._APIUrl + "/tasks/" + taskId + "/?q=" + this._sessionId;
    try {
      var response = await this.sendAPIRequest(url, "GET");
      return (response);
    }
    catch (error) {
      console.log(error);
      throw (error);
    }
  }

  async deleteTask(taskId) {
    //console.log("delete installation");
    const url = this._APIUrl + "/tasks/" + taskId + "/?q=" + this._sessionId;
    try {
      var response = await this.sendAPIRequest(url, "DELETE");
      for (const [i, inst] of this.tasks.entries()) {
        if (inst.id == taskId) {
          this.tasks.splice(i, 1);
          break;
        }
      }
      //await this.loadInstallations(true);
      return (response);
    }
    catch (error) {
      console.log(error);
      throw (error);
    }
  }

  async addTask(name, type, disabled) {
    const data = {
      name: name,
      type: type,
      disabled: disabled,
    };
    const url = this._APIUrl + "/tasks/?q=" + this._sessionId;
    try {
      var response = await this.sendAPIRequest(url, "POST", data);
      await this.loadTasks(true);
      return (response);
    }
    catch (error) {
      console.log(error);
      throw (error);
    }
  }


  async modifyTask(taskId, name = null, type = null, disabled = null) {
    var data = {};
    if (name != null)
      data.name = name;
    if (type != null)
      data.type = type;
    if (disabled != null)
      data.disabled = disabled;

    const url = this._APIUrl + "/tasks/" + taskId + "/?q=" + this._sessionId;
    try {
      var response = await this.sendAPIRequest(url, "PUT", data);
      for (let task of this.tasks) {
        if (task.id == taskId) {
          if (data.name != undefined)
            task.name = data.name;
          if (data.type != undefined)
            task.type = data.type;
          if (data.disabled != undefined)
            task.disabled = data.disabled;
          break;
        }
      }
      //await this.loadInstallations(true);
      return (response);
    }
    catch (error) {
      console.log(error);
      throw (error);
    }
  }

  async loadTaskCameras(taskId) {
    const url = this._APIUrl + "/tasks/" + taskId + "/cameras/?q=" + this._sessionId;
    //console.log(url);
    try {
      var response = await this.sendAPIRequest(url, "GET");
      return (response);
    }
    catch (error) {
      console.log(error);
      throw (error);
    }
  }

  async addTaskCameras(taskId, camId) {
    const data = { camid: camId };
    const url = this._APIUrl + "/tasks/" + taskId + "/cameras/?q=" + this._sessionId;
    try {
      var response = await this.sendAPIRequest(url, "POST", data);
      //await this.loadGroups(true);
      return (response);
    }
    catch (error) {
      console.log(error);
      throw (error);
    }
  }

  async deleteTaskCameras(taskId) {
    const url = this._APIUrl + "/tasks/" + taskId + "/cameras/?q=" + this._sessionId;
    try {
      var response = await this.sendAPIRequest(url, "DELETE");
      return (response);
    }
    catch (error) {
      console.log(error);
      throw (error);
    }
  }

  async loadTaskTimetable(taskId) {
    const url = this._APIUrl + "/tasks/" + taskId + "/timetable/?q=" + this._sessionId;
    //console.log(url);
    try {
      var response = await this.sendAPIRequest(url, "GET");
      for (let task of this.tasks) {
        if (task.id == taskId) {
          task.timetables = [...response.timetables];
          break;
        }
      }
      return (response);
    }
    catch (error) {
      console.log(error);
      throw (error);
    }
  }

  async addTaskTimetable(taskId, timetable) {
    const data = { timetable: timetable };
    const url = this._APIUrl + "/tasks/" + taskId + "/timetable/?q=" + this._sessionId;
    try {
      var response = await this.sendAPIRequest(url, "POST", data);
      for (let task of this.tasks) {
        if (task.id == taskId) {
          task.timetables = [...timetable];
          break;
        }
      }
      return (response);
    }
    catch (error) {
      console.log(error);
      throw (error);
    }
  }

  async deleteTaskTimetable(taskId) {
    const url = this._APIUrl + "/tasks/" + taskId + "/timetable/?q=" + this._sessionId;
    try {
      var response = await this.sendAPIRequest(url, "DELETE");
      for (let task of this.tasks) {
        if (task.id == taskId) {
          task.timetables = undefined;
          break;
        }
      }
      return (response);
    }
    catch (error) {
      console.log(error);
      throw (error);
    }
  }

  //#endregion

  //#region ----------------------- USERS ------------------------

  async loadUsers() {
    const url = this._APIUrl + "/users/?q=" + this._sessionId;
    try {
      var response = await this.sendAPIRequest(url);
      //console.log(response);
      return (response);
    }
    catch (error) {
      console.log(error);
      throw (error);
    }
  }

  async loadUser(userId) {
    const url = this._APIUrl + "/users/" + userId + "/?q=" + this._sessionId;
    try {
      var response = await this.sendAPIRequest(url);
      //console.log(response);
      return (response);
    }
    catch (error) {
      console.log(error);
      throw (error);
    }
  }

  async deleteUser(userId) {
    //console.log("delete user");
    const url = this._APIUrl + "/users/" + userId + "/?q=" + this._sessionId;
    try {
      var response = await this.sendAPIRequest(url, "DELETE");
      //await this.loadInstallations(true);
      return (response);
    }
    catch (error) {
      console.log(error);
      throw (error);
    }
  }

  async addUser(email, passwd, name, surname, privcod, camIds, groupIds, instIds) {
    const data = {
      email: email,
      passwd: passwd,
      name: name,
      surname: surname,
      privcod: privcod,
      camIds: camIds,
      groupIds: groupIds,
      instIds: instIds,
    };
    const url = this._APIUrl + "/users/?q=" + this._sessionId;
    try {
      var response = await this.sendAPIRequest(url, "POST", data);
      return (response);
    }
    catch (error) {
      console.log(error);
      throw (error);
    }
  }

  async modifyUser(userId, passwd, name, surname, privcod, camIds, groupIds, instIds) {
    const data = {
      passwd: passwd,
      name: name,
      surname: surname,
      privcod: privcod,
      camIds: camIds,
      groupIds: groupIds,
      instIds: instIds,
    };
    const url = this._APIUrl + "/users/" + userId + "/?q=" + this._sessionId;
    try {
      var response = await this.sendAPIRequest(url, "PUT", data);
      return (response);
    }
    catch (error) {
      console.log(error);
      throw (error);
    }
  }

  async loadUserCameras(userId) {
    const url = this._APIUrl + "/users/" + userId + "/cameras/?q=" + this._sessionId;
    //console.log(url);
    try {
      var response = await this.sendAPIRequest(url, "GET");
      return (response);
    }
    catch (error) {
      console.log(error);
      throw (error);
    }
  }

  async loadUserGroups(userId) {
    const url = this._APIUrl + "/users/" + userId + "/groups/?q=" + this._sessionId;
    //console.log(url);
    try {
      var response = await this.sendAPIRequest(url, "GET");
      return (response);
    }
    catch (error) {
      console.log(error);
      throw (error);
    }
  }

  async loadUserInstallations(userId) {
    const url = this._APIUrl + "/users/" + userId + "/installations/?q=" + this._sessionId;
    //console.log(url);
    try {
      var response = await this.sendAPIRequest(url, "GET");
      return (response);
    }
    catch (error) {
      console.log(error);
      throw (error);
    }
  }

  async modifyUserPassword(email, passwd, newpasswd) {
    const data = {
      email: email,
      password: passwd,
      newpassword: newpasswd,
    };

    const url = this._APIUrl + "/password/?q=" + this._sessionId;
    try {
      var response = await this.sendAPIRequest(url, "PUT", data);
      return (response);
    }
    catch (error) {
      console.log(error);
      throw (error);
    }
  }

  async modifyUser2FA(enabled2fa) {
    const data = {
      enabled2fa: enabled2fa ? 1 : 0,
    };
    const url = this._APIUrl + "/authentication/?q=" + this._sessionId;
    try {
      var response = await this.sendAPIRequest(url, "PUT", data);
      return (response);
    }
    catch (error) {
      console.log(error);
      throw (error);
    }
  }

  //#endregion

  //#region ----------------------- SYSTEM ------------------------

  async getStorage(forced = false) {
    const url = this._APIUrl + "/storage/?q=" + this._sessionId;
    if (!forced) {
      if (this._storage != null) {
        return { result: 0, storage: this._storage }
      }
    }
    try {
      var response = await this.sendAPIRequest(url);
      //console.log(response);
      this._storage = response.storage;
      return (response);
    }
    catch (error) {
      console.log(error);
      throw (error);
    }
  }

  async getLanguage(forced = false) {
    const url = this._APIUrl + "/language/?q=" + this._sessionId;
    if (!forced) {
      if (this._language != null) {
        return { result: 0, language: this._language }
      }
    }
    try {
      var response = await this.sendAPIRequest(url);
      this._language = response.language;
      sessionStorage.setItem("sessionManager.language", response.language);
      return (response);
    }
    catch (error) {
      console.log(error);
      throw (error);
    }
  }

  async setLanguage(language) {
    const url = this._APIUrl + "/language/?q=" + this._sessionId;
    const data = {
      language: language,
    };

    try {
      var response = await this.sendAPIRequest(url, "PUT", data);
      this._language = language;
      sessionStorage.setItem("sessionManager.language", response.language);
      return (response);
    }
    catch (error) {
      console.log(error);
      throw (error);
    }
  }


  setEnabledNotifications(motionDetection, audioDetection, connectivityEvents, labelConfiguration, externalEvents) {
    this._notificationsSettings = {
      motionDetection: motionDetection,
      audioDetection: audioDetection,
      connectivityEvents: connectivityEvents,
      labelConfiguration: labelConfiguration,
      externalEvents: externalEvents,
    }
    localStorage.setItem("Notifications_" + this._APIServer + "_" + this._userName, JSON.stringify(this._notificationsSettings));
  }

  setCameraListOrderBy(item) {
    this._cameraListOrderBySettings = item;
    localStorage.setItem("cameraListOrderBySettings", item);
    this.deleteAllCameraPreviews();
    this.loadCameras(true);
    if (this.selectedLabelId!=-1)
      this.loadCamerasWithLabel(true);
    this.loadLabels(true);
  }

  //#endregion

  //#region ----------------------- LICENSES ------------------------

  setLicenseProfiles(summary,config) {
    //api codes
    const LITE = 0;
    const PRO = 1;
    const PREMIUM = 2;
    const LITEPLUS = 3;
    //let licenses = [];
    let total_left = 0;
    let profileId = LITE;
    let profilePriority = 3;
    let profileValue = "light";
    let profileText = config.ProfileLightText;
    //console.log("set licenses");
    this.licenses = [];
    for (const item of summary) {
      const left = parseInt(item.max) - item.current;
      total_left += left;
      if (item.profile === LITEPLUS) {
        profileValue = "lightplus";
        profileText = config.ProfileLightPlusText;
        profileId = LITEPLUS;
        profilePriority = 2;
      }
      if (item.profile === PRO) {
        profileValue = "pro";
        profileText = config.ProfileProText ;
        profileId = PRO;
        profilePriority = 0;
      }
      if (item.profile === PREMIUM) {
        profileValue = "premium";
        profileText = config.ProfilePremiumText;
        profileId = PREMIUM;
        profilePriority = 1;
      }
      const license = {
        profileId: profileId,
        profileValue: profileValue,
        profileText: profileText,
        left: left,
        priority: profilePriority,
      };
      this.licenses.push(license);
    }
    this.totalLicensesLeft = total_left;
  }

  //#endregion

  //#region ----------------------- API REQUEST -------------------------


  async sendAPIRequest(url, method = "GET", data = null) {
    var func;
    var params;
    if (method == "GET") {
      func = axios.get;
    }
    else if (method == "POST") {
      func = axios.post;
    }
    else if (method == "PUT") {
      func = axios.put;
    }
    else if (method == "DELETE") {
      func = axios.delete;
    }

    if (data == null) {
      params = [url];
    }
    else {
      params = [url, data];
    }

    try {
      var response = await func(...params);
      //console.log(response);
      if (response.status == 200 && response.data.result >= 0) {
        return (response.data);
      } else {
        throw (response.data);
      }

    }
    catch (error) {
      if (error.result === undefined) {
        throw ({
          result: -1,
          error: error
        });
      }
      else
        throw error;
    }
  }

  //#endregion


  //#region ----------------------- MQTT CLIENT -------------------------

  startMQTTClient(server, topic, user, password) {
    console.log("manageMQTTConnection");
    let self = this;
    if (self.clientMQTT != undefined && self.clientMQTT.connected) return;
    //const userInfo = JSON.parse(sessionStorage.getItem("userInfo"));
    const mqtt_url = "wss://" + server + ":443/mqtt";
    const options = {
      username: user,
      password: password,
    };

    self.clientMQTT = mqtt.connect(mqtt_url, options);
    console.log("clientMQTT state:", self.clientMQTT.connected);

    self.clientMQTT.on("connect", function () {
      console.log("connect");
      self.clientMQTT.subscribe(topic, function (err) {
        if (!err) {
          console.log(
            "connected with MQTT server and subscribed to",
            topic
          );
        } else {
          console.log(err);
        }
      });
    });
    self.clientMQTT.on("error", function (error) {
      console.error("Error connecting to MQTT server:", error);
    });
    self.clientMQTT.on("close", function () {
      console.log("Closed connection with MQTT server");
    });
    self.clientMQTT.on("message", function (topic, message) {
      console.log("new message from mqtt server.");
      console.log("topic:", topic);
      console.log("message:", message.toString());

      const msg = JSON.parse(message);
      const event = msg.eventType;
      const id = msg.id;
      const date = msg.date;

      //const command = message.toString().split("/");
      //const event = parseInt(command[0]);
      //const id = parseInt(command[1]);
      //const date="88888888888888";
      console.log("new command:", event, id);

      switch (event) {
        case 100:
        case 101:
          self.updateConnStateMQTT(event, id, date);
          break;
        case 200:
          for (let cam of self._camItemList) {
            if (cam.id == id) {
              self.sendNotification(cam.name, i18n.global.t('notifications.motion_detected'), event, date);
              break;
            }
          }
          break;
        case 300:
        case 301:
          self.updateLabelStateMQTT(event, id, date);
          break;
        case 400:
          for (let cam of self._camItemList) {
            if (cam.id == id) {
              self.sendNotification(cam.name, i18n.global.t('notifications.audio_detected'), event, date);
              break;
            }
          }
          break;
        case 500:
          self.sendNotification(msg.message, i18n.global.t('notifications.live_popup'), event, date);
          break;
      }
    });
  }

  updateConnStateMQTT(event, camId, date) {
    console.log("updateConnState", event, camId);
    //console.log(i18n);
    let new_state = -1;
    let notifText;
    if (event == 100) {
      new_state = 0;
      notifText = i18n.global.t('notifications.connection_lost');
    }
    if (event == 101) {
      new_state = 1;
      notifText = i18n.global.t('notifications.connection_recovered');
    }
    if (new_state != -1) {
      for (let cam of this._camItemList) {
        if (cam.id == camId) {
          if (cam.connStatus != new_state) {
            this.sendNotification(cam.name, notifText, event, date);
          }
          cam.connStatus = new_state;
          break;
        }
      }

      if (this._selectedLabelId != -1) {
        for (let cam of this._labelCamItemList) {
          if (cam.id == camId) {
            cam.connStatus = new_state;
            break;
          }
        }
      }
    }
  }

  updateLabelStateMQTT(event, labelId, date) {
    console.log("updateLabelState", event, labelId);

    let new_state = -1;
    let notifText;
    if (event == 300) {
      new_state = 0;
      notifText = i18n.global.t('notifications.notifications_disabled');
    }
    if (event == 301) {
      notifText = i18n.global.t('notifications.notifications_enabled');
      new_state = 1;
    }
    if (new_state != -1) {
      for (let label of this.labels) {
        if (label.id == labelId) {
          if (label.alarmState != new_state) {
            this.sendNotification(label.name, notifText, event, date);
          }
          label.alarmState = new_state;
          break;
        }
      }
    }

  }

  sendNotification(title, text, type, date) {
    let icon;
    switch (type) {
      case 100:
        if (!this._notificationsSettings.connectivityEvents) return;
        icon = "cloud_off";
        break;
      case 101:
        if (!this._notificationsSettings.connectivityEvents) return;
        icon = "cloud_done";
        break;
      case 200:
        if (!this._notificationsSettings.motionDetection) return;
        icon = "directions_run";
        break;
      case 300:
        if (!this._notificationsSettings.labelConfiguration) return;
        icon = "notifications_off";
        break;
      case 301:
        if (!this._notificationsSettings.labelConfiguration) return;
        icon = "notifications_active";
        break;
      case 400:
        if (!this._notificationsSettings.audioDetection) return;
        icon = "volume_up";
        break;
      case 500:
        if (!this._notificationsSettings.externalEvents) return;
        icon = "error_outline";
        break;
    }
    //let date=new Date();
    //let dateStr=("0" + date.getHours()).slice(-2) + ":" + ("0" + date.getMinutes()).slice(-2) + ":" + ("0" + date.getSeconds()).slice(-2);
    let dateStr = date.slice(8, 10) + ":" + date.slice(10, 12) + ":" + date.slice(12, 14);
    const params = {
      title: title,
      text: text,
      type: icon,
      duration: 20000,
      data: {
        icon: icon,
        date: dateStr
      },
    }
    notify(params);
  }

  //#endregion

  //#region ----------------------- FIRESTORE -------------------------

  notificationConverter = {
    getDate: (tm) => {
      return (
        tm.substring(6, 8) + "/" + tm.substring(4, 6) + "/" + tm.substring(0, 4)
      );
    },
    getTime: (tm) => {
      if (tm) {
        return tm.substring(8, 10) + ":" + tm.substring(10, 12);
      } else {
        return "---";
      }
    },
    toFirestore: (notification) => {
      return {
        eventType: notification.eventType,
        date: notification.date,
        pending: notification.pending,
        id: notification.id,
        secId: notification.secId,
        message: notification.message,
      };
    },
    fromFirestore: (snapshot, options) => {
      const data = snapshot.data(options);
      let text = "";
      switch (data.eventType) {
        case 300:
        case 301: // LABELS
          for (let label of this.labels) {
            if (label.id == data.id) {
              text = label.name;
              break;
            }
          }
          break;
        case 500: // POPUP
          text = data.message;
          break;
        default:
          for (let cam of this.cameras) {
            if (cam.id == data.id) {
              text = cam.name;
              break;
            }
          }
      }
      if (text == "") text = i18n.global.t('notifications.unknown');
      return {
        docId: snapshot.id,
        eventType: data.eventType,
        date: data.date,
        parsedDate: this.notificationConverter.getDate(data.date) + " " + this.notificationConverter.getTime(data.date),
        pending: data.pending,
        id: data.id,
        secId: data.secId,
        message: data.message,
        text: text,
      };
    }
  };


  async initializeFirestore(platformId, userType, userId, token) {
    if (this._firestore != null) return;
    console.log("firestoreLogin");
    try {
      //console.log("Login with token", token);
      await firebase.signIn(token);
      this._firestore = firebase.getFirestore();
      this._userDocumentRef = firebase.doc(this._firestore, "platforms/" + platformId + "/" + (userType == 0 ? "users" : "depusers") + "/" + userId.toString());
      this._notificationsCollectionRef = firebase.collection(this._userDocumentRef, "notifications").withConverter(this.notificationConverter);
      //console.log(this._userDocumentRef);


      // const docSnap = await firebase.getDoc(this._userDocumentRef);
      // if (docSnap.exists()) {
      //   // Convert to City object
      //   console.log(docSnap.data());
      // } else {
      //   console.log("No such document!");
      // }
    }
    catch (error) {
      console.log(error);
    }

  }

  //#endregion
}
