<template>
  <menu-main></menu-main>

  <div id="labels">
    <header-main> </header-main>

    <div class="cuerpo_principal">
      <div class="mosaico_cam">
        <div class="main_content">
          <div class="grid_busc">
            <form class="buscador" @submit.prevent>
              <input style="padding-right: 40px;"
                id="search"
                type="search"
                v-model="search"
                :placeholder="$t('common.search')"
              />
              <span class="material-icons refresh-button"
                @click="refresh">refresh</span>
            </form>
            
          </div>
        </div>
      </div>


      <div class="container">
        <p v-if="this.notificationsLoaded && !this.notifications.length" class="no_data_msg">
          {{ $t('notifications.no_notifications') }}
        </p>
        <div v-if="this.notificationsLoaded && this.notifications.length" class="row">
          <div id="tgrab" class="col-12 offset-0 col-md-10 offset-md-1">
            <div class="table-responsive">
              <table class="table align-middle">
                <thead>
                  <tr>
                    <th scope="col">{{ $t('notifications.type') }}</th>
                    <th scope="col">{{ $t('notifications.info') }}</th>
                    <th scope="col">{{ $t('common.date') }}</th>
                    <th scope="col" style="text-align: right;">
                      <span :title="$t('notifications.mark_all_read')" v-on:click.stop="markAllRead()" class="material-icons" style="font-size: 24px;line-height:1;vertical-align:middle; cursor:pointer; margin-right:10px;">playlist_add_check</span>
                      <span :title="$t('notifications.delete_all')" v-on:click.stop="deleteAll()" class="material-icons" style="font-size: 24px;line-height:1;vertical-align:middle; cursor:pointer">delete</span>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <!--:key="notification.id"
                  
                  @delete-event="showDeleteConfirmation"
                      @edit-event="editLabel"-->
                  <!--<template v-for="(notification, index) in filterNotifications" :key="notification.docId">-->
                    <notification-item v-for="(notification, index) in filterNotifications" :key="notification.docId"
                      :docId="notification.docId"
                      :index="index"
                      :eventType="notification.eventType"
                      :date="notification.date"
                      :parsedDate="notification.parsedDate"
                      :id="notification.id"
                      :secId="notification.secId"
                      :message="notification.message"
                      :pending="notification.pending"
                      :text="notification.text"
                      @delete-event="deleteNotification"
                      @processed-event="processNotification"
                      
                    >
                    </notification-item>
                  <!--</template>-->
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <menu-footer active-state="labels"></menu-footer>
</template>

<script>
import NotificationItem from "./NotificationItem.vue";
//import DataManager from "../../assets/custom/js/DataManager.js";
//const axios = require("axios").default;
//axios.defaults.withCredentials = false;
//const Swal = require("sweetalert2");
import * as firebase from "../../assets/custom/js/Firebase.js"

export default {
  name: "NotificationsMain",
  components: {
    NotificationItem,
  },
  data() {
    return {
      //labels: [],
      search: "",
      /*error: false,
      loaded: false,*/
      //editLabelsPriv: false,
      notifications: [],
      notificationsLoaded: false,
    };
  },
  computed: {
    filterNotifications() {
      const filter1 = this.filterNotificationsByName();
      const filter2 = this.filterNotificationsByDay();
      if (filter1 || filter2) {
        return [...new Set([...filter1, ...filter2])];
      }
      return this.notifications;
    },
    notificationsCollectionRef() {
      return this.$root.dataManager.notificationsCollectionRef;
    }
  },
  watch: {
    notificationsCollectionRef() {
    if (this.notificationsCollectionRef!=null) {
      console.log("Load notifications 2");
      const q = firebase.query(this.$root.dataManager.notificationsCollectionRef, firebase.orderBy("date", "desc"));
        this.unsubscribe = firebase.onSnapshot(q, (querySnapshot) => {
        this.notifications = [];
        
        querySnapshot.forEach((doc) => {
          if (doc.data()!=undefined) {
            this.notifications.push(doc.data());
          }
        });
        this.notificationsLoaded=true;
        //console.log("Notifications: ", this.notifications);
      });
    }

    }
  },

  methods: {
    async refresh() {
      console.log("refresh");
      //this.$root.dataManager.loadLabels(true);
    },
    filterNotificationsByName() {
      return this.search
        ? this.notifications.filter((notification) =>
          notification.text.toLowerCase().includes(this.search.toLowerCase())
          )
        : null;
    },
    filterNotificationsByDay() {
      return this.search
        ? this.notifications.filter((notification) =>
            notification.parsedDate.includes(this.search.toLowerCase())
          )
        : null;
    },
    async markAllRead() {
      console.log("Mark all read");
      const notificationsBlockSize=200;
      let i,j;
      console.log(notificationsBlockSize);
      console.log(this.notifications.length/notificationsBlockSize);
      console.log(Math.floor(this.notifications.length/notificationsBlockSize));
      for (j=0;j<Math.floor(this.notifications.length/notificationsBlockSize);j++) {
        var batch = firebase.writeBatch(this.$root.dataManager.firestore);
        for (i=j*notificationsBlockSize;i<(j+1)*notificationsBlockSize; i++) {
          if (this.notifications[i].pending) {
            const docRef=firebase.doc(this.$root.dataManager.notificationsCollectionRef,this.notifications[i].docId);
            batch.update(docRef, {pending: false});
          }
        }
        await batch.commit();
      }
      batch = firebase.writeBatch(this.$root.dataManager.firestore);
      for (i=j*notificationsBlockSize;i<this.notifications.length; i++) {
        if (this.notifications[i].pending) {
          const docRef=firebase.doc(this.$root.dataManager.notificationsCollectionRef,this.notifications[i].docId);
          batch.update(docRef, {pending: false});
        }
      }
      batch.update(this.$root.dataManager.userDocumentRef,{pendingMotion: 0, pendingAudio: 0, pendingConnectivity: 0, pendingExternal: 0, pendingLabelsConfig: 0});
      await batch.commit();
    },
    async deleteAll() {
      console.log("Delete all");
      
      const notificationsBlockSize=200;
      let i,j;
      for (j=0;j<Math.floor(this.notifications.length/notificationsBlockSize);j++) {
        var batch = firebase.writeBatch(this.$root.dataManager.firestore);
        for (i=j*notificationsBlockSize;i<(j+1)*notificationsBlockSize; i++) {
          const docRef=firebase.doc(this.$root.dataManager.notificationsCollectionRef,this.notifications[i].docId);
          batch.delete(docRef);
        }
        await batch.commit();
      }
      batch = firebase.writeBatch(this.$root.dataManager.firestore);
      for (i=j*notificationsBlockSize;i<this.notifications.length; i++) {
        const docRef=firebase.doc(this.$root.dataManager.notificationsCollectionRef,this.notifications[i].docId);
        batch.delete(docRef);
      }
      batch.update(this.$root.dataManager.userDocumentRef,{pendingMotion: 0, pendingAudio: 0, pendingConnectivity: 0, pendingExternal: 0, pendingLabelsConfig: 0});
      await batch.commit();
      //var docRef=firebase.doc(this.$root.dataManager.notificationsCollectionRef,docId);
      //await firebase.deleteDoc(docRef);
    },
    async deleteNotification(docId) {
      console.log("Delete notification " + docId);
      var docRef=firebase.doc(this.$root.dataManager.notificationsCollectionRef,docId);
      await firebase.deleteDoc(docRef);
    },
    async processNotification(docId) {
      console.log("Process notification " + docId);
      let currentNotification;
      let currentNotificationIndex;
      for (let [index,notification] of this.notifications.entries()) {
        if (notification.docId==docId)
        {
          currentNotification=notification;
          currentNotificationIndex=index;
          break;
        }
      }
      //console.log(currentNotification);
      if (currentNotification!=null) {
        var batch;
        switch (currentNotification.eventType) {
          case 200:
          case 400:
          case 500:
            var docRef=firebase.doc(this.$root.dataManager.notificationsCollectionRef,currentNotification.docId);
            await firebase.updateDoc(docRef, {pending: false});
            break;
          case 100:
          case 101:
            batch = firebase.writeBatch(this.$root.dataManager.firestore);
            for (let i=currentNotificationIndex;i<this.notifications.length;i++) {
              if (this.notifications[i].id==currentNotification.id && (this.notifications[i].eventType==100 || this.notifications[i].eventType==101)) {
                const docRef=firebase.doc(this.$root.dataManager.notificationsCollectionRef,this.notifications[i].docId);
                batch.update(docRef, {pending: false});
                //console.log("Mark read", this.notifications[i]);
              }

            }
            await batch.commit();
            break;
          case 300:
          case 301:
            batch = firebase.writeBatch(this.$root.dataManager.firestore);
            for (let i=currentNotificationIndex;i<this.notifications.length;i++) {
              if (this.notifications[i].id==currentNotification.id && (this.notifications[i].eventType==300 || this.notifications[i].eventType==301)) {
                const docRef=firebase.doc(this.$root.dataManager.notificationsCollectionRef,this.notifications[i].docId);
                batch.update(docRef, {pending: false});
                //console.log("Mark read", this.notifications[i]);
              }

            }
            await batch.commit();
            break;
        }
      }
      
      
    },


  },
  mounted() {
    document.documentElement.style.scrollBehavior = 'auto';
    console.log("NotificationsMain");
    document.getElementById("search").focus();
    document.getElementById("search").blur();
    document.title = this.$t('common.notifications');
    //const userInfo = JSON.parse(sessionStorage.getItem("userInfo"));
    if (!this.$root.sessionManager.isLoggedIn()) {
      console.log("router to signin");
      this.$router.push({ name: "SignIn" });
    }

    if (this.notificationsCollectionRef!=null) {
      console.log("Load notifications 1");
      const q = firebase.query(this.$root.dataManager.notificationsCollectionRef, firebase.orderBy("date", "desc"));
      this.unsubscribe=firebase.onSnapshot(q, (querySnapshot) => {
        this.notifications = [];
        querySnapshot.forEach((doc) => {
          if (doc.data()!=undefined) {
            this.notifications.push(doc.data());
          }
        });
        this.notificationsLoaded=true;
        //console.log("Notifications: ", this.notifications);
      });
    }
  },
  unmounted() {
    if (this.unsubscribe)
      this.unsubscribe();
    this.unsubscribe=undefined;
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>