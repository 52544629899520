<template>
  <tr v-on:click="signInAsClient">
    <th scope="row">
      <span
        class="material-icons"
        style="font-size: 32px; line-height: 1; vertical-align: middle"
        >person</span
      >
    </th>
    <td>{{ name }}</td>
    <td>{{ surname }}</td>
    <td>{{ email }}</td>
  </tr>
</template>

<script>
export default {
  name: "ClientItem",
  props: {
    index: Number,
    accessToken: {
      type: String,
      required: true,
      default: "",
    },
    name: {
      type: String,
      required: true,
      default: "",
    },
    surname: {
      type: String,
      required: true,
      default: "",
    },
    email: {
      type: String,
      required: true,
      default: "",
    },
  },
  data() {
    return {
      loaded: false,
      error: false,
      //token: "",
    };
  },
  mounted() {
    //this.token = this.accessToken;
    //console.log("ClientItem mounted", this.token);
  },
  methods: {
    signInAsClient() {
      this.$root.sessionManager.logout();
      this.$root.dataManager.clear();
      this.$router.push({
        name: "SignIn",
        params: { token: this.accessToken, email: this.email },
      });
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>