<template>
<tr v-on:click="viewRecording(id, name,rectime)">
<!-- v-on:click="viewRecording(id, name)" -->
    <th scope="row">
      <div class="tg_vista black-background">
        <!--<img :src="imgRecording()" alt="" />-->
				<img v-if="(preview.textOrImage==2)"
          :src="preview.previewImg" alt=""/>
				
				<span v-show="this.locked" class="material-icons lock_icon">https</span>
      </div>
			
    </th>
		<td>
			<span v-if="type==0" class="material-icons">person</span>
			<span v-if="type==1" class="material-icons">schedule</span>
			<span v-if="type==2" class="material-icons">priority_high</span>
		</td>
    <td>{{name}}</td>
    <td>{{date}}</td>
    <td>
       <span> {{ $t('common.start') }}: {{start}}</span>
      <span> / {{ $t('common.end') }}: {{end}}</span>
    </td>
    <td style="text-align:right">
      <!-- Default dropstart button -->
      <div class="btn-group dropstart">
        <button v-on:click.stop
          type="button"
          class="btn btn-no-border list-popup"
          data-bs-toggle="dropdown"
          aria-expanded="false"
					ref="dropdownbtn"
        >
				
          <span
            ><svg
              id="Modo_de_aislamiento"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 1.78 10.75"
            >
              <circle
                class="cls-1"
                cx=".89"
                cy="9.86"
                r=".89"
                transform="translate(-2.23 .46) rotate(-13.2)"
              />
              <circle
                class="cls-1"
                cx=".89"
                cy="5.37"
                r=".89"
                transform="translate(-1.2 .35) rotate(-13.2)"
              />
              <circle
                class="cls-1"
                cx=".89"
                cy=".89"
                r=".89"
                transform="translate(-.18 .23) rotate(-13.2)"
              /></svg></span>
        </button>
        <ul class="dropdown-menu list-dropdown">
          <li @click="updateRecordingProtection(id,!this.locked)" >
            <a><i :class="{'fa fa-unlock':this.locked,'fa fa-lock':!this.locked}" aria-hidden="true"></i> {{protectMessage}} </a>
          </li>
          <!--<li v-if="showRecordingsPriv" @click="viewRecording(id, name)">
            <a><i class="fa fa-eye" aria-hidden="true"></i> Ver</a>
          </li>-->
          <li v-if="deleteRecordingsPriv && !this.locked" @click="deleteRec(id,index)">
            <a><i class="fa fa-trash" aria-hidden="true"></i> {{ $t('common.delete') }}</a>
          </li>
          <li v-if="exportRecordingsPriv" @click="exportRec(id,index)">
            <a><i class="fa fa-download" aria-hidden="true"></i> {{ $t('recordings.export') }}</a>
          </li>
        </ul>
			
      </div>
    </td>
</tr>
</template>

<script>
//const axios = require("axios").default;
//axios.defaults.withCredentials = false;

export default {
  name: "RecordingItem",
  emits:["deleteEvent","exportEvent"],
  props: {
    index: Number,
    id: Number,
    camId: Number,
    name: {
      type: String,
      required: true,
      default: "",
    },
    date: {
      type: String,
      required: true,
      default: "-",
    },
    start: {
      type: String,
      required: true,
      default: "00:00",
    },
    end: {
      type: String,
      required: true,
      default: "23:59",
    },
    type: {
      type: Number,
      default: 0,
    },
    model: {
      type: String,
      default: "",
    },
    locked: {
      type: Boolean,
      default: false,
    },
    rectime:{
      type: String,
      default: "",

    }
  },
  data() {
    return {
      //previewURL: "",
      loaded: false,
      error: false,
      //protected: false,
      showRecordingsPriv:false,
      exportRecordingsPriv:false,
      deleteRecordingsPriv:false, 
      showPreview:true,

    };
  },
  created() {
    //console.log("RecordingItem created");
    this.getPreview(this.camId);
    

  },
  computed:{
    protectMessage(){
      return this.locked ?  this.$t('recordings.unlock') : this.$t('recordings.lock')
    },
    preview() {
      //console.log("compute previewImg");
      let pr={};
      pr.textOrImage=0;
      //pr.previewText="";
      pr.previewImg="";
      if (this.$root.dataManager.camPreviews["cam" + this.camId]===undefined)
      {
        //pr.previewText="";
        pr.previewImg="pending";
        pr.textOrImage=0;
        return pr;
      }
      if (this.$root.dataManager.camPreviews["cam" + this.camId]==="downloading")
      {
        //pr.previewText="";
        pr.previewImg="";
        pr.textOrImage=0;
        return pr;
      }
      if (this.$root.dataManager.camPreviews["cam" + this.camId]==="unavailable")
      {
        //pr.previewText="No hay previsualización disponible<br>Seleccione Actualizar previsualización";
        pr.textOrImage=1;
        return pr;
      }
      //pr.previewText="";
      pr.previewImg=this.$root.dataManager.camPreviews["cam" + this.camId];
      pr.textOrImage=2;
      return pr;
    }
  },
  watch: {
    preview() {
      if (this.preview.previewImg=="pending") {
        this.getPreview(this.id);
      }
    }
  },
  mounted() {
    //console.log("RecordingItem mounted");
    //this.protected = this.locked;
    //console.log("RecordingItem mounted:", this.starttime,this.rectime);
    const userPriv = this.$root.sessionManager.privileges;
    //console.log("UsersMain, userPriv:", userPriv);
    this.showRecordingsPriv = userPriv['showRecordings'];
    this.exportRecordingsPriv = userPriv['exportRecordings'];
    this.deleteRecordingsPriv = userPriv['deleteRecordings'];
		//console.log (this.$refs.dropdownbtn);
		this.$refs.dropdownbtn.addEventListener('show.bs.dropdown', function(e) {
			//console.log("Drop down");
			//console.log(e);
			var oldMenu=document.querySelector('.dropdown-menu.show');
			if (oldMenu!=null) oldMenu.classList.remove("show");
			var dropdownMenu = e.target.parentElement.querySelector(".dropdown-menu");
			if (dropdownMenu===null) return;
			var rect = dropdownMenu.getBoundingClientRect();
			dropdownMenu.style={
				position: 'absolute',
				left: rect.left,
				top: rect.top
			};
			e.target.myMenu=dropdownMenu;
			dropdownMenu.parentElement.removeChild(dropdownMenu);
			document.body.append(dropdownMenu);
		});
		this.$refs.dropdownbtn.addEventListener('hidden.bs.dropdown', function(e) {
			//console.log("Hide drop down");	
			//console.log(e);
			if (e.target.myMenu===undefined) return;
			var dropdownMenu = e.target.myMenu;
			dropdownMenu.style={
				position: false,
				left: false,
				top: false
			};
			document.body.removeChild(dropdownMenu);
			e.target.parentElement.append(dropdownMenu);
		});
  },
  methods: {
		exportRec(id,index) {
			document.querySelector('.dropdown-menu.show').classList.remove("show");
			this.$emit('exportEvent',id,index);
		},
		deleteRec(id,index) {
			document.querySelector('.dropdown-menu.show').classList.remove("show");
			this.$emit('deleteEvent',id,index);
		},
    async getPreview(id) {
      //console.log("getPreview " + id);
      try {
        this.$root.dataManager.getCameraPreviewBase64(id);
        //this.previewText="";
      }
      catch (error) {
        console.log(error);
      }
    },
    async updateRecordingProtection(id,locked) {
			document.querySelector('.dropdown-menu.show').classList.remove("show");
      console.log("updateRecordingProtection");
      try {
        await this.$root.dataManager.modifyRecording(id,locked);
      } catch (error) {
        console.log("updateRecordingProtection  ERROR", error);
      }
    },

		openCentered(url, parent, width, height) {
			const y = parent.top.outerHeight / 2 + parent.top.screenY - ( height / 2);
			const x = parent.top.outerWidth / 2 + parent.top.screenX - ( width / 2);
      var w=window.open(
        url,
        "",
        "height=" + height + ",width=" + width + ",status=yes,toolbar=no,menubar=no,location=no,scrollbars=0,resizable=1,top=" + y + ", left=" + x
      );
			w.desiredWidth=width;
			w.desiredHeight=height;	
			return w;	
		},
   viewRecording(id,name,rectime) {
      console.log("viewRecording recid:",id,name);
      let url = "view_recording.html?recid=" + id + "&camname=" + name;
      if (rectime == ""){
        url +=  "&starttime=0";
      }
      else{
        url += "&starttime=" + rectime;
      }
      if(this.showRecordingsPriv) {
       //window.open(url, "", "height=240,width=320,status=yes,toolbar=no,menubar=no,location=no,scrollbars=0,resizable=1");
				var newWindow=this.openCentered(url,window,480,270);
				newWindow.parent=window;
      }
    },
    imgRecording(){
        return "/img/rectype" + this.type + ".png";
    },


  },

};

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.tg_vista img {
  border: 1px solid var(--separator-color);
}
</style>