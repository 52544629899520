<template>
  <div class="cam_vista_min">
    <div>
      <!--<img class="cam_vista" :src="previewURL" alt="" />-->
			<div class="cam_preview">
				<img v-if="(preview.textOrImage==2)"      
          :src="preview.previewImg"
          alt=""
        />
        <span class="preview_msg" v-show="(preview.textOrImage==1)" v-html="preview.previewText"></span>
			</div>
      <div class="c_conf">
        <div class="txt_cam">
          <h3 class="text-truncate">{{ name }}</h3>
          <p class="text-truncate">{{ model }}</p>
        </div>
        <div class="sw_cam">
          <input
            type="checkbox"
            name="swCamSwitch"
            :id="swCam"
            @click="swCamChange"
            class="switch_input"
            v-model="swCamChecked"
            readonly
          />
          <label :for="swCam" class="switch_label"></label>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
//const axios = require("axios").default;
//axios.defaults.withCredentials = false;
//const sleep = (ms) => new Promise((r) => setTimeout(r, ms));
export default {
  name: "CameraSwitch",
  props: {
    index: Number,
    id: Number,
    name: {
      type: String,
      required: true,
      default: "",
    },
    model: {
      type: String,
      default: "",
    },
    initialSelected:{
      type: Boolean,
      default:false
    }
  },
  data() {
    return {
      //previewURL: "",
      loaded: false,
      error: false,
      swCamChecked: false,
			previewText: "",
    };
  },
  created() {
    console.log("TaskCamItem created");
    this.getPreview(this.id);
    this.swCamChecked = this.initialSelected;
  },
  computed: {
    swCam() {
      return "swCam-" + this.id;
    },
    preview() {
      //console.log("compute preview");
      let pr={};
      pr.textOrImage=0;
      pr.previewText="";
      pr.previewImg="";
      if (this.previewText!="") {
        pr.textOrImage=1;
        pr.previewText=this.previewText;
        pr.previewImg="";
        return pr;
      }
      if (this.$root.dataManager.camPreviews["cam" + this.id]===undefined)
      {
        pr.previewText="";
        pr.previewImg="pending";
        pr.textOrImage=0;
        return pr;
      }
      if (this.$root.dataManager.camPreviews["cam" + this.id]==="downloading")
      {
        pr.previewText="";
        pr.previewImg="";
        pr.textOrImage=0;
        return pr;
      }
      if (this.$root.dataManager.camPreviews["cam" + this.id]==="unavailable")
      {
        pr.previewText=this.$t('cameras.no_preview_available') + "<br>" + this.$t('cameras.select_update_preview');
        pr.textOrImage=1;
        return pr;
      }
      pr.previewText="";
      pr.previewImg=this.$root.dataManager.camPreviews["cam" + this.id];
      pr.textOrImage=2;
      return pr;
    },
  },
  watch: {
    preview() {
      if (this.preview.previewImg=="pending") {
        this.getPreview(this.id);
      }
      
    }
  },
  methods: {
    swCamChange(){
      console.log("switch camera changed",this.swCamChecked);

    },

    async getPreview(id) {
      console.log("getPreview");
      try {
        await this.$root.dataManager.getCameraPreviewBase64(id);
        this.previewText="";
      }
      catch (error) {
        console.log(error);
      }
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>


.cam_vista_min h3 {
  /*font-size: 14px;
  font-weight: 800;
  text-align: left;
  margin-bottom: 2px;*/
  max-width: 150px;
}

.cam_vista_min p {
  /*font-size: 12px;
  color: #7886a3;
  text-align: left;
  margin-bottom: 0px;*/
  max-width: 150px;
}

.cam_preview .preview_msg {
		font-size: 13px;
	}
</style>