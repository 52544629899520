<template>
  <menu-main> </menu-main>

  <div id="camaras">
    <header-main></header-main>
    <div id="main_body" class="cuerpo_principal">
      <div class="mosaico_cam">
        <div class="main_content">
          <div class="grid_busc">
            <form class="buscador">
              <input
                id="search"
                type="search"
                v-model="search"
                :placeholder="$t('common.search')"
              />
              <!--<button><i class="fa fa-search" aria-hidden="true"></i></button>-->
              <span class="material-icons refresh-button"
                @click="refresh">refresh</span>
            </form>
          </div>
          <p
            v-if="this.$root.dataManager.camerasLoaded && !this.$root.dataManager.camerasWithLabel.length && this.$root.dataManager.selectedLabelId == -1"
            class="no_data_msg"
          >
            {{ $t('cameras.no_cameras') }}  
          </p>
          <p
            v-if="this.$root.dataManager.camerasWithLabelLoaded && !this.$root.dataManager.camerasWithLabel.length && this.$root.dataManager.selectedLabelId != -1"
            class="no_data_msg"
          >
            {{ $t('cameras.no_cameras_with_label') }}
          </p>
          <div class="grid_cam">
            <div v-for="(camera, index) in filterCameras" :key="camera.id">
              <camera-item
                ref="camItem"
                :index="index"
                :id="camera.id"
                :name="camera.name"
                :model="camera.model"
                :connStatus="camera.connStatus"
                :wifi="camera.wifi"
                @delete-event="showDeleteConfirmation"
                @editEvent="editCamera"
                @editWifi="editWifi"
              >
              </camera-item>
            </div>
          </div>
        </div>
        <!--<div id="btnAgregar">-->
        <button
          v-show="addCamerasPriv"
          @click="addCamera"
          class="agregar_opcion"
        >
          <span class="material-icons">add</span>

          <!--<strong>Añadir<br />Cámara</strong>-->
          <!--<i class="fa fa-plus"/>-->
        </button>
        <!--</div>-->
      </div>
    </div>
  </div>

  <menu-footer active-state="cameras"></menu-footer>
</template>

<script>
import CameraItem from "./CameraItem.vue";
//import DataManager from "../../assets/custom/js/DataManager.js";
//import mqtt from "mqtt/dist/mqtt";
//var clientMQTT;
//const axios = require("axios").default;
//axios.defaults.withCredentials = false;
const Swal = require("sweetalert2");

export default {
  name: "CamerasMain",
  components: {
    "camera-item": CameraItem,
  },
  data() {
    return {
      sessionId: "",
      //cameras: [],
      summary: [],
      search: "",
      error: false,
      //loaded: false,
      addCamerasPriv: false,
      editCamerasPriv: false,
      deleteCamerasPriv: false,
      topic: "",
    };
  },
  computed: {
    filterCameras() {
      const filter1 = this.filterCamerasByName();
      const filter2 = this.filterCamerasByModel();
      if (filter1 || filter2) {
        return [...new Set([...filter1, ...filter2])];
      }
      return this.$root.dataManager.camerasWithLabel;
    },
  },
  methods: {
    async refresh() {
      console.log("refresh");
      //this.$root.dataManager.getCameraPreviewBase64(1,true);

      // const userInfo = JSON.parse(sessionStorage.getItem("userInfo"));
      // for (const cam of this.$root.dataManager.cameras) {
      //   sessionStorage.removeItem("previewCam" + cam.id + ":" + userInfo.sessionId);
      // }
      this.$root.dataManager.deleteAllCameraPreviews();
      this.$root.dataManager.loadCameras(true);
      if (this.$root.dataManager.selectedLabelId!=-1)
        this.$root.dataManager.loadCamerasWithLabel(true);
      this.$root.dataManager.loadLabels(true);
    },
    filterCamerasByName() {
      return this.search
        ? this.$root.dataManager.camerasWithLabel.filter((camera) =>
            camera.name.toLowerCase().includes(this.search.toLowerCase())
          )
        : null;
    },
    filterCamerasByModel() {
      return this.search
        ? this.$root.dataManager.camerasWithLabel.filter((camera) =>
            camera.model.toLowerCase().includes(this.search.toLowerCase())
          )
        : null;
    },
    showDeleteConfirmation(id, index) {
      console.log("showDeleteConfirmation:", id, index);
      Swal.fire({
        /*title: this.recordings[index].camName,*/
        text: this.$t('cameras.cam_del_confirmation',{camname: this.$root.dataManager.camerasWithLabel[index].name}),
        icon: "question",
        showCancelButton: true,
        confirmButtonText: this.$t('common.ok'),
        cancelButtonText: this.$t('common.cancel'),
        //confirmButtonColor: getComputedStyle(document.documentElement).getPropertyValue('--main-accent-color') /*"#61aaea"*/,
        //cancelButtonColor: getComputedStyle(document.documentElement).getPropertyValue('--main-inactive-color') /*"#c0c0c0"*/,
        position: "center",
      }).then((result) => {
        if (result.isConfirmed) {
          console.log("Delete:", id, index);
          this.deleteCamera(id/*, index*/);
        }
      });
    },
    
    async deleteCamera(id/*, index*/) {
      if (!this.deleteCamerasPriv) {
        console.log("sin privilegios para borrar cámaras");
        return;
      }
      try {
        await this.$root.dataManager.deleteCamera(id);
      }
      catch (error) {
        console.log("camera delete ERROR", error);
      }

    },
    async editWifi(id, index) {
      console.log("editWifi:", id, index);
      if (this.editCamerasPriv) {
        this.$router.push({ name: "CameraWifi", params: { editId: id } });
      }
    },
    async editCamera(id, index) {
      console.log("edit camera:", id, index);
      if (this.editCamerasPriv) {
        this.$router.push({ name: "CameraEdit", params: { editId: id } });
      }
    },

    
    addCamera() {
      console.log("add camera");
      
      const license = this.$root.dataManager.totalLicensesLeft; // sessionStorage.getItem("userLicense");
      if (license > 0) {
        this.$router.push({ name: "CameraAdd" });
      } else {
        Swal.fire({
        /*title: this.recordings[index].camName,*/
        text: this.$t('cameras.no_licenses_for_new_camera'),
        icon: "question",
        showCancelButton: false,
        confirmButtonText: this.$t('common.ok'),
        //cancelButtonText: this.$t('common.cancel'),
        //confirmButtonColor: getComputedStyle(document.documentElement).getPropertyValue('--main-accent-color') /*"#61aaea"*/,
        //cancelButtonColor: getComputedStyle(document.documentElement).getPropertyValue('--main-inactive-color') /*"#c0c0c0"*/,
        position: "center",
      }).then((result) => {
        console.log(result);
      });
        //alert("No dispone de licencias para añadir una nueva cámara.");
      }
    },
  },
  unmounted() {
    console.log("unmounted CamerasMain");
  },
  mounted() {
    document.documentElement.style.scrollBehavior = 'auto';
    console.log("CamerasMain");
    document.getElementById("search").focus();
    document.getElementById("search").blur();
    document.title = this.$t('common.cameras');
    //const userInfo = JSON.parse(sessionStorage.getItem("userInfo"));
    if (!this.$root.sessionManager.isLoggedIn()) {
      console.log("router to signin");
      this.$router.push({ name: "SignIn" });
    }
    console.log("CamerasMain, sessionid:", this.$root.sessionManager.sessionId);
    this.sessionId = this.$root.sessionManager.sessionId;
    const userPriv = this.$root.sessionManager.privileges;
    console.log("UsersMain, userPriv:", userPriv);
    this.addCamerasPriv = userPriv["addCameras"];
    this.editCamerasPriv = userPriv["editCameras"];
    this.deleteCamerasPriv = userPriv["deleteCameras"];
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
/*.swal2-popup {
  font-size: 0.6rem !important;
}*/
</style>