<template>
  <menu-main></menu-main>
  <div id="groupAdd">
    <header-main> </header-main>
    <div v-show="editGroupsPriv" class="container cuerpo_principal">
      <div class="row">
        <div class="col-12 offset-0 col-sm-10 offset-sm-1 col-md-8 offset-md-2 col-md-8 offset-md-2 col-lg-6 offset-lg-3 visor_box">
          <div class="alert alert-custom" id="msg-alert">
            <p>{{ errorMessage }}</p>
          </div>
          <ul class="nav nav-tabs nav-fill" id="myTab" role="tablist">
            <li class="nav-item" role="presentation">
              <button
                class="nav-link active"
                id="configuracion-tab"
                data-bs-toggle="tab"
                data-bs-target="#configuracion"
                type="button"
                role="tab"
                aria-controls="configuracion"
                aria-selected="true"
              >
                {{ $t('common.configuration') }}
              </button>
            </li>
            <li class="nav-item" role="presentation">
              <button
                class="nav-link"
                id="camaras-tab"
                data-bs-toggle="tab"
                data-bs-target="#camarasGrupos"
                type="button"
                role="tab"
                aria-controls="camarasGrupos"
                aria-selected="false"
              >
              {{ $t('common.cameras') }}
              </button>
            </li>
          </ul>
					<button
              @click="closeWindow"
              type="button"
              class="close_props material-icons"
              style="float: right"
              aria-label="Close"
            >close</button>
          <div class="tab-content" id="npContenido">
            <div
              class="tab-pane fade show active"
              id="configuracion"
              role="tabpanel"
              aria-labelledby="configuracion-tab"
            >
              <div class="conf_box">
                <div class="nom_interv">
                  <div class="nom_input">
                    <p>{{ $t('common.name') }}</p>
                    <input class="text_input"
                      id="group_name"
                      type="text"
                      :placeholder="$t('groups.group_name')"
                      v-model="name"
                    />
                  </div>
                </div>
                <div class="nom_interv">
                  <div class="intervalo">
                    <p>{{ $t('groups.interval') }}</p>
                    <div class="conf_intervalo">
                      <input class="text_input" style="width: 35%"
                        type="number"
                        min="1"
                        placeholder="20"
                        v-model="interval"
                      />
                      <select name="tiempo" v-model="unit" class="dropdown_select" style="width: 60%; margin-left: 5%; padding-left:5px;padding-right:5px;">
                        <option value="S">{{ $t('common.seconds') }}</option>
                        <option value="M">{{ $t('common.minutes') }}</option>
                        <option value="H">{{ $t('common.hours') }}</option>
                      </select>
                    </div>
                  </div>
                </div>
                <div class="geo_conf">
                  <h5>{{ $t('groups.geometry') }}</h5>
                  <div class="plantilla">
                    <div class="box_pl">
                      <label>
                        <img src="../../assets/custom/img/1x1.png" alt="" />
                        <p>
                          <input
														class="form-check-input"
                            type="radio"
                            name="geometry"
                            value="1x1"
                            v-model="geometry"
                          /><span>1x1</span>
                        </p>
                      </label>
                    </div>
                    <div class="box_pl">
                      <label>
                        <img src="../../assets/custom/img/2x2.png" alt="" />
                        <p>
                          <input
														class="form-check-input"
                            type="radio"
                            name="geometry"
                            value="2x2"
                            v-model="geometry"
                          /><span>2x2</span>
                        </p>
                      </label>
                    </div>
                    <div class="box_pl">
                      <label>
                        <img src="../../assets/custom/img/3x3.png" alt="" />
                        <p>
                          <input
														class="form-check-input"
                            type="radio"
                            name="geometry"
                            value="3x3"
                            v-model="geometry"
                          /><span>3x3</span>
                        </p>
                      </label>
                    </div>
                    <div class="box_pl">
                      <label>
                        <img src="../../assets/custom/img/4x4.png" alt="" />
                        <p>
                          <input
														class="form-check-input"
                            type="radio"
                            name="geometry"
                            value="4x4"
                            v-model="geometry"
                          /><span>4x4</span>
                        </p>
                      </label>
                    </div>
                    <div class="box_pl">
                      <label>
                        <img src="../../assets/custom/img/5plus1.png" alt="" />
                        <p>
                          <input
														class="form-check-input"
                            type="radio"
                            name="geometry"
                            value="5+1"
                            v-model="geometry"
                          /><span>5+1</span>
                        </p>
                      </label>
                    </div>
                    <div class="box_pl">
                      <label>
                        <img src="../../assets/custom/img/2plus8.png" alt="" />
                        <p>
                          <input
														class="form-check-input"
                            type="radio"
                            name="geometry"
                            value="2+8"
                            v-model="geometry"
                          /><span>2+8</span>
                        </p>
                      </label>
                    </div>
                    <div class="box_pl">
                      <label>
                        <img src="../../assets/custom/img/3plus4.png" alt="" />
                        <p>
                          <input
														class="form-check-input"
                            type="radio"
                            name="geometry"
                            value="3+4"
                            v-model="geometry"
                          /><span>3+4</span>
                        </p>
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              class="tab-pane fade"
              id="camarasGrupos"
              role="tabpanel"
              aria-labelledby="camarasGrupos-tab"
            >
              <div class="conf_camaras">
                <div class="cam_layout">
                  <div
                    v-for="(camera, index) in this.$root.dataManager.cameras"
                    :key="camera.id"
                  >
                    <camera-switch
                      :index="index"
                      :id="camera.id"
                      :name="camera.name"
                      :model="camera.model"
                    ></camera-switch>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="btn_guardar">
            <button @click.prevent="save">
              <a href="#">{{ $t('common.save') }}</a>
              <div
                v-if="saving"
                class="spinner-border spinner-border-sm"
                role="status"
              >
                <span class="sr-only">Saving...</span>
              </div>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <menu-footer active-state="groups"></menu-footer>
</template>

<script>
import CameraSwitch from "../Cameras/CameraSwitch.vue";
import $ from "jquery";

//const axios = require("axios").default;
//axios.defaults.withCredentials = false;

export default {
  name: "GroupAdd",
  components: {
    CameraSwitch,
  },
  data() {
    return {
      sessionId: "",
      name: "",
      interval: 20,
      unit: "S",
      geometry: "1x1",
      //allAvailableCameras: [],
      selectedCameras: [],
      //error: false,
      errorMessage: "",
      //loaded: false,
      //loading: false,
      saving: false,
      editGroupsPriv: false,
      isInformationActive: true,
      isConnectionActive: false,
    };
  },
  methods: {
    showMsgAlert() {
      $("#msg-alert")
        .fadeTo(200, .80, function() {
					setTimeout(function() {
						$("#msg-alert").fadeTo(200,0);
						},2000)
				});
//        .fadeTo(500,0)/*slideUp(500, function () {
//          $("#msg-alert").slideUp(500);
//        })*/;
    },
    closeWindow() {
      console.log("closeWindow");
      //this.$router.push({ name: "GroupsMain" });
      this.$router.go(-1);
    },
    verifyData() {
      if (this.name === "") {
        this.errorMessage = this.$t('groups.name_required');
        //this.error = true;
        return false;
      }
      if (this.selectedCameras.length === 0) {
        this.errorMessage = this.$t('groups.at_least_one_camera');
        //this.error = true;
        return false;
      }
      return true;
    },
    save() {
      console.log(
        "save group",
        this.name,
        this.interval,
        this.unit,
        this.geometry
      );
      //this.error = false;
      this.errorMessage = "";
      this.selectedCameras = $("input:checkbox[name=swCamSwitch]:checked")
        .map(function () {
          console.log("Id: " + $(this).attr("id") + " Value: " + $(this).val());
          return $(this).attr("id").split("-")[1];
        })
        .get();
      console.log("save group selected cameras", this.selectedCameras);
      if (this.verifyData()) {
        this.saveGroup();
      } else {
        this.showMsgAlert();
      }
    },

    async saveGroup() {
      this.saving = true;
      
      console.log("saving group");
      try {
        const response = await this.$root.dataManager.addGroup(this.name,this.geometry,this.interval + this.unit);
        const groupId = response.groupId;
        console.log("save group OK", groupId);
        //const data = { this.selectedCameras.toString();
        await this.$root.dataManager.addGroupCameras(groupId,this.selectedCameras.toString());
        await this.$root.dataManager.updateGroupPreview(groupId);
        this.saving = false;
        this.$router.push({ name: "GroupsMain" });
      }
      catch (error) {
        console.log("saveGroup error");
        console.error(error);
        //this.error = true;
        this.saving = false;
        this.errorMessage = this.$t('groups.error_saving_group');
        this.showMsgAlert();
      }

      
    },

    
  },
  created() {
    console.log(this.$root.dataManager);
  },
  mounted() {
    console.log("GroupAdd");
    document.getElementById("group_name").focus();
    document.getElementById("group_name").blur();
    document.title = this.$t('groups.new_group');
    $("#msg-alert").hide();
    //const userInfo = JSON.parse(sessionStorage.getItem("userInfo"));
    if (!this.$root.sessionManager.isLoggedIn()) {
      console.log("router to signin");
      this.$router.push({ name: "SignIn" });
    }
    console.log("GroupAdd, sessionid:", this.$root.sessionManager.sessionId);
    this.sessionId = this.$root.sessionManager.sessionId;
    const userPriv = this.$root.sessionManager.privileges;
    console.log("UsersMain, userPriv:", userPriv);
    this.editGroupsPriv = userPriv["editGroups"];
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>