
<template>

<router-view> </router-view>
<!--class="notifications-tray"-->
<notifications width="350px" height="100px" position="bottom right" class="notifications-tray">
  <template #body="props">
    <div @click="props.close" class="vue-notification-template vue-notification ipn-notification">
      <div class="ipn-notification-icon material-icons">{{ props.item.data.icon }}</div>
      <div class="ipn-notification-date">{{ props.item.data.date }}</div>
      <div class="notification-title ipn-notification-title">{{ props.item.title}}</div>
      <div class="notification-content ipn-notification-content">{{ props.item.text }}</div>
    </div>
  </template>
</notifications>
</template>

<script>
import DataManager from "./assets/custom/js/DataManager.js";
import SessionManager from "./assets/custom/js/SessionManager.js";

const axios = require("axios").default;
axios.defaults.withCredentials = false;
export default {
  name: 'App',
  data() { return {
    sessionManager: new SessionManager(this.APIServer),
    dataManager: new DataManager(),
    labelId: -1,
    }
  },
  mounted() {
    console.log("App mounted");
  },
  unmounted() {
    console.log("App unmounted");
  }
}
</script>

<style scoped>
.ipn-notification {
  background-color: var(--card-background-color);
  border-left-color: var(--main-accent-color);
  position: relative;
  height: 70px;
}
.ipn-notification-title {
  position: absolute;
  left: 64px;
  /*right: 4px;*/
  width: 200px;
  top: 10px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow:hidden;
  font-weight: 500;
  color: var(--th-text-color);
  font-size: 1.1em;
  /*background-color: red;*/
}

.ipn-notification-date {
  position: absolute;
  width: 52px;
  right: 12px;
  top: 8px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow:hidden;
  color: var(--main-text-color);
  font-size: 1em;
  text-align: right;
  /*background-color: cyan;*/
}

.ipn-notification-content {
  position: absolute;
  left: 64px;
  bottom: 12px;
  right: 4px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow:hidden;
  color: var(--main-text-color);

}

.ipn-notification-icon {
  position:absolute;
  top:19px;
  height: 32px;
  font-size: 32px;
  line-height: 1;
  left: 16px;
  color: var(--th-text-color);
}
</style>
