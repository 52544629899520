<template>
  <div class="cam_vista_min">
    <div>
      <!--<img class="cam_vista" :src="previewURL" alt="" />-->
      <div class="c_conf">
        <div class="txt_cam">
          <h3 class="text-truncate">{{ name }}</h3>
        </div>
        <div class="sw_cam">
          <input
            type="checkbox"
            name="swLabelSwitch"
            :id="swLabel"
            @click="swLabelChange"
            class="switch_input"
            v-model="swLabelChecked"
            readonly
          />
          <label :for="swLabel" class="switch_label"></label>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
//const axios = require("axios").default;
//axios.defaults.withCredentials = false;
//const sleep = (ms) => new Promise((r) => setTimeout(r, ms));
export default {
  name: "LabelSwitch",
  props: {
    index: Number,
    id: Number,
    name: {
      type: String,
      required: true,
      default: "",
    },
    initialSelected:{
      type: Boolean,
      default:false
    }
  },
  data() {
    return {
      loaded: false,
      //error: false,
      swLabelChecked: false,
    };
  },
  created() {
    console.log("LabelSwitchItem created");
    this.swLabelChecked = this.initialSelected;
  },
  computed: {
    swLabel() {
      return "swLabel-" + this.id;
    },
  },
  methods: {
    swLabelChange(){
      console.log("switch label changed",this.swLabelChecked);

    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

.cam_vista_min {
  padding:10px;
}
.cam_vista_min h3 {
  /*font-size: 14px;
  font-weight: 800;
  text-align: left;
  margin-bottom: 2px;*/
  max-width: 135px;
}

.cam_layout .c_conf .txt_cam {
    padding-top: 1px;
}

.cam_layout .c_conf .sw_cam {
    padding-top: 0px;
}

.cam_preview .preview_msg {
		font-size: 13px;
}
</style>