// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
//import { getAnalytics } from "firebase/analytics";
import { getAuth, signInWithCustomToken } from "firebase/auth";

import { getFirestore as fs_getFirestore, collection, doc, getDoc, updateDoc, deleteDoc, query, orderBy, onSnapshot, writeBatch } from "firebase/firestore";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional

var firebase=null;
var firestore=null;

function initialize () {
    if (firebase!=null) return;
    const firebaseConfig = {
    apiKey: "AIzaSyDryY_NeD5yiIMUy4xv8Gk-BQ5RsNEVWJY",
    authDomain: "ipronet-vsaas.firebaseapp.com",
    databaseURL: "https://ipronet-vsaas.firebaseio.com",
    projectId: "ipronet-vsaas",
    storageBucket: "ipronet-vsaas.appspot.com",
    messagingSenderId: "612445470389",
    appId: "1:612445470389:web:5b1565941b4028082608fc",
    //measurementId: "G-V5KK2G5E7M"
    };
    // Initialize Firebase
    firebase = initializeApp(firebaseConfig);
    //const analytics = getAnalytics(app);
}

async function signIn(token) {
    const auth = getAuth();
    try {
        const userCredential = await signInWithCustomToken(auth, token);
        const user = userCredential.user;
        console.log("Login OK", user);
    }
    catch(error) {
        const errorCode = error.code;
        const errorMessage = error.message;
        console.log(errorMessage);
        console.log(errorCode);
        // ...
    }
}

function getFirestore () {
    if (firestore!=null) return firestore;
    firestore = fs_getFirestore(firebase);    
    return firestore;
}

export {initialize,signIn,getFirestore,collection,doc,getDoc,updateDoc,deleteDoc,query,orderBy,onSnapshot,writeBatch};