<template>
<tr v-on:click="notificationMainAction()">
  <th scope="row">
    <!--<div class="tg_vista">-->
		<span class="material-icons" style="font-size: 24px;line-height:1;vertical-align:middle">{{icon}}</span>
    <!--</div>-->
  </th>
  <td :class="{ unread: pending, read: !pending }">{{ text }}</td>
  
  <td :class="{ unread: pending, read: !pending }">{{ parsedDate }}</td>
  <td style="text-align:right">
    <span v-on:click.stop="notificationSecAction()" v-show="eventType==200 || eventType==400" class="material-icons sec-action" :class="{ unread: pending, read: !pending }" style="font-size: 24px;line-height:1;vertical-align:middle; cursor:pointer">visibility</span>
    <div class="btn-group dropstart">
      <button v-on:click.stop
        type="button"
        class="btn"
        data-bs-toggle="dropdown"
        aria-expanded="false"
				ref="dropdownbtn"
      >
        <span
          ><svg
            id="Modo_de_aislamiento"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 1.78 10.75"
          >
            <circle
              class="cls-1"
              cx=".89"
              cy="9.86"
              r=".89"
              transform="translate(-2.23 .46) rotate(-13.2)"
            />
            <circle
              class="cls-1"
              cx=".89"
              cy="5.37"
              r=".89"
              transform="translate(-1.2 .35) rotate(-13.2)"
            />
            <circle
              class="cls-1"
              cx=".89"
              cy=".89"
              r=".89"
              transform="translate(-.18 .23) rotate(-13.2)"
            /></svg></span>
      </button>
      <ul class="dropdown-menu list-dropdown">
        <li @click="deleteNotification(docId)">
          <a>
						<i class="fa fa-trash" aria-hidden="true"></i> {{ $t('common.delete') }}</a>
        </li>
      </ul>
    </div>
  </td>
</tr>
</template>

<script>
//const axios = require("axios").default;
//axios.defaults.withCredentials = false;

export default {
  name: "NotificationItem",
  emits: ["deleteEvent", "processedEvent"],
  props: {
    index: Number,
    eventType: {
      type: Number,
      required: true,
      default: 0,
    },
    date: {
      type: String,
      required: true,
      default: "",
    },
    parsedDate: {
      type: String,
      required: true,
      default: "",
    },
    id: {
      type: Number,
      required: true,
      default: -1,
    },
    secId: {
      type: Number,
      required: true,
      default: -1,
    },
    pending: {
      type: Boolean,
      required: true,
      default: true,
    },
    message: {
      type: String,
      default: "",
    },
    text: {
      type: String,
      default: "",
    },
    docId: {
      type: String,
      required: true,
      default: "",
    },
  },
  data() {
    return {
      loaded: false,
      error: false,
      showRecordingsPriv:false,
      showCamerasPriv:false,
      //id: -1,
      icon: "",
    };
  },
  computed: {
    
  },
  mounted() {
    //this.id = this.paramId;
    //console.log("LabelItem mounted",this.id);
		/*this.$refs.dropdownbtn.addEventListener('show.bs.dropdown', function() {
			var oldMenu=document.querySelector('.dropdown-menu.show');
			if (oldMenu!=null) oldMenu.classList.remove("show");
		});*/
    const userPriv = this.$root.sessionManager.privileges;
    this.showRecordingsPriv = userPriv['showRecordings'];
    this.showCamerasPriv = userPriv['viewCameras'];

		this.$refs.dropdownbtn.addEventListener('show.bs.dropdown', function(e) {
			//console.log("Drop down");
			//console.log(e);
			var oldMenu=document.querySelector('.dropdown-menu.show');
			if (oldMenu!=null) oldMenu.classList.remove("show");
			var dropdownMenu = e.target.parentElement.querySelector(".dropdown-menu");
			if (dropdownMenu===null) return;
			var rect = dropdownMenu.getBoundingClientRect();
			dropdownMenu.style={
				position: 'absolute',
				left: rect.left,
				top: rect.top
			};
			e.target.myMenu=dropdownMenu;
			dropdownMenu.parentElement.removeChild(dropdownMenu);
			document.body.append(dropdownMenu);
		});
		this.$refs.dropdownbtn.addEventListener('hidden.bs.dropdown', function(e) {
			//console.log("Hide drop down");	
			//console.log(e);
			if (e.target.myMenu===undefined) return;
			var dropdownMenu = e.target.myMenu;
			dropdownMenu.style={
				position: false,
				left: false,
				top: false
			};
			document.body.removeChild(dropdownMenu);
			e.target.parentElement.append(dropdownMenu);
		});
    switch (this.eventType) {
      case 100:
        this.icon="cloud_off";
        break;
      case 101:
        this.icon="cloud_done";
        break;
      case 200:
        this.icon="directions_run";
        break;
      case 300:
        this.icon="notifications_off";
        break;
      case 301:
        this.icon="notifications_active";
        break;
      case 400:
        this.icon="volume_up";
        break;
      case 500:
        this.icon="error_outline";
        break;
      
        
      
    }

  },
  methods: {
		// editLabel(id,index) {
		// 	document.querySelector('.dropdown-menu.show').classList.remove("show");
		// 	this.$emit('editEvent',id,index);
		// },
		deleteNotification(id,index) {
			document.querySelector('.dropdown-menu.show').classList.remove("show");
			this.$emit('deleteEvent',id,index);
		},
    notificationMainAction() {
      console.log("Main Action");
      this.$emit('processedEvent',this.docId);
      switch (this.eventType) {
        case 200:
        case 400:
        case 500:
            this.viewRecording(this.secId,this.text,this.date);
          break;
      }
    },
    notificationSecAction() {
      console.log("Sec Action");
      switch (this.eventType) {
        case 200:
        case 400:
        case 500:
            this.viewCamera(this.id,this.text,this.date);
          break;
      }
    },
    openCentered(url, parent, width, height) {
      const y = parent.top.outerHeight / 2 + parent.top.screenY - ( height / 2);
      const x = parent.top.outerWidth / 2 + parent.top.screenX - ( width / 2);
      var w=window.open(
        url,
        "",
        "height=" + height + ",width=" + width + ",status=yes,toolbar=no,menubar=no,location=no,scrollbars=0,resizable=1,top=" + y + ", left=" + x
      );
      w.desiredWidth=width;
      w.desiredHeight=height;	
      return w;	
    },
    viewRecording(id,name,rectime) {
      console.log("viewRecording recid:",id,name);
      let url = "view_recording.html?recid=" + id + "&camname=" + name + "&starttime=" + rectime;
      
      if(this.showRecordingsPriv) {
      //window.open(url, "", "height=240,width=320,status=yes,toolbar=no,menubar=no,location=no,scrollbars=0,resizable=1");
        var newWindow=this.openCentered(url,window,480,270);
        newWindow.parent=window;
      }
    },
    viewCamera(id,name) {
      console.log("viewCamera camid:",id,name);
      let url = "view_camera.html?camid=" + id + "&camname=" + name;
      
      if(this.showCamerasPriv) {
      //window.open(url, "", "height=240,width=320,status=yes,toolbar=no,menubar=no,location=no,scrollbars=0,resizable=1");
        var newWindow=this.openCentered(url,window,480,270);
        newWindow.parent=window;
      }
    },
  },
  
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.unread {
  color: var(--th-text-color)!important;
  font-weight: 500!important;
}
.read {
  font-weight: normal!important;
}
.sec-action {
  margin-right: 10px;
}
</style>