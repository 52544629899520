<template>
<tr>
  <th scope="row">
    <div class="progress">
      <div
        class="progress-bar"
        :style="progress_style"
        role="progressbar"
        aria-valuenow="64"
        aria-valuemin="0"
        aria-valuemax="100"
      >
        <span>{{ this.progress }}%</span>
      </div>
    </div>
  </th>
  <td>{{ name }}</td>
  <td>{{ date }}</td>
  <td>
    <span> {{ $t('common.start') }}: {{ start }}</span>
    <span> / {{ $t('common.start') }}: {{ end }}</span>
  </td>
  <td style="text-align:right">
    <!-- Default dropstart button -->
    <div class="btn-group dropstart">
      <button
        type="button"
        class="btn"
        data-bs-toggle="dropdown"
        aria-expanded="false"
				ref="dropdownbtn"
      >
        <span
          ><svg
            id="Modo_de_aislamiento"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 1.78 10.75"
          >
            <circle
              class="cls-1"
              cx=".89"
              cy="9.86"
              r=".89"
              transform="translate(-2.23 .46) rotate(-13.2)"
            />
            <circle
              class="cls-1"
              cx=".89"
              cy="5.37"
              r=".89"
              transform="translate(-1.2 .35) rotate(-13.2)"
            />
            <circle
              class="cls-1"
              cx=".89"
              cy=".89"
              r=".89"
              transform="translate(-.18 .23) rotate(-13.2)"
            /></svg></span>
      </button>
      <ul class="dropdown-menu list-dropdown">
        <li
          v-show="this.progress == 100"
          @click="$emit('deleteEvent', formKey, index)"
        >
          <a><i class="fa fa-trash" aria-hidden="true"></i> {{ $t('common.delete') }}</a>
        </li>
        <li v-show="this.progress == 100">
          <a
            :href = "url"
            ><i class="fa fa-download" aria-hidden="true"></i> {{ $t('exports.download') }}</a
          >
        </li>

        <li
          v-show="this.progress != 100"
          @click="cancelRecExport(formKey, index)"
        >
          <a><i class="fa fa-cancel" aria-hidden="true"></i> {{ $t('common.cancel') }}</a>
        </li>
      </ul>
    </div>
  </td>
</tr>
</template>

<script>
//const axios = require("axios").default;
//axios.defaults.withCredentials = false;
const sleep = (ms) => new Promise((r) => setTimeout(r, ms));

export default {
  name: "ExportItem",
  emits: ["deleteEvent", "downloadEvent", "cancelEvent"],
  props: {
    index: Number,
    formKey: {
      type: String,
      required: true,
      default: "",
    },
    name: {
      type: String,
      required: true,
      default: "",
    },
    date: {
      type: String,
      required: true,
      default: "-",
    },
    start: {
      type: String,
      required: true,
      default: "00:00",
    },
    end: {
      type: String,
      required: true,
      default: "23:59",
    },
    label: {
      type: String,
      required: true,
      default: "",
    },
    initProgress: {
      type: String,
      default: "",
    },
    status: {
      type: String,
      default: "",
    },
  },
  computed: {
    progress_style() {
      return "width: " + this.progress + "%";
    },
     url(){
      return this.$root.dataManager.getExportedRecordingURL(this.formKey);
    }
  },
  data() {
    return {
      loaded: false,
      error: false,
      progress: 0,
    };
  },
  mounted() {
    console.log("RecExportsItem mounted", this.formKey);
    this.progress = parseInt(this.initProgress);
    this.progressBarStatus(this.formKey);
		this.$refs.dropdownbtn.addEventListener('show.bs.dropdown', function(e) {
			var oldMenu=document.querySelector('.dropdown-menu.show');
			if (oldMenu!=null) oldMenu.classList.remove("show");
			var dropdownMenu = e.target.parentElement.querySelector(".dropdown-menu");
			if (dropdownMenu===null) return;
			//console.log(dropdownMenu);
			var rect = dropdownMenu.getBoundingClientRect();
			dropdownMenu.style={
				position: 'absolute',
				left: rect.left,
				top: rect.top
			};
			e.target.myMenu=dropdownMenu;
			dropdownMenu.parentElement.removeChild(dropdownMenu);
			document.body.append(dropdownMenu);
		});
		this.$refs.dropdownbtn.addEventListener('hidden.bs.dropdown', function(e) {
			if (e.target.myMenu===undefined) return;
			var dropdownMenu = e.target.myMenu;
			dropdownMenu.style={
				position: false,
				left: false,
				top: false
			};
			document.body.removeChild(dropdownMenu);
			e.target.parentElement.append(dropdownMenu);
		});
  },
  methods: {
    cancelRecExport(formKey, index) {
      this.cancel = true;
      this.$emit("cancelEvent", formKey, index);
    },
    async progressBarStatus(formKey) {
      while (this.progress != 100) {
        await sleep(400);
        if (this.cancel) {
          break;
        }
        this.getRecExport(formKey);
        await sleep(400);
      }
    },
    async getRecExport(formKey) {
      
      try {
        const response = await this.$root.dataManager.loadExportedRecording(formKey);
        this.progress = parseInt(response.export.progress);
      } catch (error) {
        console.log("getRecExport error");
        console.error(error);
      }
    },

  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>


</style>