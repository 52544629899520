import axios from 'axios';

export async function loadConfig() {
  try {
    const response = await axios.get('/config/config.json');
    return response.data;
  } catch (error) {
    console.error('Failed to load config file', error);
    return {};
  }
}
