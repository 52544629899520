<template>
  <menu-main></menu-main>
  <div id="taskAdd">
    <header-main> </header-main>

    <div class="cuerpo_principal">
      <div id="confProg" class="container">
        <div class="row">
          <div class="col-12 offset-0 col-sm-10 offset-sm-1 col-md-8 offset-md-2 col-md-8 offset-md-2 col-lg-6 offset-lg-3 visor_box">
            <div class="alert alert-custom" id="msg-alert">
              <p>{{ errorMessage }}</p>
            </div>
            <ul class="nav nav-tabs nav-fill" role="tablist">
              <li class="nav-item" role="presentation">
                <button
                  class="nav-link active"
                  id="informacion-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#informacion"
                  type="button"
                  role="tab"
                  aria-controls="informacion"
                  aria-selected="true"
                >
                  {{ $t('common.information') }}
                </button>
              </li>
              <li class="nav-item" role="presentation">
                <button
                  class="nav-link"
                  id="horario-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#horario"
                  type="button"
                  role="tab"
                  aria-controls="horario"
                  aria-selected="false"
                >
                {{ $t('tasks.timetable') }}
                </button>
              </li>
              <li class="nav-item" role="presentation">
                <button
                  class="nav-link"
                  id="camp-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#camp"
                  type="button"
                  role="tab"
                  aria-controls="camp"
                  aria-selected="false"
                >
                {{ $t('common.cameras') }}
                </button>
              </li>
              
            </ul>
						<button
              @click="closeWindow"
              type="button"
              class="close_props material-icons"
              style="float: right"
              aria-label="Close"
            >close</button>
            <div class="tab-content" id="npContenido">
              <div
                class="tab-pane fade show active"
                id="informacion"
                role="tabpanel"
                aria-labelledby="informacion-tab"
              >
                <div class="conf_box" style="width: 70%">
                  <div class="nom_input">
                    <p>{{ $t('common.name') }}</p>
                    <input class="text_input"
                      v-model="name"
                      id="task_name"
                      type="text"
                      :placeholder="$t('tasks.task_name')"
                    />
                  </div>

                  <div class="p_tipo">
                    <p>{{ $t('tasks.type') }}</p>
                    <div class="conf_tipo">
                      <select id="task_type" v-model="type" class="dropdown_select">
                        <option value="0">{{ $t('tasks.continuous') }}</option>
                        <option value="1">{{ $t('tasks.motion_detection') }}</option>
                      </select>
                    </div>
                  </div>

                  <div class="sw_prog">
                    <div class="sw_contenedor">
                      <p>{{ $t('tasks.motion_notification') }}</p>
                      <div class="sw_cam">
                        <input
                          type="checkbox"
                          id="swmotionNotify"
                          v-model="motionNotify"
                          class="switch_input"
                          readonly
                        />
                        <label
                          for="swmotionNotify"
                          class="switch_label"
                        ></label>
                      </div>
                    </div>
                    <div class="sw_contenedor">
                      <p>{{ $t('tasks.audio_notification') }}</p>
                      <div class="sw_cam">
                        <input
                          type="checkbox"
                          name="switch_cam"
                          id="swaudioNotify"
                          v-model="audioNotify"
                          class="switch_input"
                          readonly
                        />
                        <label for="swaudioNotify" class="switch_label"></label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div
                class="tab-pane fade"
                id="horario"
                role="tabpanel"
                aria-labelledby="horario-tab"
              >
                <div class="tab-content" id="hTabla">
                  <div class="conf_time">
                    <div class="p_day">
                      <p>{{ $t('tasks.day') }}</p>
                      <div class="conf_day">
                        <select name="task_day" v-model="wday" class="dropdown_select">
                          <option value="10">{{ $t('weekdays.all') }}</option>
                          <option value="8">{{ $t('weekdays.monday') }}-{{ $t('weekdays.friday') }}</option>
                          <option value="9">{{ $t('weekdays.saturday') }}-{{ $t('weekdays.sunday') }}</option>
                          <option value="1">{{ $t('weekdays.monday') }}</option>
                          <option value="2">{{ $t('weekdays.tuesday') }}</option>
                          <option value="3">{{ $t('weekdays.wednesday') }}</option>
                          <option value="4">{{ $t('weekdays.thursday') }}</option>
                          <option value="5">{{ $t('weekdays.friday') }}</option>
                          <option value="6">{{ $t('weekdays.saturday') }}</option>
                          <option value="7">{{ $t('weekdays.sunday') }}</option>
                        </select>
                      </div>
                    </div>

                    <div class="nom_input">
                      <p>{{ $t('common.start') }}</p>
                      <Datepicker v-model="startTime" timePicker />
                    </div>
                    <div class="nom_input">
                      <p>{{ $t('common.end') }}</p>
                      <Datepicker v-model="endTime" timePicker />
                    </div>
                  </div>
                  <div class="msg">
                    <p>{{ timetableMessage }}</p>
                  </div>
                  <div class="btn_guardar">
                    <button @click="addTimetable">
                      <a href="#">{{ $t('tasks.add') }}</a>
                    </button>
                  </div>
                  <table class="table align-middle" id="ttimetable">
                    <thead>
                      <tr>
                        <th scope="col"></th>
                        <th scope="col" class="text-end">{{ $t('common.start') }}</th>
                        <th scope="col" class="text-start">{{ $t('common.end') }}</th>
                        <th scope="col"></th>
                      </tr>
                    </thead>
                    <tbody class="align-middle">
                      <tr v-for="(timetable, index) in timetables" :key="index">
                        <td class="">{{ dayOfWeek(timetable.wday) }}</td>
                        <td class="text-end">
                          <span class="b_rad">{{
                            decodeTime(timetable.start)
                          }}</span>
                        </td>
                        <td class="text-start">
                          <span class="b_rad">{{
                            decodeTime(timetable.end)
                          }}</span>
                        </td>
                        <td scope="row" class="text-end">
                          <a
                            ><i
                              @click="deleteTimetable(index)"
                              class="fa fa-trash"
                              aria-hidden="true"
                            ></i>
                          </a>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>

              <div
                class="tab-pane fade"
                id="camp"
                role="tabpanel"
                aria-labelledby="camp-tab"
              >
                <div class="conf_camaras">
                  <div class="btn_c_visor">
                    <button id="btn_unassigned" @click="unassigned_cameras" style="width:120px;">
                      {{ $t('tasks.unassigned') }}
                    </button>
                    <button id="btn_all" @click="all_cameras"  style="width:120px;">{{ $t('tasks.all') }}</button>
                  </div>

                  <div class="cam_layout">
                    <div v-for="(camera, index) in cameras" :key="camera.id">
                      <camera-switch
                        :index="index"
                        :id="camera.id"
                        :name="camera.name"
                        :model="camera.model"
                      ></camera-switch>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="btn_guardar">
              <button @click.prevent="save">
                <a href="#">{{ $t('common.save') }}</a>
                <div
                  v-if="saving"
                  class="spinner-border spinner-border-sm"
                  role="status"
                >
                  <span class="sr-only">Saving...</span>
                </div>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <menu-footer active-state="tasks"></menu-footer>
</template>

<script>
import CameraSwitch from "../Cameras/CameraSwitch.vue";
import Datepicker from "@vuepic/vue-datepicker";
import "@vuepic/vue-datepicker/dist/main.css";
import $ from "jquery";

//const axios = require("axios").default;
//axios.defaults.withCredentials = false;

export default {
  name: "TaskAdd",
  components: {
    Datepicker,
    CameraSwitch,
  },
  data() {
    return {
      currentTime: {
        hours: new Date().getHours(),
        minutes: new Date().getMinutes(),
      },
      startTime: {
        hours: 0,
        minutes: 0,
      },
      endTime: {
        hours: 23,
        minutes: 59,
      },
      error: false,
      saving: false,
      timetables: [],
      wday: "10",
      timetableMessage: "",
      errorMessage: "",
      //allAvailableCameras: [],
      unassignedCameras: [],
      //cameras: [],
      selectedCameras: [],
      name: "",
      type: "0",
      motionNotify: false,
      audioNotify: false,
      unassignedOnly: true,
    };
  },
  mounted() {
    $("#msg-alert").hide();
    this.getUnassignedCameras();
    //this.getAllCameras();
		$("#btn_all").removeClass("select_btn_active");
		$("#btn_all").addClass("select_btn_inactive");
		$("#btn_unassigned").removeClass("select_btn_inactive");
		$("#btn_unassigned").addClass("select_btn_active");
  },
  computed: {
    cameras() {
      if (this.unassignedOnly)
        return this.unassignedCameras;
      return this.$root.dataManager.cameras;
    }
  },
  methods: {
    showMsgAlert() {
      $("#msg-alert")
        .fadeTo(200, .80, function() {
					setTimeout(function() {
						$("#msg-alert").fadeTo(200,0);
						},2000)
				});
    },
    closeWindow() {
      console.log("closeWindow");
      //this.$router.push({ name: "TasksMain" });
      this.$router.go(-1);
    },
    verifyData() {
      if (this.name === "") {
        this.errorMessage = this.$t('tasks.name_required');
        this.error = true;
        return false;
      }
      if (this.timetables.length === 0) {
        this.errorMessage = this.$t('tasks.at_least_one_timetable');
        this.error = true;
        return false;
      }
      if (this.selectedCameras.length === 0) {
        this.errorMessage = this.$t('tasks.at_least_one_camera');
        this.error = true;
        return false;
      }
      return true;
    },
    save() {
      console.log(
        "save task",
        this.name,
        this.type,
        this.motionNotify,
        this.audioNotify
      );
      this.error = false;
      this.errorMessage = "";
      console.log("save task timetables", this.timetables);
      this.selectedCameras = $("input:checkbox[name=swCamSwitch]:checked")
        .map(function () {
          console.log("Id: " + $(this).attr("id") + " Value: " + $(this).val());
          return $(this).attr("id").split("-")[1];
        })
        .get();
      console.log("save task selected cameras", this.selectedCameras);
      if (this.verifyData()) {
        this.saveTask();
      } else {
        this.showMsgAlert();
      }
    },
    async saveTask() {
      this.saving = true;
      console.log("saving task");
      const timetable = this.timetables.map(function (e) {
        return {
          wday: e.wday,
          start: this.decodeTime(e.start),
          end: this.decodeTime(e.end),
        };
      }, this);
      try {
        const response = await this.$root.dataManager.addTask(this.name,this.encodeType(),true);
        const taskId = response.progId;
        console.log("save task OK", taskId);
        //const data = { this.selectedCameras.toString();
        await this.$root.dataManager.addTaskCameras(taskId,this.selectedCameras.toString());
        await this.$root.dataManager.addTaskTimetable(taskId,timetable);
        await this.$root.dataManager.modifyTask(taskId,this.name,this.encodeType(),false);
        this.saving = false;
        this.$router.push({ name: "TasksMain" });
      }
      catch (error) {
        this.saving = false;
        console.log("saveTask error");
        console.error(error);
        this.errorMessage = this.$t('tasks.error_saving_task');
        this.showMsgAlert();  
      }

    },
    encodeType() {     
      let taskType=parseInt(this.type);
      taskType+=this.motionNotify?4:0;
      taskType+=this.audioNotify?16:0;

      console.log("encodeType", taskType);
      return taskType;
    },

    all_cameras() {
			$("#btn_unassigned").removeClass("select_btn_active");
			$("#btn_unassigned").addClass("select_btn_inactive");
			$("#btn_all").removeClass("select_btn_inactive");
			$("#btn_all").addClass("select_btn_active");
      //this.cameras = [...this.$root.dataManager.cameras];
      this.unassignedOnly=false;
    },
    unassigned_cameras() {
			$("#btn_all").removeClass("select_btn_active");
			$("#btn_all").addClass("select_btn_inactive");
			$("#btn_unassigned").removeClass("select_btn_inactive");
			$("#btn_unassigned").addClass("select_btn_active");
      //this.cameras = [...this.unassignedCameras];
      this.unassignedOnly=true;
    },

    async getUnassignedCameras() {
      try {
        const response=await this.$root.dataManager.loadUnassignedCameras();
        this.unassignedCameras = [...response.cameras]; 
      }
      catch (error) {
        console.log(error);
      }

    },
    dayOfWeek(wday) {
      var dict = {};
      dict[1] = this.$t('weekdays.monday');
      dict[2] = this.$t('weekdays.tuesday');
      dict[3] = this.$t('weekdays.wednesday');
      dict[4] = this.$t('weekdays.thursday');
      dict[5] = this.$t('weekdays.friday');
      dict[6] = this.$t('weekdays.saturday');
      dict[7] = this.$t('weekdays.sunday');
      return dict[wday];
    },
    // wdayOfWeek(day) {
    //   var dict = {};
    //   dict["Lunes"] = 1;
    //   dict["Martes"] = 2;
    //   dict["Miercoles"] = 3;
    //   dict["Jueves"] = 4;
    //   dict["Viernes"] = 5;
    //   dict["Sabado"] = 6;
    //   dict["Domingo"] = 7;
    //   return dict[day];
    // },
    deleteTimetable(index) {
      console.log("deleteTimetable", index);
      this.timetables.splice(index, 1);
    },
    validateTime(wday, startTime, endTime) {
      const startMins = wday * 1440 + startTime.hours * 60 + startTime.minutes;
      const endMins = wday * 1440 + endTime.hours * 60 + endTime.minutes;
      if (startMins >= endMins) {
        this.timetableMessage = this.$t('tasks.start_later_than_end');
        return false;
      }
      for (const timetable of this.timetables) {
        const sMins =
          timetable.wday * 1440 +
          timetable.start.hours * 60 +
          timetable.start.minutes;
        const eMins =
          timetable.wday * 1440 +
          timetable.end.hours * 60 +
          timetable.end.minutes;
        if (startMins >= sMins && startMins <= eMins) {
          this.timetableMessage = this.$t('tasks.overlap_in_start_time',{weekday: this.dayOfWeek(wday)});
    
          return false;
        }
        if (endMins >= sMins && endMins <= eMins) {
          this.timetableMessage = this.$t('tasks.overlap_in_end_time',{weekday: this.dayOfWeek(wday)});
          return false;
        }
      }
      this.timetableMessage = "";
      return true;
    },
    decodeTime(time) {
      let hour = time.hours;
      let min = time.minutes;
      if (hour < 10) {
        hour = "0" + hour;
      }
      if (min < 10) {
        min = "0" + min;
      }
      return hour + ":" + min;
    },
    addTimetable() {
      this.errorMessage = "";
      console.log("add timetable", this.wday, this.startTime, this.endTime);
      if (this.wday <= 7) {
        if (!this.validateTime(this.wday, this.startTime, this.endTime)) {
          console.log("error validating time");
          return;
        }
        const item = {
          wday: this.wday,
          start: this.startTime,
          end: this.endTime,
        };
        console.log(item);
        this.timetables.push(item);
      }
      if (this.wday == 8) {
        for (let i = 1; i < 6; i++) {
          if (!this.validateTime(i, this.startTime, this.endTime)) {
            console.log("error validating time");
            return;
          }
          const item = {
            wday: i,
            start: this.startTime,
            end: this.endTime,
          };
          console.log(item);
          this.timetables.push(item);
        }
      }
      if (this.wday == 9) {
        for (let i = 6; i < 8; i++) {
          if (!this.validateTime(i, this.startTime, this.endTime)) {
            console.log("error validating time");
            return;
          }
          const item = {
            wday: i,
            start: this.startTime,
            end: this.endTime,
          };
          console.log(item);
          this.timetables.push(item);
        }
      }
      if (this.wday == 10) {
        for (let i = 1; i < 8; i++) {
          if (!this.validateTime(i, this.startTime, this.endTime)) {
            console.log("error validating time");
            return;
          }
          const item = {
            wday: i,
            start: this.startTime,
            end: this.endTime,
          };
          console.log(item);
          this.timetables.push(item);
        }
      }
    },
  },
};
</script>

<style scoped>
#confProg .time_input {
  margin-top: 20px;
}
</style>
