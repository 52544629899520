<template>
  <div id="menuFooter" class="fixed-bottom">
    <div class="container-fluid">
      <div class="row">
        <div class="col-12">
          <ul class="nav justify-content-center accesos_directos">
            <li
              :class="['nav-item', activeState == 'cameras' ? 'active' : '']"
              @click="navToCameras"
            >
              <a class="nav-link" href="#"
                @click.prevent>
                <div>
                  <!--<i class="fa fa-video-camera" aria-hidden="true"></i>-->
                  <i class="material-icons" aria-hidden="true">visibility</i>
                </div>
                <h6>{{ $t('common.cameras') }}</h6></a
              >
            </li>
            
            <li
              :class="['nav-item', activeState == 'recordings' ? 'active' : '']"
              @click="navToRecordings"
            >
              <a class="nav-link" href="#"
                @click.prevent>
                <div>
                  <!--<i class="fa fa-film" aria-hidden="true"></i>-->
                  <i class="material-icons" aria-hidden="true">theaters</i>
                </div>
                <h6>{{ $t('common.recordings') }}</h6></a
              >
            </li>
            <li
              :class="['nav-item', activeState == 'groups' ? 'active' : '']"
              @click="navToGroups"
            >
              <a class="nav-link" href="#"
                @click.prevent>
                <div>
                  <!--<i class="fa fa-object-group" aria-hidden="true"></i>-->
                  <i class="material-icons" aria-hidden="true">grid_view</i>
                </div>
                <h6>{{ $t('common.groups') }}</h6></a
              >
            </li>
            <li
              :class="['nav-item', activeState == 'tasks' ? 'active' : '']"
              @click="navToTasks"
            >
              <a class="nav-link" href="#"
                @click.prevent>
                <div>
                  <!--<i class="fa fa-clock-o" aria-hidden="true"></i>-->
                  <i class="material-icons" aria-hidden="true">today</i>
                </div>
                <h6>{{ $t('common.tasks') }}</h6>
              </a>
            </li>
            <!--<li v-if="editUsersPriv" :class="['nav-item', activeState == 'users' ? 'active' : '']"
              @click="navToUsers">
              <a class="nav-link" href="#">
                <div>
                  <i class="fa fa-users" aria-hidden="true"></i>
                </div>
                <h6>Usuarios</h6></a
              >
            </li>-->
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "MenuFooter",
  props: {
    activeState: String,
  },
  data (){
    return{
      editUsersPriv: false,
    }

  },
  mounted() {
    console.log("MenuFooter", this.activeState);
    const userPriv = this.$root.sessionManager.privileges;
    console.log("UsersMain, userPriv:", userPriv);
    this.editUsersPriv = userPriv['editUsers'];
    
  },
  methods: {
    // logOut() {
    //   sessionStorage.removeItem("userInfo");
    //   this.$router.push({ name: "SignIn" });
    // },
    navToRecordings() {
      this.$router.push({ name: "RecordingsMain" });
    },
    navToCameras() {
      this.$router.push({ name: "CamerasMain" });
    },
    navToGroups() {
      this.$router.push({ name: "GroupsMain" });
    },
    navToTasks() {
      console.log("nav to tasks");
      this.$router.push({ name: "TasksMain" });
    },
    navToUsers() {
      this.$router.push({ name: "UsersMain" });
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>